<script setup>
import {computed, onMounted, ref} from "vue";
import {useCamerasStore} from "@/store/cameras";

const camerasStore = useCamerasStore()
const props = defineProps({
  selectFirst: {type: Boolean, default: false},
  label: {type: String, required: true},
  rules: {type: Array, default: () => []},
  multiple: {type: Boolean, default: true},
  readOnly: {type: Boolean, default: false},
  disabled: {type: Boolean, default: false},
  includeDecommissionedCameras: {type: Boolean, default: false},
  hideDetails: {type: Boolean, default: false},
})
const modelValue = defineModel()
const emit = defineEmits(["validation"])
const rawCameras = computed(() => {
  return props.includeDecommissionedCameras ? camerasStore.getCameras() : camerasStore.getNonDecommissionedCameras()
})
const availableCameras = computed(() => [...rawCameras.value].sort((a, b) => a.name.localeCompare(b.name)))

const loading = computed(() => !camerasStore.getCameras())
onMounted(async () => {
  await camerasStore.keepCamerasLoaded()
  if (!modelValue.value && props.selectFirst && availableCameras.value.length > 0) {
    modelValue.value = [availableCameras.value[0].cameraId]
  }
})

</script>

<template>
  <v-autocomplete
    :auto-select-first="true"
    style="min-width: 250px;"
    :loading="loading"
    v-model="modelValue"
    :label="label"
    :rules="rules"
    class="ma-2"
    variant="outlined"
    :items="availableCameras"
    item-title="name"
    item-value="cameraId"
    :multiple="multiple"
    :hide-details="hideDetails"
    :chips="true"
    :readonly="readOnly"
    :disabled="disabled"

  />
</template>

<style scoped>

</style>
