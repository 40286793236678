<script setup>
import i18n from "../../plugins/i18n"
const {t} = i18n.global
import {computed, onMounted, ref} from "vue";
import {humanReadableTimestamp} from "@/composables/datetime";
import {useCamerasStore} from "@/store/cameras";
import {useCameraName} from "@/composables/cameraHelpers";
import WYSIWYG from "@/components/WYSIWYG.vue";
import {useIncidentStore} from "@/store/incident";
const incidentStore = useIncidentStore()
const props = defineProps({incident: {type: Object, default: () => ({})}})

const humanReadableCameras = ref("")
const firstEvent = ref(null)

const hasStartTimestamp = computed(() => {
  if(!appropriatePayload.value?.specification) return false
  return Object.keys(appropriatePayload.value.specification).includes("startTimestamp")
})
const hasEndTimestamp = computed(() => {
  if(!appropriatePayload.value?.specification) return false
  return Object.keys(appropriatePayload.value.specification).includes("endTimestamp")
})
const hasTimestamp = computed(() => {
  if(!appropriatePayload.value?.specification) return false
  return Object.keys(appropriatePayload.value.specification).includes("timestamp")
})

const hasRegions = computed(() => {
  if(!appropriatePayload.value?.specification) return false
  if(appropriatePayload.value?.specification.location === "global") return false
  return Object.keys(appropriatePayload.value.specification.location).includes("regionSpecification")
})
const hasCameras = computed(() => {
  if(!appropriatePayload.value?.specification) return false
  if(appropriatePayload.value.specification.location === "global") return false
  return Object.keys(appropriatePayload.value.specification.location).includes("cameraId")
})
const isGlobal = computed(() => {
  if(!appropriatePayload.value?.specification) return false
  return (appropriatePayload.value?.specification.location === "global")
})

const hasDescription = computed(() => {
  if(!appropriatePayload.value) return false
  return Object.keys(appropriatePayload.value).includes("description")
})

const humanReadableRegions = computed(() => {
  if(!hasRegions.value) return null
  const regionSpecification = appropriatePayload.value.specification.location.regionSpecification
  return regionSpecification.join(", ")
})

const wasCreatedAutomatically = computed(() => {
  return (props.incident.triggeredBy.entityType !== "account")
})

const humanReadableSpecificationType = computed(() => {
  const specification = isHidden.value ? firstEvent.value?.payload?.specification : props.incident.messagePayload?.specification
  if(!specification ) return ""
  const type = specification.type
  return t(`incidents.barnDocumentation.specificationTypes.${type}`)
})

const humanReadableClassification = computed(() => {
  const classification = appropriatePayload.value?.classification
  return t(`incidents.barnDocumentation.classifications.${classification}`)
})

const isHidden = computed(() => {
  return props.incident.state === "hidden"
})

const appropriatePayload = computed(() => {
  return isHidden.value ? firstEvent.value?.payload : props.incident.messagePayload
})

const newEventButtonText = computed(() => {
  if(isHidden.value) return t("incidents.barnDocumentation.actions.show")
  return t("incidents.barnDocumentation.actions.hide")
})

onMounted(async () => {
  if(isHidden.value) {
    firstEvent.value = await incidentStore.loadFirstEventForIncident(props.incident.incidentId)
  }
  if (hasCameras.value) {
    await useCamerasStore().keepCamerasLoaded()
    const cameraNames = []
    for (const cameraId of appropriatePayload.value.specification.location.cameraId) {
      cameraNames.push(useCameraName(cameraId))
    }
    humanReadableCameras.value = cameraNames.join(", ")
  }


})
</script>

<template>
  <v-layout-card>
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-card-title>
        {{ humanReadableSpecificationType }}
      </v-card-title>
      <v-card-subtitle v-if="incident.state === 'hidden'">
        {{ $t("incidents.barnDocumentation.hidden") }}
      </v-card-subtitle>
    </v-row>

    <v-card-text>
      <v-list>
        <v-list-item
          :title="$t('incidents.barnDocumentation.titles.classification')"
          :subtitle="humanReadableClassification"
          prepend-icon="mdi-list-box"
        />
        <v-list-item
          :title="$t('incidents.barnDocumentation.titles.specificationType')"
          :subtitle="humanReadableSpecificationType"
          prepend-icon="mdi-label"
        />
        <v-list-item
          v-if="hasStartTimestamp"
          :title="$t('incidents.barnDocumentation.titles.startTimestamp')"
          :subtitle="humanReadableTimestamp(appropriatePayload.specification.startTimestamp)"
          prepend-icon="mdi-clock-start"
        />
        <v-list-item
          v-if="hasEndTimestamp"
          :title="$t('incidents.barnDocumentation.titles.endTimestamp')"
          :subtitle="humanReadableTimestamp(appropriatePayload.specification.endTimestamp)"
          prepend-icon="mdi-clock-end"
        />
        <v-list-item
          v-if="hasTimestamp"
          :title="$t('incidents.barnDocumentation.titles.timestamp')"
          :subtitle="humanReadableTimestamp(appropriatePayload.specification.timestamp)"
          prepend-icon="mdi-clock"
        />
        <v-list-item
          v-if="isGlobal"
          :title="$t('incidents.barnDocumentation.titles.location')"
          :subtitle="$t('incidents.barnDocumentation.global')"
          prepend-icon="mdi-earth"
        />
        <v-list-item
          v-if="hasCameras"
          :title="$t('incidents.barnDocumentation.titles.cameras')"
          :subtitle="humanReadableCameras"
          prepend-icon="mdi-video"
        />
        <v-list-item
          v-if="hasRegions"
          :title="$t('incidents.barnDocumentation.titles.regions')"
          :subtitle="humanReadableRegions"
          prepend-icon="mdi-map-marker"
        />
        <v-list-item
          v-if="wasCreatedAutomatically"
          :title="$t('incidents.barnDocumentation.titles.created_automatically')"
          :subtitle="$t('incidents.barnDocumentation.created_automatically')"
          prepend-icon="mdi-robot"
        />
        <v-list-item
          v-if="!wasCreatedAutomatically"
          :title="$t('incidents.barnDocumentation.titles.creator')"
          :subtitle="incident.triggeredBy.displayName"
          prepend-icon="mdi-account"
        />
        <v-list-item
          v-if="isHidden"
          :title="$t('incidents.barnDocumentation.titles.hideReason')"
          prepend-icon="mdi-file-question"
        >
          <WYSIWYG
            :model-value="incident.messagePayload.reason"
            :read-only="true"
          />
        </v-list-item>
        <v-list-item
          v-if="hasDescription"
          :title="$t('incidents.barnDocumentation.titles.description')"
          prepend-icon="mdi-text-box"
        >
          <WYSIWYG
            :model-value="appropriatePayload.description"
            :read-only="true"
          />
        </v-list-item>
        <v-list-item
          v-if="incident.state !== 'closed' && incident.isPublicOpen === false"
          prepend-icon="mdi-eye-off"
          :title="$t('incidents.titles.public_closed')"
          :subtitle="$t('incidents.properties.public_closed')"
        />
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{enableNewEventButton: true, newEventButtonText: newEventButtonText}"
      />
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
