import Syncable from "@/syncing/Syncable.js";
import backendHandler from "@/BackendAPI.js";
import {useCanvasStore} from "@/store/canvas.js";
import _ from "lodash";
import {useInstanceStore} from "@/store/backendInstances.js";

class BaseCanvasSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 10, BaseCanvasSyncable.getSyncableHandle(), true, true)
  }

  satisfiesPreconditions() {
    if(useInstanceStore().instances[this.globalUniqueIdentifier].instanceType !== "barn") return false
    return super.satisfiesPreconditions();
  }

  hasInitialData() {
    return !!useCanvasStore().getBaseCanvas(this.globalUniqueIdentifier)
  }

  async workload() {
    const baseCanvasResponse = await backendHandler.get(this.globalUniqueIdentifier, {
      path: "/api/cameraCanvases",
      priority: 2
    })
    if (!baseCanvasResponse.ok) return
    const currentBaseCanvas = useCanvasStore().getBaseCanvas(this.globalUniqueIdentifier)
    if (currentBaseCanvas && _.isEqual(currentBaseCanvas, baseCanvasResponse.data)) return;
    useCanvasStore().baseCanvases[this.globalUniqueIdentifier] = baseCanvasResponse.data
  }

  static getSyncableHandle() {
    return "base-canvas"
  }
}

export default BaseCanvasSyncable
