<script setup>

import {humanReadableDuration, humanReadableTimestamp} from "@/composables/datetime";
import {getAge, getDeadAnimalIncidentColor} from "@/composables/incidents/incidentTimeHelper";
import {canTriggerDeadAnimals} from "@/composables/permissionHelper";
import DeadAnimalIncidentPlugin from "@/components/canvas/CanvasPluginProviders/DeadAnimalIncidentPlugin";
import {computed} from "vue";
import {useDisplay} from "vuetify";
import IncidentDisplay from "@/components/incidents/IncidentDisplay.vue";

const {mobile} = useDisplay()

const props = defineProps({
  pluginConfig: {type: Object, default: () => ({})},
  plugin: {type: DeadAnimalIncidentPlugin, default: null},
  visualLabelingModeEnabled: {type: Boolean, default: false},
  expanded: {type: Boolean, default: false},
})

defineEmits(["zoomToSpecificPosition", "enableVisualLabelingMode", "disableVisualLabelingMode", "update:expanded"])
const deadAnimalIncidents = computed(() => {
  if (!props.plugin?.reactiveProperties.incidents) return []
  const incidents = props.plugin.reactiveProperties.incidents.map(incident => {
    return {
      raw: incident,
      incidentId: incident.incidentId,
      cameraId: incident.identifier.cameraId,
      cameraName: incident.messagePayload.cameraName,
      age: getAge(incident),
      position: incident.position
    }
  })
  return incidents.sort((a, b) => b.age - a.age)
})

const loading = computed(() => {
  return props.plugin?.reactiveProperties.loading
})


</script>

<template>
  <v-card
    v-if="plugin && plugin.isEnabled && plugin.isEnabled()"
    :loading="loading"
    :class="`pa-2 position-absolute ${mobile && !expanded ? 'rounded-circle' : ''}`"
    style="top: 16px; right:16px; border-radius: 32px"
  >
    <v-row class="justify-space-between align-center">
      <v-expand-transition>
        <v-col
          v-if="expanded || !mobile"
          cols="auto"
        >
          <v-card-title v-if="loading">
            {{ $t("canvas.dead_animal_incidents") }}
          </v-card-title>
          <v-card-title v-else>
            {{ $t("canvas.dead_animal_incidents_with_count", {count: deadAnimalIncidents.length}) }}
          </v-card-title>
          <v-card-subtitle v-if="!loading">
            {{ $t("canvas.dead_animal_incidents_subtitle") }}
          </v-card-subtitle>
        </v-col>
      </v-expand-transition>
      <v-col cols="auto">
        <v-fab-transition>
          <v-btn
            v-if="expanded"
            class="ma-1"
            size="small"
            variant="outlined"
            icon="mdi-chevron-up"
            @click="$emit('update:expanded', false)"
          />
          <v-btn
            v-if="!expanded"
            size="small"
            class="ma-1"
            variant="flat"
            :icon="mobile ? 'mdi-skull-scan':'mdi-chevron-down'"
            @click="$emit('update:expanded', true)"
          />
        </v-fab-transition>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card-text v-if="deadAnimalIncidents && expanded">
        <v-list
          class="mx-1"
          density="compact"
          max-height="40vh"
        >
          <v-list-item
            v-for="incident in deadAnimalIncidents"
            :key="incident.incidentId"
            variant="flat"
            lines="three"
            class="my-1 rounded-e-pill"
            :title="`${incident.cameraName} ${incident.raw.triggeredBy.entityType === 'account' ? '('+$t('canvas.dead_animal_overlay.manual')+')' : ''}`"
            :base-color="getDeadAnimalIncidentColor(incident.raw)"
          >
            <template #subtitle>
              <span>
                {{ $t("canvas.dead_animal_overlay.first_detection", {timestamp: humanReadableTimestamp(incident.raw.firstEventTimestamp)}) }}
              </span>

              <br v-if="incident.raw.state !== 'deadAnimalExists'">
              <span v-if="incident.raw.state !== 'deadAnimalExists'">
                {{ $t("canvas.dead_animal_overlay.last_detection", {timestamp: humanReadableTimestamp(incident.raw.lastEventTimestamp)}) }}
              </span>

              <br v-if="incident.raw.state === 'deadAnimalExists'">
              <span v-if="incident.raw.state === 'deadAnimalExists'">
                {{ $t("canvas.dead_animal_overlay.current_age", {age: humanReadableDuration(incident.age, undefined, ['d', 'h'], true)}) }}
              </span>
              <br v-if="incident.raw.state !== 'deadAnimalExists'">
              <span v-if="incident.raw.state !== 'deadAnimalExists'">
                {{
                  $t("canvas.dead_animal_overlay.total_age", {age: humanReadableDuration(incident.age, undefined, ['d', 'h'], true)})
                }}
              </span>

            </template>
            <template #append>
              <v-btn
                variant="elevated"
                :color="getDeadAnimalIncidentColor(incident.raw)"
                class="mx-1 my-3"
                size="small"
                icon="mdi-crosshairs-gps"
                @click="(e) => {$emit('zoomToSpecificPosition', incident.position), e.stopPropagation()} "
              />
            </template>
            <v-dialog
              max-width="600px"
              activator="parent"
            >
              <IncidentDisplay :incident="incident.raw"/>
            </v-dialog>
          </v-list-item>
        </v-list>
        <v-row
          v-if="deadAnimalIncidents && deadAnimalIncidents.length === 0"
          class="justify-center"
        >
          <v-col cols="auto">
            <v-chip>
              {{ $t("canvas.no_dead_animal_incidents") }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-expand-transition>
    <v-card-actions
      v-if="!mobile"
      class="justify-end"
    >
      <v-btn
        v-if="canTriggerDeadAnimals() && !visualLabelingModeEnabled"
        variant="outlined"
        class="rounded-pill"
        @click="$emit('enableVisualLabelingMode', 'deadAnimal')"
      >
        {{ $t("canvas.trigger_dead_animal") }}
      </v-btn>
      <v-btn
        v-if="visualLabelingModeEnabled"
        variant="outlined"
        color="error"
        class="rounded-pill"
        @click="$emit('disableVisualLabelingMode')"
      >
        {{ $t("canvas.disable_dead_animal_triggering") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>
