import {defineStore} from 'pinia'
import {useInstanceStore} from "@/store/backendInstances";
import backendHandler from "@/BackendAPI";
import _ from "lodash";
import BaseCanvasSyncable from "@/syncing/syncables/BaseCanvasSyncable.js";
import SyncableQueue from "@/syncing/SyncableQueue.js";

export const useCanvasStore = defineStore('canvas', {
  state: () => ({
    baseCanvasLoadingHandles: {},
    baseCanvases: {},
  }),
  persist: false,
  share: {
    enable: true,
    initialize: true,
  },
  getters: {
    getBaseCanvas: (state) => (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => state.baseCanvases[globalUniqueIdentifier],
  },
  actions: {
    async queryCanvases(payload) {
      const canvasResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/cameraCanvases/query",
        data: payload,
        priority: 2
      })
      if (!canvasResponse.ok) return null;
      return canvasResponse.data
    },

    async loadSpecificCanvas(canvasId) {
      const canvasResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/cameraCanvases/queryOne",
        data: {query: [{key: "cameraCanvasId", operator: "==", value: canvasId}]},
        priority: 2
      })
      if (canvasResponse.ok)
        return canvasResponse.data
      return null
    },
    async loadBaseCanvas(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      const canvasResponse = await backendHandler.get(globalUniqueIdentifier, {
        path: "/api/cameraCanvases",
        priority: 2
      })
      if (!canvasResponse.ok) return
      const currentBaseCanvas = this.baseCanvases[globalUniqueIdentifier]
      if (currentBaseCanvas && _.isEqual(currentBaseCanvas, canvasResponse.data)) return;
      this.baseCanvases[globalUniqueIdentifier] = canvasResponse.data
    },
    keepBaseCanvasLoaded(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      if (SyncableQueue.doesSyncableExist(globalUniqueIdentifier, BaseCanvasSyncable.getSyncableHandle())) return
      const syncable = new BaseCanvasSyncable(globalUniqueIdentifier)
      SyncableQueue.addSyncable(syncable)
    }
  }
})
