<script setup>
import {useTheme} from 'vuetify'
import {ref} from "vue";
import {loadNightVisionMeasurement} from "@/composables/charts";
import CameraBaseChart from "@/components/charts/CameraBaseChart.vue";
import i18n from "../../plugins/i18n"

const {t} = i18n.global
const props = defineProps(
  {
    cameraIds: {type: Array, default: () => []},
    aggregate: {type: Boolean, default: false},
    title: {type: String, default: null},
    subtitle: {type: String, default: ""},
    timeSetup: {type: Object, default: null},
    chainTimeSetup: {type: Boolean, default: false}
  }
)
defineEmits(["updatedTimeSetup"])


const options = ref({
    theme: {
      mode: useTheme().name.value,
    },
    title: {
      text: props.title ? props.title : t("charts.titles.nightvision_range_bar"),
      margin: 16,
    },
    subtitle: {
      text: props.subtitle,
      align: "left",
    },
    chart: {
      type: "rangeBar",
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
    },
    xaxis: {
      type: "datetime",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%"
      }
    },
  }
)

async function dataLoader(cameraIds, timeSetup) {
  const data = await loadNightVisionMeasurement({
    cameraIds: cameraIds,
    timeResolution: timeSetup.timeResolution,
    timeQuery: {start: timeSetup.start, end: timeSetup.end},
    aggregateCameras: props.aggregate,
    chartType: "rangeBar"
  })
  return data
}

</script>

<template>
  <CameraBaseChart
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :time-setup="timeSetup"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
