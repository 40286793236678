<script setup>

import {ref} from "vue";
import {useIncidentStore} from "@/store/incident";
const incidentStore = useIncidentStore()

import i18n from "../../plugins/i18n"
import DateTimePicker from "@/components/DateTimePicker.vue";
import {useToast} from "vue-toastification";
import {inputRequiredRule} from "@/composables/inputRules";
const toast = useToast();

const {t} = i18n.global

defineProps({
  incident: {type: Object, default: null},
})

const emit = defineEmits(["saved"])

const availableTriggers = [
  {
    title: t("incidents.barnState.triggers.animalsDetected"),
    value: "animalsDetected"
  },
  {
    title: t("incidents.barnState.triggers.noAnimalsDetected"),
    value: "noAnimalsDetected"
  },
  {
    title: t("incidents.barnState.triggers.barnEvent"),
    value: "barnEvent"
  },
]

const availableBarnEvents = [
  {title: t("incidents.barnState.barnEvents.thinning"), value: "thinning"},
]

const form = ref(null)
const loading = ref(false)
const eventTimestamp = ref(null)
const trigger = ref(null)
const barnEvent = ref(null)

async function saveState() {
  form.value.validate();
  if(!form.value.isValid) {
    toast.error(t("toast.fix_errors_above"))
    return;
  }

  const incidentPayload = {
    type: "barnState",
    trigger: trigger.value,
    eventTimestamp: eventTimestamp.value.toMillis(),
    payload: barnEvent.value ? {event: barnEvent.value} : undefined,
  }
  loading.value = true
  const successful = await incidentStore.triggerGenericIncident(incidentPayload)
  loading.value = false
  if(!successful) {
    toast.error(t("toast.generic_could_not_save"))
    return;
  }
  toast.success(t("toast.generic_saved"))
  emit("saved")
}

</script>

<template>
  <v-layout-card
    :loading="loading"
    :disabled="loading"
  >
    <v-card-title>{{ incident ? $t("incidents.barnState.update_title") : $t("incidents.barnState.new_title") }}</v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
          v-model="trigger"
          :items="availableTriggers"
          item-title="title"
          item-value="value"
          :label="$t('incidents.barnState.trigger')"
          :rules="[inputRequiredRule]"
        />
        <v-autocomplete
          v-if="trigger === 'barnEvent'"
          v-model="barnEvent"
          :items="availableBarnEvents"
          item-title="title"
          item-value="value"
          :label="$t('incidents.barnState.barnEvent')"
          :rules="[inputRequiredRule]"
        />
        <DateTimePicker
          v-model="eventTimestamp"
          :label="$t('general_interface.date_time_picker.label')"
        />
      </v-form>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn
        variant="elevated"
        color="success"
        @click="saveState"
      >
        {{ $t("general_interface.buttons.save") }}
      </v-btn>
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
