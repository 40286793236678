<script setup>
import { useTheme } from 'vuetify'
import {ref} from "vue";
import {loadGenericMeasurement} from "@/composables/charts";
import CameraBaseChart from "@/components/charts/CameraBaseChart.vue";
const props = defineProps(
  {
    cameraIds: {type: Array, default: () => []},
    aggregate: {type: Boolean, default: false},
    measurement: {type: String, default: null},
    field: {type: String, default: null},
    title: {type: String, default: ""},
    subtitle: {type: String, default: ""},
    disableControlRow: {type: Boolean, default: false},
    timeSetup: {type: Object, default: null},
    chainTimeSetup: {type: Boolean, default: false}
  }
)

defineEmits(["updatedTimeSetup"])
const options = ref(
  {
    stroke: {
      width: 2,
      curve: "smooth",
      lineCap: "butt",
    },
    theme: {
      mode: useTheme().name.value,
    },
    title: {
      text: props.title,
      margin: 16,
    },
    subtitle: {
      text: props.subtitle,
      align: "left",
    },
    chart: {
      type: "line",
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false
      }
    },
  }
)

async function dataLoader(cameraIds, timeSetup) {
  const data = await loadGenericMeasurement({
    cameraIds: cameraIds,
    timeResolution : timeSetup.timeResolution,
    timeQuery: {start: timeSetup.start, end: timeSetup.end},
    measurement: props.measurement,
    field: props.field,
    aggregate: props.aggregate,
    chartType: "line",
  })
  options.value.labels = data.seriesNames
  return data
}


</script>

<template>
  <CameraBaseChart
    :disable-control-row="disableControlRow"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :time-setup="timeSetup"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>
