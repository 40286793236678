import CanvasElementSourceProvider from "@/components/canvas/CanvasElementSourceProviders/CanvasElementSourceProvider";

class ImageSourceProvider extends CanvasElementSourceProvider {
    constructor(settings, providerType) {
        super(settings, providerType);
    }

    async loadSourceFromBackend(height, controller, isInView) {
        throw new Error('loadSourceFromBackend not implemented')
    }

    fetchSource(isInView) {
        const height = this.getQuantizedHeight();
        const controller = new AbortController();
        const loadPromise = new Promise((resolve, reject) => {
            const {appropriateSource, appropriateSourceHeight} = this.getAppropriateSource(height);
            if (appropriateSource) {
                this.elementProviderReference.setSource(appropriateSource, appropriateSourceHeight,false);
                resolve();
            } else {
                this.loadSourceFromBackend(height, controller, isInView)
                    .then((source) => {
                        if(source instanceof Error) {
                            this.elementProviderReference.enableErrorImage();
                            reject(source)
                            return
                        }
                        if(source) {
                            this.elementProviderReference.disableErrorImage();
                            this.sources[height] = source;
                            this.elementProviderReference.setSource(this.sources[height], height,false);
                            resolve()
                        } else {
                            // Loading was Aborted!
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }
        });

        return [loadPromise, controller, height];
    }
}

export default ImageSourceProvider;
