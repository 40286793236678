<script setup>
import {useI18n} from "vue-i18n";

const i18n = useI18n();
const {t} = i18n
import {canViewDeadAnimalsHelper, canViewRegionsHelper} from "@/composables/permissionHelper";
import {computed, onMounted, ref, watch} from "vue";
import {useRegionStore} from "@/store/regions";

const props = defineProps({
  pluginConfig: {type: Object, default: () => ({})},
  vertical: {type: Boolean, default: false},
})

const emit = defineEmits(["update:pluginConfig"])


const canViewDeadAnimals = computed(() => canViewDeadAnimalsHelper())
const canViewRegions = computed(() => canViewRegionsHelper())

const showRegions = ref(false)
const regionType = ref(null)
const regionOpacity = ref(40)
const showDeadAnimals = ref(false)
const deadAnimalMarkerType = ref("circle")
const availableMarkerTypes = [
  {value: "cross", title: t('canvas.settings.overlay.dead_animals.marker_types.cross')},
  {value: "circle", title: t('canvas.settings.overlay.dead_animals.marker_types.circle')},
  {value: "box", title: t('canvas.settings.overlay.dead_animals.marker_types.box')},
  {value: "dot", title: t('canvas.settings.overlay.dead_animals.marker_types.dot')},
]
function updatePluginConfig() {
  const pluginConfig = Object.assign({}, props.pluginConfig, {
    regions: {
      enabled: showRegions.value,
      regionType: regionType.value ?? regionTypes.value[0],
      regionOpacity: regionOpacity.value ?? 40,
    },
    deadAnimals: {
      enabled: showDeadAnimals.value,
      markerType: deadAnimalMarkerType.value,
    }
  })
  emit("update:pluginConfig", pluginConfig)
}

const regionTypes = computed(() => {
  const types = useRegionStore().getRegionTypes()
  if (!types) return []
  return Object.keys(types)
})

watch(deadAnimalMarkerType, () => {
  updatePluginConfig()
})
watch(() => props.pluginConfig, (value) => {
  showRegions.value = value?.regions?.enabled === true
  regionType.value = value?.regions?.regionType ?? null
  regionOpacity.value = value?.regions?.regionOpacity ?? 40
  showDeadAnimals.value = value?.deadAnimals?.enabled === true
  deadAnimalMarkerType.value = value?.deadAnimals?.markerType ?? "circle"
}, {immediate: true})
onMounted(() => {
  useRegionStore().keepRegionsLoaded()
})

</script>

<template>
  <v-container
    class="ma-0 pa-0 fill-height"
    :fluid="true"
  >
    <v-row
      :dense="true"
      :no-gutters="true"
      class="justify-center"
    >
      <v-col
        v-if="canViewRegions"
        :cols="vertical ? 12 : 6"
      >
        <v-switch
          v-model="showRegions"
          class="ma-2"
          :disabled="!regionTypes.length"
          :hide-details="true"
          label="Show Regions"
          @update:model-value="updatePluginConfig"
        />
        <v-autocomplete
          variant="outlined"
          v-model="regionType"
          :disabled="!showRegions"
          :hide-details="true"
          label="Region Type"
          class="ma-2"
          :items="regionTypes"
          @update:model-value="updatePluginConfig"
        />
        <v-slider
          v-model="regionOpacity"
          :disabled="!showRegions"
          label="Region Opacity"
          class="ma-2"
          :max="100"
          :min="25"
          :step="1"
          @end="updatePluginConfig"
        />
      </v-col>
      <v-divider
        v-if="canViewDeadAnimals && canViewRegions"
        :vertical="!vertical"
      />
      <v-col
        v-if="canViewDeadAnimals"
        :cols="vertical ? 12 : 5"
      >
        <v-switch
          v-model="showDeadAnimals"
          class="ma-2"
          :hide-details="true"
          label="Show Dead Animals"
          @update:model-value="updatePluginConfig"
        />
        <v-select
          :disabled="!showDeadAnimals"
          class="ma-2"
          variant="outlined"
          :label="$t('canvas.settings.overlay.dead_animals.marker_type')"
          v-model="deadAnimalMarkerType"
          :items="availableMarkerTypes"
          item-value="value"
          :return-object="false"
          item-title="title"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
