<script setup>
import {useTheme} from 'vuetify'

import {onMounted, ref} from "vue";
import {loadLabelCountsMeasurement} from "@/composables/charts";
import {useMeasurementStore} from "@/store/measurements";
import CameraBaseChart from "@/components/charts/CameraBaseChart.vue";

const props = defineProps(
  {
    cameraIds: {type: Array, default: () => []},
    aggregateCameras: {type: Boolean, default: false},
    aggregateLabels: {type: Boolean, default: false},
    title: {type: String, default: ""},
    timeSetup: {type: Object, default: null},
    chainTimeSetup: {type: Boolean, default: false}
  }
)
defineEmits(["updatedTimeSetup"])

const options = ref({
    stroke: {
      width: 2,
      curve: "smooth",
      lineCap: "butt",
    },
    theme: {
      mode: useTheme().name.value,
    },
    title: {
      text: props.title,
      margin: 16,
    },
    chart: {
      type: "line",
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
    },
  }
)


let labels = []
const readyToRender = ref(false)
async function dataLoader(cameraIds, timeSetup) {
  const data = await loadLabelCountsMeasurement({
    cameraIds: cameraIds,
    timeResolution : timeSetup.timeResolution,
    timeQuery: {start: timeSetup.start, end: timeSetup.end},
    aggregateCameras: props.aggregateCameras,
    aggregateLabels: props.aggregateLabels,
    labels: labels,
    chartType: "line",
    chainTimeSetup: {type: Boolean, default: false}
  })
  options.value.labels = data.seriesNames
  return data
}


onMounted(async () => {
  labels = await useMeasurementStore().loadUsedLabels();
  readyToRender.value = true
})


</script>

<template>
  <CameraBaseChart
    v-if="readyToRender"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :time-setup="timeSetup"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
