<script setup>

import WYSIWYG from "@/components/WYSIWYG.vue";
import {inputRequiredRule} from "@/composables/inputRules";
import DateTimePicker from "@/components/DateTimePicker.vue";
import i18n from "../../plugins/i18n"
import {useToast} from "vue-toastification";

const toast = useToast();
const {t} = i18n.global

import {computed, onMounted, ref, watch} from "vue";
import {useIncidentStore} from "@/store/incident";
import CameraPicker from "@/components/formComponents/CameraPicker.vue";
import RegionSpecificationPicker from "@/components/formComponents/RegionSpecificationPicker.vue";

const incidentStore = useIncidentStore()

const props = defineProps({
  incident: {type: Object, default: null},
  prefillLatestValues: {type: Boolean, default: false},
  showCancelButton: {type: Boolean, default: false},
  shrinkable: {type: Boolean, default: false}
})
const emit = defineEmits(["saved", "cancel"])
const classification = ref(null)
const specification = ref(null)
const isGlobal = ref(false)
const cameras = ref([])
const regionSpecifications = ref([])
const timestamp = ref(null)
const startTimestamp = ref(null)
const endTimestamp = ref(null)
const description = ref(null)
const schema = ref(null)
const form = ref(null)
const loading = ref(false)
const hideReason = ref(null)
const firstEvent = ref(null)
const expanded = ref(false)

const availableClassifications = computed(() => {
  if (schema.value === null) return []
  const classifications = Object.keys(schema.value.barnDocumentation)
  return classifications.map(classification => ({
    value: classification,
    title: t("incidents.barnDocumentation.classifications." + classification)
  }))
})

const availableSpecifications = computed(() => {
  if (schema.value === null || classification.value === null) return []
  const specifications = Object.keys(schema.value.barnDocumentation[classification.value])
  return specifications.map(specification => ({
    value: specification,
    title: t("incidents.barnDocumentation.specificationTypes." + specification)
  }))
})

const useTimespan = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].time === "timespan"
})

const useTimestamp = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].time === "timestamp"
})

const allowGlobal = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].location.includes("global")
})

const allowRegions = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].location.includes("regionSpecification")
})

const allowCameraIds = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].location.includes("cameraId")
})

watch(classification, () => {
  specification.value = null
})

watch(specification, () => {
  timestamp.value = null
  startTimestamp.value = null
  endTimestamp.value = null
  description.value = null
  isGlobal.value = false
  cameras.value = []
  regionSpecifications.value = []
})

watch(isGlobal, () => {
  if (isGlobal.value) {
    cameras.value = []
    regionSpecifications.value = []
  }
})

function resetFields() {
  classification.value = null
  specification.value = null
  timestamp.value = null
  startTimestamp.value = null
  endTimestamp.value = null
  description.value = null
  isGlobal.value = false
  cameras.value = []
  regionSpecifications.value = []
  hideReason.value = null
}

async function saveDocumentation() {
  let incidentPayload

  if (!props.incident) {
    form.value.validate();
    if (!form.value.isValid) {
      toast.error(t("toast.fix_errors_above"))
      return;
    }
    if (!isGlobal.value && !cameras.value.length && !regionSpecifications.value.length) {
      toast.error(t("incidents.barnDocumentation.no_location_selected"))
      return;
    }
    if (!timestamp.value && !startTimestamp.value && !endTimestamp.value) {
      toast.error(t("incidents.barnDocumentation.no_time_selected"))
      return;
    }
    incidentPayload = {
      type: "barnDocumentation",
      trigger: "create",
      identifier: {},
      payload: {
        classification: classification.value,
        specification: {
          type: specification.value,
          location: isGlobal.value ? 'global' : {
            cameraId: allowCameraIds.value && cameras.value && cameras.value.length > 0 ? cameras.value : undefined,
            regionSpecification: allowRegions.value && regionSpecifications.value && regionSpecifications.value.length > 0 ? regionSpecifications.value : undefined,
          },
          timestamp: useTimestamp.value ? timestamp.value.toMillis() : undefined,
          startTimestamp: useTimespan.value ? startTimestamp.value.toMillis() : undefined,
          endTimestamp: useTimespan.value ? endTimestamp.value.toMillis() : undefined,
        },
        description: description.value ? description.value : undefined
      }
    }
  } else {
    if (props.incident.state === "created") {
      form.value.validate();
      if (!form.value.isValid) {
        toast.error(t("toast.fix_errors_above"))
        return;
      }
      incidentPayload = {
        type: "barnDocumentation",
        trigger: "hide",
        identifier: props.incident.identifier,
        payload: {
          reason: hideReason.value
        }
      }
    }
    if (props.incident.state === "hidden") {
      incidentPayload = {
        type: "barnDocumentation",
        trigger: "create",
        identifier: props.incident.identifier,
        payload: firstEvent.value.payload
      }
    }
  }
  loading.value = true
  const successful = await incidentStore.triggerGenericIncident(incidentPayload)
  loading.value = false
  if (!successful) {
    toast.error(t("toast.generic_could_not_save"))
    return;
  }
  toast.success(t("toast.generic_saved"))
  emit("saved")
}

onMounted(async () => {
  schema.value = await incidentStore.getSimplifiedIncidentSchema("barnDocumentation")
  if (props.incident) {
    firstEvent.value = await incidentStore.loadFirstEventForIncident(props.incident.incidentId)
  }
})
</script>

<template>
  <v-layout-card
    :loading="loading"
    :disabled="loading"
  >
    <v-row :dense="true" class="justify-space-between">
      <v-col cols="auto">

        <v-card-title>
          {{ incident ? $t("incidents.barnDocumentation.update_title") : $t("incidents.barnDocumentation.new_title") }}
        </v-card-title>
      </v-col>
      <v-col cols="auto" v-if="shrinkable">
        <v-btn class="ma-2" :icon="expanded? 'mdi-chevron-up': 'mdi-chevron-down'" variant="tonal" color="primary"
               @click="expanded = !expanded"></v-btn>
      </v-col>
    </v-row>
    <v-expand-transition mode="in-out">

      <v-card-text v-if="!shrinkable || expanded">
        <v-form
          v-if="!incident"
          ref="form"
        >
          <h3 class="mt-2 mb-6">
            {{ $t("incidents.barnDocumentation.classification") }}
          </h3>

          <v-autocomplete
            v-model="classification"
            :items="availableClassifications"
            variant="outlined"
            :label="$t('incidents.barnDocumentation.classification')"
            :rules="[inputRequiredRule]"
          />
          <v-autocomplete
            v-model="specification"
            :items="availableSpecifications"
            variant="outlined"
            :label="$t('incidents.barnDocumentation.specification')"
            :disabled="classification === null"
            :rules="[inputRequiredRule]"
          />

          <div v-if="allowGlobal || allowCameraIds || allowRegions">
            <v-divider/>
            <h3
              class="my-2"
            >
              {{ $t("incidents.barnDocumentation.location") }}
            </h3>
            <v-checkbox
              v-if="allowGlobal"
              v-model="isGlobal"
              :label="$t('incidents.barnDocumentation.is_global')"
            />

            <CameraPicker
              v-if="allowCameraIds"
              v-model="cameras"
              :disabled="isGlobal"
              :label="$t('general_interface.camera_picker.label')"
            />

            <RegionSpecificationPicker
              v-if="allowRegions"
              v-model="regionSpecifications"
              :disabled="isGlobal"
              :label="$t('general_interface.region_specification_picker.label')"
            />
          </div>

          <div v-if="useTimestamp || useTimespan">
            <v-divider/>

            <h3 class="my-2">
              {{ $t("incidents.barnDocumentation.time") }}
            </h3>

            <DateTimePicker
              v-if="useTimestamp"
              v-model="timestamp"
              :label="$t('general_interface.date_time_picker.label')"
            />

            <v-row v-if="useTimespan">
              <v-col>
                <DateTimePicker
                  v-model="startTimestamp"
                  :label="$t('general_interface.date_time_picker.start')"
                />
              </v-col>
              <v-col>
                <DateTimePicker
                  v-model="endTimestamp"
                  :label="$t('general_interface.date_time_picker.end')"
                />
              </v-col>
            </v-row>
          </div>

          <div>
            <v-divider/>
            <h3 class="my-2">
              {{ $t("incidents.barnDocumentation.additional") }}
            </h3>
            <WYSIWYG
              v-model="description"
              :label="$t('incidents.barnDocumentation.titles.description')"
            />
          </div>
        </v-form>
        <v-container v-if="incident && incident.state === 'created'">
          <v-alert type="info">
            {{ $t("incidents.barnDocumentation.hide_info") }}
          </v-alert>
          <v-form ref="form">
            <WYSIWYG
              v-model="hideReason"
              class="mt-4"
              :label="$t('incidents.barnDocumentation.hide_reason')"
              :rules="[inputRequiredRule]"
            />
          </v-form>
        </v-container>
        <v-container v-if="incident && incident.state === 'hidden'">
          <v-alert type="info">
            {{ $t("incidents.barnDocumentation.unhide_info") }}
          </v-alert>
        </v-container>
      </v-card-text>
    </v-expand-transition>

    <v-fade-transition mode="in-out">

      <v-card-actions v-if="!shrinkable || expanded" class="justify-end">
        <v-btn
          v-if="!incident || incident.state !== 'hidden'"
          color="error"
          variant="outlined"
          @click="resetFields"
        >
          {{ $t("general_interface.buttons.reset") }}
        </v-btn>
        <v-btn
          v-if="showCancelButton"
          variant="outlined"
          color="error"
          @click="$emit('cancel')"
        >
          {{ $t("general_interface.buttons.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          @click="saveDocumentation"
        >
          {{ $t("general_interface.buttons.save") }}
        </v-btn>
      </v-card-actions>
    </v-fade-transition>
    <v-fade-transition mode="in-out">
      <v-card-actions v-if="shrinkable && !expanded" class="justify-center">
        <v-btn variant="flat" color="success" class="rounded-pill" @click="expanded = true">
          {{ $t("barn_documentation_list.add") }}
        </v-btn>
      </v-card-actions>
    </v-fade-transition>

  </v-layout-card>
</template>

<style scoped>

</style>
