<script setup>

import {onMounted, ref, watch} from "vue";
import {splitIncidents, splittingModes} from "@/composables/incidents/incidentPlottingHelpers";
import {useTheme} from "vuetify";
import i18n from "@/plugins/i18n";
const {t} = i18n.global
const props = defineProps({
  mode: {type: String, default: splittingModes.opened},
  start: {type: Number, default: 0},
  end: {type: Number, default: 0},
  timeResolution: {type: Number, default: 6},
  incidents: {type: Array, default: () => []},
  dimensionKey: {type: String, default: "type"},
  timeShift: {type: Number, default: 0},
  dimensionNamingDictionary: {type: Object, default: () => ({})}
})
const renderChart = ref(false)
const options = ref({})
const series = ref()
const theme = useTheme().name.value
watch(() => props.incidents, () => {
  reRender()
})

watch(() => props.timeResolution, () => {
  reRender()
})

watch(() => props.timeShift, () => {
  reRender()
})

function reRender() {
  renderChart.value = false
  options.value = {
    theme: {
      mode: theme,
    },
    chart: {
      id: 'vuechart-example',
      type: props.mode === splittingModes.open ? "line" : "bar",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        datetimeUTC: false
      }
    },
    tooltip: {
      x: {
        format: "dd.MM.yyyy HH:mm:ss",
      },
    },
    annotations: {xaxis: []}

  }
  const splitResult = splitIncidents(props.incidents, props.timeResolution, props.start, props.end, props.mode, props.dimensionKey, props.timeShift, props.dimensionNamingDictionary)
  options.value.xaxis.categories = splitResult.segmentBounds.map(({start}) => start)
  options.value.annotations.xaxis = splitResult.segmentBounds.filter(({includesDSTShift}) => includesDSTShift).map(({start}) => {
    return {
      x: start,
      strokeDashArray: 0,
      borderColor: "dodgerblue",
      label: {
        borderColor: "#775DD0",
        style: {
          background: "dodgerblue",
        },
        text: t("charts.dst_shift"),
      }
    }
  })
  series.value = splitResult.series
  renderChart.value = true
}

onMounted(async () => {
  reRender()
})
</script>

<template>
  <apexchart
    v-if="renderChart"
    :height="400"
    :series="series"
    :options="options"
  />
</template>

<style scoped>

</style>
