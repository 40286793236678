<script setup>

import {computed, onMounted, ref, watch} from "vue";
import {buildDataTable, buildItemForIncidentEvent} from "@/composables/incidents/incidentListRenderer";
import {useIncidentStore} from "@/store/incident";
import WYSIWYG from "@/components/WYSIWYG.vue";
import {useRouter} from "vue-router";
import Incident from "@/components/incidents/IncidentDisplay.vue";
import {useDisplay} from "vuetify";
import {useMiscellaneousStore} from "@/store/miscellaneous";
const incidentStore = useIncidentStore()
const router = useRouter()
const {mobile} = useDisplay()
const miscellaneousStore = useMiscellaneousStore()
const props = defineProps({
  incidents: {type: Array, default: () => []},
  predefinedViewMode: {type: String, required: false, default: null}
})

defineEmits(["reloadIncident"])
const headers = ref([])
const incidentItems = ref([])
const eventItems = ref({})
const incidentEvents = ref({})
const expandedItems = ref([])
const eventsLoading = ref(false)


const tableItems = computed(() =>  {
  const items = [...incidentItems.value]
  for (let expandedItem of expandedItems.value) {
    const index = items.findIndex(item => item.id === expandedItem)
    if(index === -1 || !eventItems.value[expandedItem]) continue
    items.splice(index + 1, 0, ...eventItems.value[expandedItem])
  }
  return items;
})


function goToIncident(incidentId) {
  const routeData = router.resolve({name: "IncidentView", params: {incidentId: incidentId}});
  window.open(routeData.href, '_blank');
}

function setupTable() {
  const dataTableSetup = buildDataTable(props.incidents)
  headers.value = dataTableSetup.headers
  incidentItems.value = dataTableSetup.items
}

async function toggleExpansion(itemId) {
  if(expandedItems.value.includes(itemId)) {

    const index = expandedItems.value.indexOf(itemId)
    expandedItems.value.splice(index, 1)
  } else {
    eventsLoading.value = true;

    if(!Object.keys(eventItems.value).includes(itemId)) {
      incidentEvents.value[itemId] = await incidentStore.loadEventsForIncident(itemId)
      const incident = props.incidents.find(incident => incident.incidentId === itemId)
      eventItems.value[itemId] = incidentEvents.value[itemId].map((incidentEvent) => buildItemForIncidentEvent(incidentEvent, incident))
    }

    expandedItems.value.push(itemId)
    eventsLoading.value = false;

  }
}

watch(() => props.incidents, () => {setupTable()})
const viewMode = ref("list")

watch(() => viewMode.value, () => {
  miscellaneousStore.incidentListDisplayMode = viewMode.value
})

onMounted(() => {
  viewMode.value = props.predefinedViewMode ? props.predefinedViewMode : miscellaneousStore.incidentListDisplayMode
  setupTable()
})

</script>

<template>

    <v-row
      v-if="!mobile"
      justify="end"
    >
      <v-col cols="auto">
        <v-btn-toggle
          v-model="viewMode"
          :mandatory="true"
        >
          <v-btn
            value="table"
            icon="mdi-table"
            color="primary"
          />
          <v-btn
            value="list"
            icon="mdi-list-box"
            color="primary"
          />
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="eventsLoading"
      :indeterminate="true"
    />
    <v-table
      v-if="viewMode === 'table' && !mobile"
      class="ma-0"
      :fixed-header="true"
    >
      <thead>
        <tr>
          <th class="text-center">
            {{ $t("incidentList.headers.expand") }}
          </th>
          <th
            v-for="header in headers"
            :key="header.id"
            class="text-center"
          >
            {{ header.title }}
          </th>
          <th><!-- for "more" button, doesn't need a header text --></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tableItems"
          :key="item.id"
          class="rounded"
        >
          <td
            :style="`border: 1px solid ${item.isIncident ? 'black' : 'grey'}`"
          >
            <v-btn
              v-if="item.isIncident === true"
              :icon="expandedItems.includes(item.id) ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              variant="tonal"
              color="primary"
              size="small"
              @click="toggleExpansion(item.id)"
            />
          </td>
          <td
            v-for="header in headers"
            :key="`${item.id}_${header.id}`"
            :class="header.wysiwyg ? undefined : 'text-center'"
            :style="`border: 1px solid ${item.isIncident ? 'black' : 'grey'}`"
          >
            <WYSIWYG
              v-if="header.wysiwyg"
              :model-value="item[header.key]"
              :read-only="true"
            />
            <span v-if="!header.wysiwyg">{{ item[header.key] }}</span>
          </td>
          <td :style="`border: 1px solid ${item.isIncident ? 'black' : 'grey'}`">
            <v-btn
              v-if="item.isIncident === true"
              size="small"
              variant="flat"
              color="primary"
              class="rounded-pill"
              @click="goToIncident(item.id)"
            >
              {{ $t("general_interface.buttons.more") }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-row
      v-if="viewMode === 'list' || mobile"
      class="my-2"
      no-gutters
    >
      <v-col
        v-for="incident of incidents"
        :key="incident.incidentId"
        :cols="12"
      >
        <Incident
          class="ma-2"
          :incident="incident"
          @reload="$emit('reloadIncident', incident.incidentId)"
        />
      </v-col>
    </v-row>
</template>

<style scoped>

</style>
