<script setup>

import CameraPicker from "@/components/formComponents/CameraPicker.vue";
import {computed, onMounted, ref, watch} from "vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import {useCamerasStore} from "@/store/cameras";
import {useCameraName} from "@/composables/cameraHelpers";
import {humanReadableDuration, humanReadableTimestamp} from "@/composables/datetime";
import {useRoute, useRouter} from "vue-router";
import {useDownloadFile} from "@/composables/downloadFile";
import {DateTime} from "luxon";
import {dateTimeInPast, inputRequiredRule} from "@/composables/inputRules";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import sv from "@/assets/video-off-outline.svg"

const toast = useToast();
const i18n = useI18n();
const {t} = i18n
const props = defineProps({
  useQueryString: {type: Boolean, default: true},
  prePickedCamera: {type: String, default: null},
  cameraReadOnly: {type: Boolean, default: false},
  timestampPickerMode: {type: Boolean, default: false},
})

const emit = defineEmits(["pickTimestamp"])

const camerasStore = useCamerasStore()
const router = useRouter()
const route = useRoute()
const intervals = [
  1000,
  1000 * 10,
  1000 * 30,
  1000 * 60,
  1000 * 60 * 5,
  1000 * 60 * 10,
  1000 * 60 * 30,
  1000 * 60 * 60,
  1000 * 60 * 60 * 3,
  1000 * 60 * 60 * 6,
  1000 * 60 * 60 * 12,
  1000 * 60 * 60 * 24,
  1000 * 60 * 60 * 24 * 7,
]
const frameCount = 25

const cameraId = ref(null)
const timestamp = ref(DateTime.now().minus({minutes: 10}))
const interval = ref(1000 * 60 * 60)
const highQualityFrames = ref([])
const lowQualityFrames = ref([])
const highQuality = ref(false)
const displayFrame = ref(null)
const form = ref(null)

const isLoading = computed(() => {
  return frameStore.value.value[cameraId.value]?.[timestamp.value.toMillis()]?.loading
})

const canSkipForward = computed(() => {
  return timestamp.value.plus({millisecond: interval.value}).toMillis() <= DateTime.now().toMillis()
})

const frameStore = computed(() => {
  return highQuality.value ? highQualityFrames : lowQualityFrames
})

const availableIntervals = computed(() => {
  return intervals.map(interval => ({value: interval, title: humanReadableDuration(interval)}))
})


watch(cameraId, async () => {
  await updateQueryParams()
  return validateAndLoad()
})

watch(timestamp, async () => {
  await updateQueryParams()
  return validateAndLoad()
})

watch(highQuality, async () => {
  await updateQueryParams()
  return validateAndLoad()
})


async function updateQueryParams() {
  if (!props.useQueryString) return
  const query = {
    cameraId: cameraId.value,
    timestamp: timestamp.value.toMillis(),
    highQuality: highQuality.value
  }
  router.replace({query})
}

async function jumpBackward() {
  timestamp.value = timestamp.value.minus({millisecond: interval.value})
}

async function jumpForward() {
  timestamp.value = timestamp.value.plus({millisecond: interval.value})
}

async function downloadFrame() {
  if (!displayFrame.value) return
  if (!highQuality.value) {
    highQuality.value = true
    await loadFrame()
    highQuality.value = false
  }
  useDownloadFile(displayFrame.value, useCameraName(cameraId.value) + "_" + timestamp.value.toISO() + ".jpg")
}

function evictFromHolder(holder) {
  if (!holder.value) return
  const entries = []
  for (const cameraId of Object.keys(holder.value)) {
    for (const timestamp of Object.keys(holder.value[cameraId])) {
      entries.push({
        cameraId: cameraId,
        timestamp: Number.parseInt(timestamp),
        loadedTimestamp: holder.value[cameraId][timestamp].loadedTimestamp
      })
    }
  }
  if (entries.length <= frameCount) return
  entries.sort((a, b) => a.loadedTimestamp - b.loadedTimestamp)
  const toDelete = entries.slice(0, entries.length - frameCount)
  for (const entry of toDelete) {
    delete holder.value[entry.cameraId][entry.timestamp]
  }
}

function evictFrames() {
  evictFromHolder(highQualityFrames)
  evictFromHolder(lowQualityFrames)
}

async function validateAndLoad() {
  await form.value.validate()
  if (!form.value.isValid) {
    toast.error(t("toast.fix_errors_above"))
    return
  }

  return loadFrame()
}

async function loadFrame() {
  if (!cameraId.value || !timestamp.value) return
  if (!Object.keys(frameStore.value.value).includes(cameraId.value)) {
    frameStore.value.value[cameraId.value] = {}
  }
  if (!Object.keys(frameStore.value.value[cameraId.value]).includes(timestamp.value.toMillis().toString())) {
    frameStore.value.value[cameraId.value][timestamp.value.toMillis()] = {
      loading: true,
    }
    const frame = await camerasStore.loadFrameForTimestamp(cameraId.value, timestamp.value.toMillis(), highQuality.value ? 1080 : 150, false, 1000 * 31)
    frameStore.value.value[cameraId.value][timestamp.value.toMillis()] = {
      frame: frame,
      loading: false,
      loadedTimestamp: DateTime.now()
    }
    if (typeof frame === 'string')
      displayFrame.value = frame
    else {
      displayFrame.value = null
    }
    evictFrames()
  } else {
    displayFrame.value = frameStore.value.value[cameraId.value][timestamp.value.toMillis().toString()].frame
  }
}


onMounted(() => {
  if (props.prePickedCamera) {
    cameraId.value = props.prePickedCamera
  }
  if (!props.useQueryString) return
  const queryParams = route.query
  if (queryParams.cameraId) {
    cameraId.value = queryParams.cameraId
  }
  if (queryParams.timestamp) {
    timestamp.value = DateTime.fromMillis(Number.parseInt(queryParams.timestamp))
  }
  if (queryParams.highQuality) {
    highQuality.value = queryParams.highQuality === "true"
  }
})

</script>

<template>

  <v-layout-card>
    <v-card-text>

      <v-form ref="form">

        <v-row class="justify-center align-center" :dense="true" :no-gutters="true">
          <v-col
            cols="auto"
          >
            <CameraPicker
              :hide-details="true"
              v-model="cameraId"
              :select-first="true"
              :read-only="props.cameraReadOnly"
              :label="$t('general_interface.camera_picker.label_single')"
              :multiple="false"
              :rules="[inputRequiredRule]"
            />
          </v-col>
          <v-col
            cols="auto"
          >
            <DateTimePicker
              :hide-details="true"
              v-model="timestamp"
              :max="DateTime.now()"
              class="ma-2"
              :rules="[dateTimeInPast, inputRequiredRule]"
            />
          </v-col>

          <v-col cols="auto">

              <v-switch
                :hide-details="true"
                class="ma-2"
                v-model="highQuality"
                :label="$t('frame_seek.high_quality')"
              />


          </v-col>
          <v-col cols="auto">
            <v-autocomplete
              v-model="interval"
              :hide-details="true"
              variant="outlined"
              class="ma-2"
              style="min-width: 200px;"
              :label="$t('frame_seek.interval')"
              :items="availableIntervals"
            />
          </v-col>
        </v-row>

      </v-form>
    </v-card-text>

  </v-layout-card>
  <v-layout-card>
    <v-card-text>
      <v-row
        v-if="cameraId && timestamp"
        class="justify-center"
      >
        <v-col
          :cols="12"
          class="justify-center"
        >
          <h1
            v-if="displayFrame"
            class="text-center"
          >
            {{ useCameraName(cameraId) }} {{ humanReadableTimestamp(timestamp.toMillis()) }}
          </h1>
        </v-col>
        <v-col
          v-if="!displayFrame && !isLoading"
          :cols="12"
        >
          <v-alert type="error">{{ $t('frame_seek.could_not_load') }}</v-alert>
        </v-col>

        <v-col :cols="12">
          <v-img
            height="60vh"
            :cover="false"
            :src="displayFrame"
            class="justify-center align-center"
          >
            <v-row v-if="!displayFrame && !isLoading" class="justify-center">
              <v-icon
                color="red"
                size="30vh" icon="mdi-video-off"></v-icon>
            </v-row>
            <v-row
              v-if="isLoading"
              class="w-100 justify-center"
            >
              <v-progress-circular
                size="100"
                width="10"
                :indeterminate="true"
              />
            </v-row>
          </v-img>

        </v-col>

        <v-col
          v-if="displayFrame"
          :cols="12"
        >
          <v-row class="justify-center">
            <v-btn
              color="success"
              variant="elevated"
              @click="downloadFrame"
              class="rounded-pill"
            >
              {{ $t("general_interface.buttons.download") }}
            </v-btn>
          </v-row>
        </v-col>

        <v-row class="justify-center align-center my-4">
          <v-btn
            prepend-icon="mdi-arrow-left"
            :rounded="true"
            class="ma-2"
            color="primary"
            variant="elevated"
            @click="jumpBackward"
          >
            {{ $t("general_interface.buttons.backward") }}
          </v-btn>
          <v-btn
            :disabled="!canSkipForward"
            append-icon="mdi-arrow-right"
            :rounded="true"
            class="ma-2"
            color="primary"
            variant="elevated"
            @click="jumpForward"
          >
            {{ $t("general_interface.buttons.forward") }}
          </v-btn>
        </v-row>
        <v-col
          v-if="props.timestampPickerMode"
          :cols="12"
        >
          <v-btn
            :block="true"
            variant="flat"
            color="primary"
            @click="emit('pickTimestamp', timestamp)"
          >
            {{ $t("general_interface.date_time_picker.confirm") }}
          </v-btn>
        </v-col>
      </v-row>

    </v-card-text>
  </v-layout-card>
</template>

<style scoped>

</style>
