<script setup>
import {useInstanceStore} from "@/store/backendInstances";
import {onMounted, ref, watch} from "vue";

defineProps({
  disabled: {type: Boolean, default: false},
})
const modelValue = defineModel({type: String})
const instanceStore = useInstanceStore()
const accounts = ref([])
const loading = ref(false)

async function reloadAccounts() {
  loading.value = true
  accounts.value = await instanceStore.getAccounts()
  accounts.value = accounts.value.map(account => {
    return {...account, title: `${account.displayName}  (${account.accountId})`}
  })
  loading.value = false
}

watch(modelValue, async (newValue) => {
  if (newValue === null) return
  if (accounts.value.map(account => account.accountId).includes(newValue)) return
  await reloadAccounts()
}, {})
onMounted(async () => {
  await reloadAccounts()
})

</script>

<template>
  <v-autocomplete
    :loading="loading"
    :disabled="disabled"
    style="min-width: 250px;"
    :label="$t('general_interface.account_picker.label')"
    :items="accounts"
    item-value="accountId"
    item-title="title"
    v-model="modelValue"
  />
</template>

<style scoped>

</style>
