import artifact from './artifact'
import incident from './incident'
import recordings from './recordings'

const filters = {artifact, incident, recordings}



function useFilter(filterType) {
  return filters[filterType]
}

export {useFilter}
