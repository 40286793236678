const NIN = "nin"
const IN = "in"
const GTE = ">="
const LTE = "<="
const GT = ">"
const LT = "<"
const EQ = "=="
const NEQ = "!="
const EXISTS = "exists"
const CONTAINS = "contains"
const ALL = "all"
const ANY = "any"
export {NIN, IN, GTE, LTE, GT, LT, EQ, NEQ, EXISTS, CONTAINS, ALL, ANY}
