<script setup>

import {computed, onMounted, ref} from "vue";
import {useIncidentStore} from "@/store/incident";
import i18n from "../../plugins/i18n"
import WYSIWYG from "@/components/WYSIWYG.vue";
import {inputRequiredRule} from "@/composables/inputRules";
import DateTimePicker from "@/components/DateTimePicker.vue";
import {useToast} from "vue-toastification";

const incidentStore = useIncidentStore()

const toast = useToast();

const {t} = i18n.global

const props = defineProps({
  incident: {type: Object, default: null},
  prefillLatestValues: {type: Boolean, default: true},
  showCancelButton: {type: Boolean, default: false},
  shrinkable: {type: Boolean, default: false}
})
const emit = defineEmits(["saved", "cancel"])

const form = ref(null)
const loading = ref(false)
const schema = ref(null)
const title = ref(null)
const interventionType = ref(null)
const interventionIssue = ref(null)
const severityIndex = ref(0)
const issuePersists = ref(true)
const description = ref(null)
const specifyTime = ref(false)
const eventTimestamp = ref(null)
const severityLabels = ref({
  0: t("incidents.barnIntervention.severities.none"),
  1: t("incidents.barnIntervention.severities.low"),
  2: t("incidents.barnIntervention.severities.middle"),
  3: t("incidents.barnIntervention.severities.high"),
})
const expanded = ref(false)

const availableTypes = computed(() => {
  if (schema.value === null) return []
  const types = Object.keys(schema.value.barnIntervention.typeSetup)
  return types.map(type => ({value: type, title: t("incidents.barnIntervention.types." + type)}))
})

const availableIssues = computed(() => {
  if (schema.value === null || interventionType.value === null) return []
  const issues = schema.value.barnIntervention.typeSetup[interventionType.value]
  return issues.map(issue => ({value: issue, title: t("incidents.barnIntervention.issues." + issue)}))
})

const severityColor = computed(() => {
  if (severityIndex.value === 3) return 'red';
  if (severityIndex.value === 2) return 'orange';
  if (severityIndex.value === 1) return 'green';
  if (severityIndex.value === 0) return 'blue';
  return undefined;
})

const severity = computed(() => {
  switch (severityIndex.value) {
    case 0:
      return "none"
    case 1:
      return "low"
    case 2:
      return "middle"
    case 3:
      return "high"
  }
  return null
})

const getSeverityIndexFromSeverity = (severity) => {
  switch (severity) {
    case "none":
      return 0
    case "low":
      return 1
    case "middle":
      return 2
    case "high":
      return 3
  }
}

async function saveIntervention() {
  form.value.validate();
  if (!form.value.isValid) {
    toast.error(t("toast.fix_errors_above"))
    return;
  }
  const incidentPayload = {
    type: "barnIntervention",
    trigger: issuePersists.value ? "issueDetected" : "noIssueDetected",
    eventTimestamp: specifyTime.value ? eventTimestamp.value.toMillis() : undefined,
    identifier: {},
    payload: {
      title: title.value,
      type: interventionType.value,
      issue: interventionIssue.value ? interventionIssue.value : undefined,
      severity: severity.value,
      description: description.value,
    }
  }
  if (props.incident) incidentPayload.identifier = props.incident.identifier
  loading.value = true
  const successful = await incidentStore.triggerGenericIncident(incidentPayload)
  loading.value = false
  if (!successful) {
    toast.error(t("toast.generic_could_not_save"))
    return;
  }
  toast.success(t("toast.generic_saved"))
  emit("saved")
}

onMounted(async () => {
  schema.value = await incidentStore.getSimplifiedIncidentSchema("barnIntervention")
  if (props.incident && props.prefillLatestValues) {
    title.value = props.incident.messagePayload.title
    interventionType.value = props.incident.messagePayload.type
    interventionIssue.value = props.incident.messagePayload.issue
    severityIndex.value = getSeverityIndexFromSeverity(props.incident.messagePayload.severity)
    description.value = props.incident.messagePayload.description
    issuePersists.value = props.incident.state === "actionRequired"
  }
})

</script>

<template>
  <v-layout-card
    :loading="loading"
    :disabled="loading"
  >
    <v-row :dense="true" class="justify-space-between">
      <v-col cols="auto">
        <v-card-title>
          {{ incident ? $t("incidents.barnIntervention.update_title") : $t("incidents.barnIntervention.new_title") }}
        </v-card-title>

      </v-col>
      <v-col cols="auto" v-if="shrinkable">
        <v-btn class="ma-2" :icon="expanded? 'mdi-chevron-up': 'mdi-chevron-down'" variant="tonal" color="primary"
               @click="expanded = !expanded"></v-btn>
      </v-col>
    </v-row>
    <v-expand-transition mode="in-out">
      <v-card-text v-if="!shrinkable || expanded">
        <v-form ref="form">
          <v-text-field
            v-model="title"
            :label="$t('incidents.barnIntervention.titles.title')"
            variant="outlined"
            :rules="[inputRequiredRule]"
          />

          <v-select
            v-model="interventionType"
            :label="$t('incidents.barnIntervention.titles.type')"
            variant="outlined"
            :items="availableTypes"
            :rules="[inputRequiredRule]"
            :disabled="!!incident"
          />

          <v-select
            v-model="interventionIssue"
            :label="$t('incidents.barnIntervention.titles.issue')"
            variant="outlined"
            :disabled="!availableIssues.length"
            :items="availableIssues"
          />

          <v-row class="align-center">
            <v-col cols="auto">
              <v-switch
                v-model="specifyTime"
                :label="$t('incidents.barnIntervention.specify_time')"
              />
            </v-col>
            <v-col>
              <DateTimePicker
                v-model="eventTimestamp"
                :disabled="!specifyTime"
              />
            </v-col>
          </v-row>

          <WYSIWYG v-model="description"/>

          <v-slider
            v-model="severityIndex"
            :label="$t('incidents.barnIntervention.titles.severity')"
            show-ticks="always"
            :color="severityColor"
            :ticks="severityLabels"
            :step="1"
            :max="3"
          />

          <v-switch
            v-model="issuePersists"
            :label="$t('incidents.barnIntervention.issuePersists')"
          />
        </v-form>
      </v-card-text>
    </v-expand-transition>

    <v-fade-transition mode="in-out">
      <v-card-actions v-if="!shrinkable || expanded" class="justify-end">
        <v-btn
          v-if="showCancelButton"
          variant="outlined"
          color="error"
          @click="$emit('cancel')"
        >
          {{ $t("general_interface.buttons.cancel") }}
        </v-btn>
        <v-btn
          variant="elevated"
          color="success"
          @click="saveIntervention"
        >
          {{ $t("general_interface.buttons.save") }}
        </v-btn>
      </v-card-actions>
    </v-fade-transition>
    <v-fade-transition mode="in-out">
      <v-card-actions v-if="shrinkable && !expanded" class="justify-center">
        <v-btn variant="flat" color="success" class="rounded-pill" @click="expanded = true">
          {{ $t("barn_intervention_list.add") }}
        </v-btn>
      </v-card-actions>
    </v-fade-transition>
  </v-layout-card>
</template>

<style scoped>

</style>
