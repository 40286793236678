import Syncable from "@/syncing/Syncable.js";
import {useInstanceStore} from "@/store/backendInstances.js";

export default class OwnPermissionsSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 30, OwnPermissionsSyncable.getSyncableHandle(), true, true)
  }

  hasInitialData() {
    return useInstanceStore().instances[this.globalUniqueIdentifier] && useInstanceStore().instances[this.globalUniqueIdentifier].availablePermissions
  }

  async workload() {
    await useInstanceStore().fetchOwnPermissions(this.globalUniqueIdentifier)
  }
  static getSyncableHandle() {
    return "own-permissions"
  }

}
