
function getAge(incident) {
  if(incident.state === "closed") return incident.lastEventTimestamp - incident.firstEventTimestamp;
  return Date.now() - incident.firstEventTimestamp
}

function getDeadAnimalIncidentColor(incident) {
  const age = getAge(incident)
  if(age >= 1000 * 60 * 60 * 24 * 2) return "red";
  if(age >= 1000 * 60 * 60 * 24) return "orange";
  return "green"
}
export {getAge, getDeadAnimalIncidentColor}
