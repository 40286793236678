import ImageSourceProvider from "@/components/canvas/CanvasElementSourceProviders/ImageSourceProvider";
import {useCamerasStore} from "@/store/cameras";
import {humanReadableTimestamp} from "@/composables/datetime";
import {useCameraName} from "@/composables/cameraHelpers.js";

class StillFrameProvider extends ImageSourceProvider {
  constructor(settings) {
    super(settings, "stillFrame");
  }

  async loadSourceFromBackend(height, controller, isInView) {
    const frameResponse = await useCamerasStore().loadFrameForTimestamp(this.cameraId, this.timestamp.toMillis(), height, false, 1000 * 60 * 5, controller.signal, isInView)
    this.tooltipText = () => `${useCameraName(this.cameraId)} ${humanReadableTimestamp(this.timestamp.toMillis())}`
    return frameResponse
  }

  handleDoubleClick(ev) {
    this.router.push({name: "CameraView", params: {cameraId: this.cameraId}})
    ev.evt.preventDefault()
  }

}

export default StillFrameProvider;
