import CanvasPluginProvider from "@/components/canvas/CanvasPluginProvider";
import {useRegionStore} from "@/store/regions";
import Konva from "konva";
import Polygon from "@/composables/Polygon";

class RegionPlugin extends CanvasPluginProvider {

  constructor(settings) {
    super(settings, "regions", false, false);
    this.mappings = []
    useRegionStore().keepRegionsLoaded()
  }

  fetchInformation() {
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      useRegionStore().ensureRegionsAreLoaded().then(() => {
        this.mappings = Object.values(useRegionStore().getRegionMappingsForCameras(Object.keys(this.elementPositions)))
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
    return [promise, controller]
  }

  draw() {
    if (this.group.isCached()) this.group.clearCache()
    this.group.opacity((this.config.regionOpacity ?? 40) / 100)
    this.group.destroyChildren()
    this.mappings.forEach(mapping => {
      const cameraId = mapping.cameraId
      const type = mapping.regionType
      if (this.config.regionType && type !== this.config.regionType) return;
      const {x, y, width, height, rotation} = this.elementPositions[cameraId]

      for (const region of mapping.regions.toReversed()) {
        const specification = region.regionSpecification
        const color = region.color ?? specification.color
        const canvasSpacePoints = region.polygon.map((point) => ({x: point.x * width, y: point.y * height}));
        const polygon = Polygon.newPolygon(mapping.regionMappingId, specification, 1, 1, canvasSpacePoints, true, color, (this.config.regionOpacity ?? 40) / 100)
        const line = new Konva.Line({
          points: polygon.getPointList(),
          strokeWidth: 0,
          stroke: 'black',
          closed: polygon.closed,
          fillEnabled: polygon.closed,
          fill: polygon.color,
          position: {x, y},
          rotation: rotation,
          attrs: {cameraId: cameraId},
        });

        line.on("contextmenu", (ev) => {
          ev.evt.preventDefault()
        })

        line.on("mouseenter", (ev) => {
          this.tooltipEnable({
            x: ev.evt.clientX + 2,
            y: ev.evt.clientY + 2,
            text: `${type} -> ${specification}`,
          })
        })

        line.on("mousemove", (ev) => {
          this.tooltipEnable({
            x: ev.evt.clientX + 2,
            y: ev.evt.clientY + 2,
            text: `${type} -> ${specification}`,
          })
        })

        line.on("mouseleave", () => {
          this.tooltipDisable()
        })


        this.group.add(line)

      }
    })
    this.group.cache({pixelRatio: 10})
  }

  doesConfigChangeNeedRerender(config) {
    if (this.config.regionType !== config.regionType) return true
    if (this.config.regionOpacity !== config.regionOpacity) return true
    return false
  }

  doesConfigChangeNeedReload() {
    return false
  }


}

export default RegionPlugin
