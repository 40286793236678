<script setup>

import DocumentQueryList from "@/views/DocumentQueryList.vue";
import {useArtifactStore} from "@/store/artifacts";
import {computed, onMounted, ref, watch} from "vue";
import ArtifactListRenderer from "@/components/ArtifactListRenderer.vue";
import OutdatedAlert from "@/components/OutdatedAlert.vue";
import {DateTime} from "luxon";

const heatmapList = ref(null)
const lteList = ref(null)
const lteaList = ref(null)
const tagSelectors = ref({
  "morning": true,
  "morninground": true,
  "noon": true,
  "afternoon": true,
  "evening": true,
  "night": true,
  "noonround": true,
})

const loadedTagSelectors = ref({
  "morning": true,
  "morninground": true,
  "noon": true,
  "afternoon": true,
  "evening": true,
  "night": true,
  "noonround": true,
})
const heatmapFilters = ref([])
const lteFilters = ref([])
const lteAnnotationFilters = ref([])

function updateArtifactQueriesAndReload() {
  loadedTagSelectors.value = Object.assign({}, tagSelectors.value)
}

watch(loadedTagSelectors, () => {
  heatmapFilters.value = [
    {key: 'type', operator: '==', value: 'heatmap'},
    {key: 'timestamp', operator: '>=', value: Date.now() - 1000 * 60 * 60 * 24},
    {key: 'status', operator: '==', value: 'done'},
  ]
  for (const tag of Object.keys(loadedTagSelectors.value)) {
    if (loadedTagSelectors.value[tag]) {
      heatmapFilters.value.push({key: "tags", operator: "contains", value: tag})
    }
  }

  lteFilters.value = [
    {key: 'type', operator: '==', value: 'longTimeExposure'},
    {key: 'timestamp', operator: '>=', value: Date.now() - 1000 * 60 * 60 * 24},
    {key: 'status', operator: '==', value: 'done'},
  ]
  for (const tag of Object.keys(loadedTagSelectors.value)) {
    if (loadedTagSelectors.value[tag]) {
      lteFilters.value.push({key: "tags", operator: "contains", value: tag})
    }
  }

  lteAnnotationFilters.value = [
    {key: 'type', operator: '==', value: 'longTimeExposureAnnotation'},
    {key: 'timestamp', operator: '>=', value: Date.now() - 1000 * 60 * 60 * 24},
    {key: 'status', operator: '==', value: 'done'},
  ]

  for (const tag of Object.keys(loadedTagSelectors.value)) {
    if (loadedTagSelectors.value[tag]) {
      lteAnnotationFilters.value.push({key: "tags", operator: "contains", value: tag})
    }
  }
}, {deep: true, immediate: true})

watch(heatmapFilters, async () => {
  if (heatmapList.value) {
    await heatmapList.value.parseQueryParams()
    await heatmapList.value.reload()
  }
}, {deep: true, flush: 'post'})

watch(lteFilters, async () => {
  if (lteList.value) {
    await lteList.value.parseQueryParams()
    await lteList.value.reload()
  }
}, {deep: true, flush: 'post'})

watch(lteAnnotationFilters, async () => {
  if (lteaList.value) {
    await lteaList.value.parseQueryParams()
    await lteaList.value.reload()
  }
}, {deep: true, flush: 'post'})

const outDated = computed(() => {
  return Object.keys(tagSelectors.value).some(tag => tagSelectors.value[tag] !== loadedTagSelectors.value[tag])
})

onMounted(() => {
  let now = DateTime.now()
  tagSelectors.value.afternoon = false
  tagSelectors.value.evening = false
  tagSelectors.value.night = false
  tagSelectors.value.noonround = now.hour >= 12 && now.hour < 17
  tagSelectors.value.morninground = now.hour >= 5 && now.hour < 12
  tagSelectors.value.noon = false
  tagSelectors.value.morning = false
  loadedTagSelectors.value = Object.assign({}, tagSelectors.value)
  updateArtifactQueriesAndReload()
})
</script>

<template>
  <outdated-alert v-if="outDated" @click="updateArtifactQueriesAndReload"></outdated-alert>
  <v-card class="ma-2">
    <v-card-title>{{ $t("dashboards.quickArtifacts.title") }}</v-card-title>
    <v-card-text>
      <v-alert type="info" density="compact"
               class="rounded-pill"
      >
        {{ $t('dashboards.quickArtifacts.tag_explanation') }}
      </v-alert>
      <v-row>
        <v-col v-for="(_, tag) in tagSelectors" v-bind:key="tag" cols="auto">
          <v-switch v-model="tagSelectors[tag]"
                    :label="$t('dashboards.quickArtifacts.tags.' + tag)"
                    class="ma-2">
          </v-switch>
        </v-col>
      </v-row>

      <v-expansion-panels :multiple="true">
        <v-expansion-panel
          :title="$t('artifact.type.heatmap')"
        >
          <template #text>
            <document-query-list
              ref="heatmapList"
              auto-load
              query-type="artifact"
              :query-function="useArtifactStore().queryArtifacts"
              :preset-order="{sortBy: 'preferredFilename', direction: 'asc'}"
              :preset-filters="heatmapFilters"
              :hide-items-per-page="true"
              :preset-items-per-page="100"
              :enable-query-params="false"
              :hide-sortings="true"
              :hide-filters="true"
            >
              <template #list="listProps">
                <ArtifactListRenderer :artifacts="listProps.documents"/>
              </template>
            </document-query-list>
          </template>
        </v-expansion-panel>
        <v-expansion-panel
          :title="$t('artifact.type.longTimeExposure')"
        >
          <template #text>
            <document-query-list
              ref="lteList"
              auto-load
              query-type="artifact"
              :query-function="useArtifactStore().queryArtifacts"
              :preset-order="{sortBy: 'preferredFilename', direction: 'asc'}"
              :preset-filters="lteFilters"
              :hide-items-per-page="true"
              :preset-items-per-page="100"
              :enable-query-params="false"
              :hide-sortings="true"
              :hide-filters="true"
            >
              <template #list="listProps">
                <ArtifactListRenderer :artifacts="listProps.documents"/>
              </template>
            </document-query-list>
          </template>
        </v-expansion-panel>
        <v-expansion-panel
          :title="$t('artifact.type.longTimeExposureAnnotation')"
        >
          <template #text>
            <document-query-list
              ref="lteaList"
              auto-load
              query-type="artifact"
              :query-function="useArtifactStore().queryArtifacts"
              :preset-order="{sortBy: 'preferredFilename', direction: 'asc'}"
              :preset-filters="lteAnnotationFilters"
              :hide-items-per-page="true"
              :preset-items-per-page="100"
              :enable-query-params="false"
              :hide-sortings="true"
              :hide-filters="true"
            >
              <template #list="listProps">
                <ArtifactListRenderer :artifacts="listProps.documents"/>
              </template>
            </document-query-list>
          </template>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
