<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n({})
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import {computed, onBeforeUnmount, ref, watch} from "vue";

const props = defineProps({
  modelValue: {type: String, default: ""},
  readOnly: {type: Boolean, default: false},
  label: {type: String, default: null, required: false},
  rules: {type: Array, default: () => []},
})
const emit = defineEmits(["update:modelValue"])

const editor = useEditor({
  editable: props.readOnly !== true,
  content: props.modelValue,
  extensions: [
    StarterKit.configure({
      history: true,
    }),
    Color,
    TextStyle,
    Highlight,
  ],
  onUpdate: () => {
    emit("update:modelValue", editor.value.getHTML())
  }
})
const colors = [
  {value: "#fa5555"},
  {value: "#fcc460"},
  {value: "#fafa59"},
  {value: "#80f680"},
  {value: "#6b6bf8"},
  {value: "#803a80"},

  {value: "#ff0000"},
  {value: "#ffa500"},
  {value: "#ffff00"},
  {value: "#00ff00"},
  {value: "#0000ff"},
  {value: "#800080"},

  {value: "#3d0101"},
  {value: "#694401"},
  {value: "#757501"},
  {value: "#027c02"},
  {value: "#01018f"},
  {value: "#360136"},
]

const input = ref(null)
defineExpose({
  validate: input.value?.validate,
  reset: input.value?.reset,
  errorMessages: input.value?.errorMessages,
  resetValidation: input.value?.resetValidation,
  isValid: input.value?.isValid,
})

const currentHeadingText = computed(() => {
  if(currentHeadingValue.value === 0) return t("wysiwyg.headings.normal")
  return t(`wysiwyg.headings.h${currentHeadingValue.value}`)
})

const currentHeadingValue = computed(() => {
  if(editor.value.isActive("heading", {level: 1})) return 1
  if(editor.value.isActive("heading", {level: 2})) return 2
  if(editor.value.isActive("heading", {level: 3})) return 3
  if(editor.value.isActive("heading", {level: 4})) return 4
  if(editor.value.isActive("heading", {level: 5})) return 5
  if(editor.value.isActive("heading", {level: 6})) return 6
  return 0
})

const minHeight = computed(() => {
  if(props.readOnly === true) return 0
  return "100px"
})

function setColor(color = undefined) {
  if (!color) {
    editor.value.chain().focus().unsetColor().run()
  }  else {
    editor.value.chain().focus().setColor(color).run()
  }
}

watch(() => props.modelValue, (value) => {
  const hasChanged = editor.value.getHTML() !== value
  if (hasChanged) {
    editor.value.commands.setContent(value, false)
  }
})

onBeforeUnmount(() => {
  editor.value.destroy()
})

</script>

<template>
  <v-input
    ref="input"
    :validation-value="props.modelValue"
    :rules="rules"
  >
    <template #default>
      <v-container class="ma-0 pa-0">
        <v-label :text="props.label" />
        <v-container
          v-if="editor && editor.isEditable"
          :fluid="true"
          class="ma-0 pa-0"
        >
          <v-btn
            icon="mdi-undo"
            variant="text"
            :disabled="!editor.can().undo()"
            class="rounded-0"
            @click="editor.chain().focus().undo().run()"
          />

          <v-btn
            icon="mdi-redo"
            variant="text"
            :disabled="!editor.can().redo()"
            class="rounded-0"
            @click="editor.chain().focus().redo().run()"
          />

          <v-btn
            append-icon="mdi-chevron-down"
            variant="text"
          >
            {{ currentHeadingText }}
            <v-menu activator="parent">
              <v-btn
                :active="!editor.isActive('heading')"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: currentHeadingValue}).run()"
              >
                {{ $t("wysiwyg.headings.normal") }}
              </v-btn>

              <v-btn
                :active="editor.isActive('heading', {level: 1})"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: 1}).run()"
              >
                <h1>{{ $t("wysiwyg.headings.h1") }}</h1>
              </v-btn>

              <v-btn
                :active="editor.isActive('heading', {level: 2})"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: 2}).run()"
              >
                <h2>{{ $t("wysiwyg.headings.h2") }}</h2>
              </v-btn>

              <v-btn
                :active="editor.isActive('heading', {level: 3})"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: 3}).run()"
              >
                <h3>{{ $t("wysiwyg.headings.h3") }}</h3>
              </v-btn>

              <v-btn
                :active="editor.isActive('heading', {level: 4})"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: 4}).run()"
              >
                <h4>{{ $t("wysiwyg.headings.h4") }}</h4>
              </v-btn>

              <v-btn
                :active="editor.isActive('heading', {level: 5})"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: 5}).run()"
              >
                <h5>{{ $t("wysiwyg.headings.h5") }}</h5>
              </v-btn>

              <v-btn
                :active="editor.isActive('heading', {level: 6})"
                variant="flat"
                class="rounded-0"
                @click="editor.chain().focus().toggleHeading({level: 6}).run()"
              >
                <h6>{{ $t("wysiwyg.headings.h6") }}</h6>
              </v-btn>
            </v-menu>
          </v-btn>
          <v-btn
            icon="mdi-format-bold"
            variant="text"
            :active="editor.isActive('bold')"
            class="rounded-0"
            @click="editor.chain().focus().toggleBold().run()"
          />
          <v-btn
            icon="mdi-format-italic"
            variant="text"
            :active="editor.isActive('italic')"
            class="rounded-0"
            @click="editor.chain().focus().toggleItalic().run()"
          />
          <v-btn
            icon="mdi-format-strikethrough"
            variant="text"
            :active="editor.isActive('strike')"
            class="rounded-0"
            @click="editor.chain().focus().toggleStrike().run()"
          />
          <v-btn
            icon="mdi-code-json"
            variant="text"
            :active="editor.isActive('codeBlock')"
            class="rounded-0"
            @click="editor.chain().focus().toggleCodeBlock().run()"
          />
          <v-btn
            variant="text"
            class="rounded-0"
          >
            <v-icon
              icon="mdi-format-color-text"
              :color="editor.getAttributes('textStyle').color"
            />
            <v-menu
              activator="parent"
              max-width="300"
            >
              <v-sheet
                elevation="10"
                class="border"
              >
                <v-row
                  :no-gutters="true"
                  class="justify-center ma-0"
                >
                  <v-col
                    v-for="color in colors"
                    :key="color.value"
                    cols="2"
                  >
                    <v-btn
                      :icon="true"
                      :color="color.value"
                      size="x-small"
                      class="rounded ma-2"
                      @click="setColor(color.value)"
                    >
                      <v-icon
                        v-if="editor.getAttributes('textStyle').color === color.value"
                        class="rounded-circle bg-black"
                        icon="mdi-check"
                      />
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      :block="true"
                      variant="text"
                      @click="setColor()"
                    >
                      {{ $t("wysiwyg.colors.reset") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-menu>
          </v-btn>

          <v-btn
            icon="mdi-marker"
            variant="text"
            :active="editor.isActive('highlight')"
            class="rounded-0"
            @click="editor.chain().focus().toggleHighlight().run()"
          />

          <v-btn
            icon="mdi-format-quote-close"
            variant="text"
            :active="editor.isActive('blockQuote')"
            class="rounded-0"
            @click="editor.chain().focus().toggleBlockquote().run()"
          />


          <v-btn
            icon="mdi-format-list-bulleted"
            variant="text"
            :active="editor.isActive('bulletList')"
            class="rounded-0"
            @click="editor.chain().focus().toggleBulletList().run()"
          />

          <v-btn
            icon="mdi-format-list-numbered"
            variant="text"
            :active="editor.isActive('orderedList')"
            class="rounded-0"
            @click="editor.chain().focus().toggleOrderedList().run()"
          />

          <v-btn
            icon="mdi-format-indent-increase"
            variant="text"
            :disabled="!editor.can().sinkListItem('listItem')"
            class="rounded-0"
            @click="editor.chain().focus().sinkListItem('listItem').run()"
          />

          <v-btn
            icon="mdi-format-indent-decrease"
            variant="text"
            :disabled="!editor.can().liftListItem('listItem')"
            class="rounded-0"
            @click="editor.chain().focus().liftListItem('listItem').run()"
          />
        </v-container>
        <editor-content
          style="border-radius: 32px"
          :editor="editor"
          :class="readOnly ? undefined : 'border'"
        />
      </v-container>
    </template>
  </v-input>
</template>

<style>
.ProseMirror {
  min-height: v-bind(minHeight);
  padding: 10px;
}
</style>
