<script setup>
import {computed, ref, watch} from "vue";
import {useTheme} from "vuetify";
import ChartTimeSetupPicker from "@/components/charts/ChartTimeSetupPicker.vue";
import {useI18n} from "vue-i18n";
import i18n from "@/plugins/i18n.js";
import {useLogsStore} from "@/store/logs.js";

const t = useI18n().t
const props = defineProps({
  height: {type: Number, default: 400},
  options: {
    type: Object, default: () => {
    }
  },
  dataLoader: {
    type: Function, default: async function () {
    }
  },
  disableControlRow: {type: Boolean, default: false},
  hideTimeResolution: {type: Boolean, default: false},
  timeSetup: {type: Object, default: null},
  chainTimeSetup: {type: Boolean, default: false}
})

const baseOptions = {
  markers: {
    size: 0,
    hover: {
      size: 5
    }
  },
  stroke: {
    width: 2,
    curve: "smooth",
    lineCap: "butt",
  },
  yaxis: {
    labels: {
      minWidth: 15,
      formatter: (value) => {
        if (typeof value === "string") return value
        return value.toLocaleString(i18n.global.locale.value)
      }
    }
  },
  theme: {
    mode: useTheme().name.value,
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeUTC: false,
    },
  },
  tooltip: {
    shared: false,
    x: {
      format: "dd.MM.yyyy HH:mm:ss",
    },
  }
}

const chart = ref(null)
const showError = ref(false)
const emit = defineEmits(["updatedTimeSetup"])

const loaded = ref(false)
const initialLoaded = ref(false)
let series = []

const chartOptions = computed(() => {
  return {...baseOptions, ...props.options}
})

watch(() => props.timeSetup, (value, oldValue) => {

  if (!initialLoaded.value || value.start !== oldValue.start || value.end !== oldValue.end || value.timeResolution !== oldValue.timeResolution)
    loadData()
}, {deep: true, immediate: true,})

async function loadData() {
  showError.value = false;
  loaded.value = false;
  try {
    const data = await props.dataLoader(props.timeSetup)
    series = data.series
    initialLoaded.value = true;
  } catch (e) {
    showError.value = true;
    useLogsStore().addLogEntry({message: "Could not load Data for chart", error: e, tag: "chart", level: "ERROR"})
  }
  loaded.value = true;
}

</script>

<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-layout-card>
      <v-row>
        <v-col v-if="!disableControlRow"
               :cols="12">
          <v-row
            class="pa-2 justify-end"
            :no-gutters="true"
          >
            <v-col cols="auto" v-if="!chainTimeSetup">
              <ChartTimeSetupPicker
                :hide-time-resolution="hideTimeResolution"
                :model-value="timeSetup"
                @update:model-value="emit('updatedTimeSetup', $event); timeSetup = $event"
              ></ChartTimeSetupPicker>
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="ma-2 rounded-pill"
                prepend-icon="mdi-reload"
                @click="loadData"
              >
                {{ $t("charts.reload") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card-title v-if="!loaded">{{ $t("charts.loading_title") }}</v-card-title>
      <v-card-subtitle v-if="!loaded && chartOptions?.title?.text">{{ chartOptions.title.text }}</v-card-subtitle>
      <v-card-text v-if="loaded">
        <v-alert v-if="showError" type="error" prominent variant="elevated">{{ $t("charts.could_not_load") }}</v-alert>
        <apexchart
          v-else
          ref="chart"
          :height="height"
          :series="series"
          :options="chartOptions"
        />
      </v-card-text>

      <v-card-text v-if="!loaded" class="justify-center">
        <v-row>
          <v-col :cols="12">
            {{ $t("charts.loading_text") }}
          </v-col>
          <v-col
            :cols="12"
            class="justify-center"
          >
            <v-layout class="justify-center">
              <v-progress-circular
                :indeterminate="true"
                class="justify-center"
              />
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-layout-card>
  </v-container>
</template>
