<script setup>
import {computed, onMounted, ref} from "vue";
import {useCamerasStore} from "@/store/cameras";
import BaseChart from "@/components/charts/BaseChart.vue";

const props = defineProps({
  height: {type: Number, default: 400},
  options: {type: Object, default: () => {}},
  cameraIds: {type: Array, default: () => []},
  dataLoader: {
    type: Function, default: async function () {
    }
  },
  disableControlRow: {type: Boolean, default: false},
  timeSetup: {type: Object, default: null},
  hideTimeResolution: {type: Boolean, default: false},
  chainTimeSetup: {type: Boolean, default: false}
})
defineEmits(["updatedTimeSetup"])

const localCameraIds = computed(() => {
  if (props.cameraIds.length > 0) return props.cameraIds
  if(!cameras.value) return []
  return Object.values(cameras.value).map(({cameraId}) => cameraId)
})

async function localDataLoader(timeSetup) {
  return await props.dataLoader(localCameraIds.value, timeSetup)
}

const cameras = computed(() => useCamerasStore().getNonDecommissionedCameras())
const camerasLoaded = computed(() => cameras.value)

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded()
})
</script>

<template>
  <BaseChart
    :height="height"
    v-if="camerasLoaded && localCameraIds.length > 0"
    :data-loader="localDataLoader"
    :options="options"
    :disable-control-row="props.disableControlRow"
    :time-setup="props.timeSetup"
    :hide-time-resolution="props.hideTimeResolution"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
