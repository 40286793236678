class Polygon {
  constructor(
    mappingId,
    regionSpecification,
    width,
    height,
    color = 'black',
    opacity = 1,
  ) {
    this.mappingId = mappingId;
    this.regionSpecification = regionSpecification;
    this.color = color;
    this.imageWidth = width;
    this.imageHeight = height;
    this.points = [];
    this.canBeFinished = false;
    this.canRemoveLastPoint = false;
    this.closed = false;
    this.opacity = opacity;
  }

  /**
   * Adds Point to polygon
   * @param {Number} x X Coordinate
   * @param {Number} y Y Coordinate
   */
  addPoint(x, y) {
    this.points.push({ x, y });
    this.updatePoints();
  }

  /**
   * Removes the last point of the Polygon
   */
  removeLastPoint() {
    this.points.pop();
    this.updatePoints();
  }

  /**
   * Updates 'canBeFinished' and 'canRemoveLastPoint'
   */
  updatePoints() {
    this.canBeFinished = this.points && this.points.length > 3;
    this.canRemoveLastPoint = this.points && this.points.length > 0;
  }

  /**
   * Returns List of X and Y values (alternating)
   * @returns {*[]}
   */
  getPointList() {
    const result = [];
    this.points.forEach((point) => {
      result.push(point.x);
      result.push(point.y);
    });
    return result;
  }

  close() {
    if (!this.canBeFinished) throw new Error('Cannot close Polygon!');
    this.closed = true;
  }

  getPoints() {
    return this.points;
  }

  getRelativePoints() {
    return this.points.map((point) => ({ x: point.x / this.imageWidth, y: point.y / this.imageHeight }));
  }

  static newPolygon(
    mappingId,
    regionSpecification,
    width,
    height,
    points,
    closed,
    color = 'black',
    opacity = 1,
  ) {
    const polygon = new Polygon(
      mappingId,
      regionSpecification,
      width,
      height,
      color,
      opacity,
    );
    polygon.points = points;
    polygon.closed = closed;
    polygon.updatePoints();
    return polygon;
  }

  static newPolygonFromRelativePoints(
    mappingId,
    regionSpecification,
    width,
    height,
    points,
    closed,
    color,
    opacity,
  ) {
    const polygon = new Polygon(
      mappingId,
      regionSpecification,
      width,
      height,
      color,
      opacity,
    );
    polygon.points = points.map((point) => ({ x: point.x * width, y: point.y * height }));
    polygon.closed = closed;
    polygon.updatePoints();
    return polygon;
  }
}

export default Polygon
