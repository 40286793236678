import Syncable from "@/syncing/Syncable.js";
import {canViewDeadAnimalsHelper} from "@/composables/permissionHelper.js";
import {useIncidentStore} from "@/store/incident.js";
import {useInstanceStore} from "@/store/backendInstances.js";

class DeadAnimalCountsSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 30, DeadAnimalCountsSyncable.getSyncableHandle(), true, true);
  }

  satisfiesPreconditions() {
    if(useInstanceStore().instances[this.globalUniqueIdentifier].instanceType !== "barn") return false
    if(!super.satisfiesPreconditions()) return false;
    return canViewDeadAnimalsHelper(this.globalUniqueIdentifier)
  }

  hasInitialData() {
    return !!useIncidentStore().getDeadAnimalCounts(this.globalUniqueIdentifier)
  }

  async workload() {

    const payload = {
      query: [
        {key: 'state', operator: '==', value: 'deadAnimalExists'},
        {key: 'type', operator: '==', value: 'deadAnimal'},
        {key: "messagePayload.falsePositive", operator: "exists", value: false},
      ],
      projection: "count"
    }

    const counts = {}
    const now = Date.now()

    counts.now = await useIncidentStore().queryIncidents(payload, this.globalUniqueIdentifier)

    counts.last24h = await useIncidentStore().loadIncidentsOpenBetweenSpecificTimestamps(["deadAnimal"], now - 1000 * 60 * 60 * 24, now, "count", undefined, [{
      key: 'messagePayload.falsePositive',
      operator: 'exists',
      value: false
    }], this.globalUniqueIdentifier)

    counts.dayBefore = await useIncidentStore().loadIncidentsOpenBetweenSpecificTimestamps(["deadAnimal"], now - 1000 * 60 * 60 * 24 * 2, now - 1000 * 60 * 60 * 24, "count", undefined, [{
      key: 'messagePayload.falsePositive',
      operator: 'exists',
      value: false
    }], this.globalUniqueIdentifier)

    counts.openedLast24h = await useIncidentStore().loadIncidentsCreatedBetweenSpecificTimestamps(["deadAnimal"], now - 1000 * 60 * 60 * 24, now, "count", undefined, [{
      key: 'messagePayload.falsePositive',
      operator: 'exists',
      value: false
    }], this.globalUniqueIdentifier)

    counts.openedDayBefore = await useIncidentStore().loadIncidentsCreatedBetweenSpecificTimestamps(["deadAnimal"], now - 1000 * 60 * 60 * 24 * 2, now - 1000 * 60 * 60 * 24, "count", undefined, [{
      key: 'messagePayload.falsePositive',
      operator: 'exists',
      value: false
    }], this.globalUniqueIdentifier)
    useIncidentStore().deadAnimalCounts[this.globalUniqueIdentifier] = counts
  }

  static getSyncableHandle() {
    return "dead-animal-counts"
  }
}

export default DeadAnimalCountsSyncable
