import {defineStore} from 'pinia'
import {useInstanceStore} from "@/store/backendInstances";
import backendHandler from "@/BackendAPI";
import CamerasSyncable from "@/syncing/syncables/CamerasSyncable.js";
import SyncableQueue from "@/syncing/SyncableQueue.js";

export const useCamerasStore = defineStore('cameras', {
  state: () => ({
    cameras: {},
    currentStillFrames: {},
    blockedThumbnailLoadings: {},
    fallbackFrames: {},
    camerasLoadingHandles: {},
  }),
  persist: false,
  share: {
    enable: true,
    omit: ["fallbackFrames", "blockedThumbnailLoadings", "currentStillFrames"],
    initialize: true,
  },
  getters: {
    getCameras(state) {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => state.cameras[globalUniqueIdentifier]
    },
    getNonDecommissionedCameras() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => {
        const cameras = this.getCameras(globalUniqueIdentifier)
        if (!cameras) return []
        return cameras.filter(camera => !camera.tags.includes("decommissioned"))
      }
    },
    getCameraById() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId, cameraId) => {
        const cameras = this.getCameras(globalUniqueIdentifier)
        if (!cameras) return null
        return cameras.find(camera => camera.cameraId === cameraId)
      }
    },
  },
  actions: {
    async loadCurrentStillFrame(cameraId, {
      resizeY = 1080,
      draw = undefined,
      text = undefined,
      forceFallback = undefined,
      priority = 5,
      signal = undefined
    } = {}) {
      if (this.blockedThumbnailLoadings[cameraId] && this.blockedThumbnailLoadings[cameraId][resizeY]) return
      if (!this.blockedThumbnailLoadings[cameraId]) this.blockedThumbnailLoadings[cameraId] = {}
      this.blockedThumbnailLoadings[cameraId][resizeY] = true
      const params = {};
      if (resizeY !== undefined) params.resizeY = resizeY
      if (draw !== undefined) params.draw = draw
      if (text !== undefined) params.text = text
      if (forceFallback !== undefined) params.forceFallback = forceFallback
      const frameResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {
        path: "/api/cameras/" + cameraId + "/still",
        params,
        responseType: "blob",
        priority,
        signal
      })
      if (frameResponse.ok) {
        const frameTimestamp = Date.parse(frameResponse.response.headers.get("X-Still-Frame-Source"))
        if (!this.currentStillFrames[cameraId]) this.currentStillFrames[cameraId] = {};
        this.currentStillFrames[cameraId][resizeY] = {
          frame: URL.createObjectURL(frameResponse.data),
          timestamp: frameTimestamp
        }
      }
      this.blockedThumbnailLoadings[cameraId][resizeY] = false
    },

    async loadFrameForTimestamp(cameraId, timestamp, resizeY = 1080, exact = false, maximumTimeDifference = 1000 * 60 * 5, signal = undefined, higherPriority = false) {
      const frameResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {
        path: "/api/recordings/frame/" + cameraId + "/" + timestamp,
        responseType: "blob",
        params: {
          resizeY: resizeY,
          onlyExactFrame: exact,
          maximumDifference: maximumTimeDifference,
        },
        priority: higherPriority ? 4 : undefined,
        signal
      })
      if (frameResponse.ok) {
        return URL.createObjectURL(frameResponse.data)
      }
      if (frameResponse.aborted) return null
      return frameResponse.error
    },

    async startLiveStream(cameraId) {
      const liveStreamResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {path: "/api/streaming/" + cameraId})
      if (!liveStreamResponse.ok) return false;
      return liveStreamResponse.data.path;
    },

    async hasStreamAlreadyStarted(streamPath) {
      const response = await backendHandler.get(useInstanceStore().selectedInstanceId, {path: streamPath + "camera.m3u8"})
      return response.ok
    },

    async setPrivacyForMultipleCameras(cameraIds, privacy) {
      const response = await backendHandler.put(useInstanceStore().selectedInstanceId, {
        path: "/api/privacy/multiple/" + privacy,
        data: {cameras: cameraIds}
      })
      return response.ok;
    },

    async loadCameras(globalUniqueIdentifier=useInstanceStore().selectedInstanceId) {
      const cameraResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/cameras/query",
        body: {},
        priority: 3,
      })
      if(!cameraResponse.ok) return null
      return cameraResponse.data
    },

    async keepCamerasLoaded(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      if (!SyncableQueue.doesSyncableExist(globalUniqueIdentifier, CamerasSyncable.getSyncableHandle())) {
        const syncable = new CamerasSyncable(globalUniqueIdentifier)
        SyncableQueue.addSyncable(syncable)
      }
      await SyncableQueue.ensureSyncable(globalUniqueIdentifier, CamerasSyncable.getSyncableHandle())
    }
  }
})

