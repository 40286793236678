import Syncable from "@/syncing/Syncable.js";
import {canViewRegionsHelper} from "@/composables/permissionHelper.js";
import backendHandler from "@/BackendAPI.js";
import {useRegionStore} from "@/store/regions.js";
import {useInstanceStore} from "@/store/backendInstances.js";

class RegionsSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 30, RegionsSyncable.getSyncableHandle(), true, true);
  }

  satisfiesPreconditions() {
    if(useInstanceStore().instances[this.globalUniqueIdentifier].instanceType !== "barn") return false
    if (!super.satisfiesPreconditions()) return false;
    return canViewRegionsHelper(this.globalUniqueIdentifier)
  }

  hasInitialData() {
    return !!useRegionStore().getRegionTypes(this.globalUniqueIdentifier)
  }

  async workload() {
    const regionTypeResponse = await backendHandler.get(this.globalUniqueIdentifier, {path: "/api/regions/types"});
    const regionSpecificationsResponse = await backendHandler.get(this.globalUniqueIdentifier, {path: "/api/regions/specifications"});
    const regionMappingsResponse = await backendHandler.get(this.globalUniqueIdentifier, {path: "/api/regions/mappings"});
    const regions = {};
    if (regionTypeResponse.ok) regions.regionTypes = regionTypeResponse.data.result;
    if (regionSpecificationsResponse.ok) regions.regionSpecifications = regionSpecificationsResponse.data.result;
    if (regionMappingsResponse.ok) regions.regionMappings = regionMappingsResponse.data.result;
    useRegionStore().regions[this.globalUniqueIdentifier] = regions;
  }
  static getSyncableHandle() {
    return "regions"
  }
}

export default RegionsSyncable
