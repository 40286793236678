<script setup>
import {splitIncidents, splittingModes} from "@/composables/incidents/incidentPlottingHelpers";
import {computed, onMounted, ref, watch} from "vue";
import {DateTime} from "luxon";

const props = defineProps({
  incidents: {type: Array, default: () => []},
  timeResolution: {type: Number, default: 6},
  start: {type: Number, default: 0},
  end: {type: Number, default: 0},
  dimensionKey: {type: String, default: "type"},
  timeShift: {type: Number, default: 0},
})

const series = ref([])
const segments = ref([])
const headers = computed(() => {
  const h = []
  const hours = props.timeResolution
  let hour = 0 + props.timeShift
  while (hour < 24 + props.timeShift) {
    h.push({value: hour.toString(), text: `${hour % 24}`, colspan: series.value.length});
    hour += hours;
  }
  return h;
})

const dimensionHeaders = computed(() => {
  if (series.value.length === 1) return null
  const h = [
    {text: "", value: ""}
  ]
  for (const header of headers.value) {
    if (header.value === "date") continue;
    for (const dimension of series.value) {
      h.push({text: dimension.name, value: dimension.name})
    }
  }
  return h

})

const seriesAndHeaderCombinations = computed(() => {
  const combinations = []
  for (const {name} of series.value) {
    for (const header of headers.value) {
      combinations.push({series: name, header: header.text})
    }
  }
  return combinations
})

const days = computed(() => {
  const d = Object.keys(rows.value)
  return d.sort((a, b) => {
    return DateTime.fromFormat(b, "yyyy-MM-dd").toMillis() - DateTime.fromFormat(a, "yyyy-MM-dd").toMillis()
  })
})


const rows = computed(() => {
  const rows = {}

  let i = 0
  for (const segment of segments.value) {
    const day = segment.attributedDay
    const hour = DateTime.fromMillis(segment.start).hour
    const headerString = `${hour}`
    if (!Object.keys(rows).includes(day)) rows[day] = {segments: {}}
    rows[day].segments[headerString] = {}
    for (const seriesElement of series.value) {
      const seriesName = seriesElement.name
      const seriesData = seriesElement.data
      rows[day].segments[headerString][seriesName] = {
        value: seriesData[i],
        notYetStarted: segment.notYetStarted
      }
    }
    i = i + 1
  }
  return rows
})


watch(() => props.timeResolution, () => {
  reRender()
})

watch(() => props.timeShift, () => {
  reRender()
})

function reRender() {
  const splitResult = splitIncidents(props.incidents, props.timeResolution, props.start, props.end, splittingModes.open, props.dimensionKey, props.timeShift)
  series.value = splitResult.series.map(({name, data}) => ({name, data: data}))
  segments.value = splitResult.segmentBounds
}

onMounted(() => {
  reRender()
})

</script>

<template>
  <v-table density="compact">
    <thead>
    <tr>
      <th
        key="date"
        class="border text-center"
        :colspan="1"
      >
        {{ $t('charts.date') }}
      </th>
      <th
        v-for="header in headers"
        :key="header.value"
        class="border text-center"
        :colspan="header.colspan"
      >
        {{ header.text + ":00" }}
      </th>
    </tr>
    <tr v-if="dimensionHeaders">
      <th
        v-for="header in dimensionHeaders"
        :key="header.value"
        class="border text-center"
      >
        {{ header.text }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
      v-for="day in days"
      :key="day"
    >
      <td class="border text-center">
        {{ day }}
      </td>
      <td
        v-for="h in seriesAndHeaderCombinations"
        :key="h"
        class="border text-center"
      >
        <!-- Segment does not even exist-->
        <span v-if="!rows[day].segments[h.header]"/>

        <!-- Segment not yet started-->
        <span v-else-if="rows[day].segments[h.header][h.series].notYetStarted"/>
        <!-- Non DST Shift, Segment completely done-->

        <span
          v-else
        >
            {{ rows[day].segments[h.header][h.series].value }}
          </span>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>

<style scoped>

</style>
