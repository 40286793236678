import {version} from '../../package.json';
import {branch} from '../../branch.json';
function useBranchString() {
  return branch
}
function useVersionString() {
  return version
}

export {useBranchString, useVersionString}
