<script setup>

import CreateIncidentComment from "@/components/CreateIncidentComment.vue";
import IncidentComment from "@/components/IncidentComment.vue";
import {computed, onMounted, ref} from "vue";
import {useIncidentStore} from "@/store/incident";

const props = defineProps({
  incident: {type: Object, required: true},
  incidentEventId: {type: String, default: null}
})


const incidentStore = useIncidentStore()

const comments = ref([])
const loading = ref(false)

const commentBadgeNumber = computed(() => {
  if (!comments.value) return 0
  return comments.value.length
})


async function triggerCommentReload() {
  loading.value = true;
  if (props.incidentEventId)
    comments.value = await incidentStore.loadEventCommentsForIncidentEvent(props.incident.incidentId, props.incidentEventId)
  else
    comments.value = await incidentStore.loadCommentsForIncident(props.incident.incidentId)
  loading.value = false;
}

onMounted(async () => {
  await triggerCommentReload()
})


</script>

<template>
  <v-btn
    class="mx-1"
    variant="text"
    icon="mdi-comment"
  >
    <v-dialog
      activator="parent"
      max-width="1000px"
    >
      <v-card
        :loading="loading"
        density="compact"
      >
        <v-card-title>{{ $t("incidentComments.title") }}</v-card-title>
        <v-card-text>
          <IncidentComment
            v-for="comment in comments"
            :key="comment.incidentCommentId"
            :loading="loading"
            :comment="comment"
            @trigger-reload="triggerCommentReload"
          />
          <v-divider/>
          <v-card
            variant="flat"
            class="mt-4"
          >
            <v-card-subtitle>{{ $t("incidentComments.add") }}</v-card-subtitle>
            <v-card-text>
              <CreateIncidentComment
                :incident-id="incident.incidentId"
                :incident-event-id="incidentEventId"
                @comment-created="triggerCommentReload"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-badge
      v-if="commentBadgeNumber"
      :content="commentBadgeNumber"
    >
      <v-icon icon="mdi-comment"/>
    </v-badge>
    <v-icon
      v-else
      icon="mdi-comment"
    />
  </v-btn>
</template>

<style scoped>

</style>
