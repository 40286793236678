<script setup>
import {computed, watch} from "vue";
const props = defineProps({
  artifactId: {type: String, default: ""},
  payload: {type: String, default: null},
  isDownloaded: {type: Boolean, default: false},
  artifactData: {type: Object, default: () => ({})},
  fullScreen: {type: Boolean, default: false},
  isDownloading: {type: Boolean, default: false},
})
const videoPayload = computed(() => {
  if (!props.payload) return null
  if (!Array.isArray(props.payload)) return props.payload
  return props.payload[Math.max(...Object.keys(props.payload).map(key => parseInt(key)))]
})

</script>

<template>
  <video
    v-if="videoPayload"
    :autoplay="true"
    :muted="true"
    :controls="true"
    :loop="true"
    :src="videoPayload"
    style="max-width: 100%"/>
</template>

<style scoped>

</style>
