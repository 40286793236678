import {defineStore} from 'pinia'
import backendHandler from "@/BackendAPI";
import {useInstanceStore} from "@/store/backendInstances";

export const useMeasurementStore = defineStore('measurements', {
  state: () => ({}),
  persist: false,
  share: {
    enable: false,
    initialize: false,
  },
  getters: {},
  actions: {

    async loadUsedLabels() {
      const response = await backendHandler.get(useInstanceStore().selectedInstanceId, {path: "/api/data/labels"})
      if (!response.ok) return [];
      return response.data
    },

    async loadGenericMeasurement(measurement, query) {
      const dataResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/data/" + measurement, data: {
          iReadTheDocumentationAndReallyWantToRequestAllThatData: true,
          query: query,
        }
      })
      if (!dataResponse.ok) return null;
      return dataResponse.data;
    },
    async loadSimpleMeasurementForSingleCamera(measurement, value, cameraId, timeQuery, timeResolution, xAggregation = "mean") {
      return this.loadGenericMeasurement(measurement, {
        columns: {[value]: `${xAggregation}@y`, "time@x": true},
        where: {camera: cameraId},
        time: timeQuery,
        timeResolution: timeResolution + "ms",
      })
    },
    loadSimpleMeasurementForMultipleBarns(measurement, value, vmsInstanceNames, timeQuery, timeResolution, aggregate = true, xAggregation = "mean", where) {
      return this.loadGenericMeasurement(measurement, {
        columns: {[value]: `${xAggregation}@y`, "time@x": true},
        where: {vmsInstanceName: vmsInstanceNames, ...where},
        time: timeQuery,
        timeResolution: timeResolution + "ms",
        groupByTags: aggregate === true ? undefined : "vmsInstanceName"
      })
    },

    async loadSimpleMeasurementForMultipleCameras(measurement, value, cameraIds, timeQuery, timeResolution, aggregate = true, xAggregation = "mean") {
      return this.loadGenericMeasurement(measurement, {
        columns: {[value]: `${xAggregation}@y`, "time@x": true},
        where: {camera: cameraIds},
        time: timeQuery,
        timeResolution: timeResolution + "ms",
        groupByTags: aggregate === true ? undefined : "camera"
      })
    },
    async loadSimpleMeasurementForAllCameras(measurement, value, timeQuery, timeResolution, aggregate = true, xAggregation = "mean") {
      return this.loadGenericMeasurement(measurement, {
        columns: {[value]: `${xAggregation}@y`, "time@x": true},
        time: timeQuery,
        timeResolution: timeResolution + "ms",
        groupByTags: aggregate ? undefined : "camera"
      })
    },
    async loadLabelCountsForMultipleCameras(cameraIds, timeQuery, timeResolution, labels = undefined, aggregateCameras = true, aggregateLabels = true) {

      const dataResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/data/labelCounts", data: {
          iReadTheDocumentationAndReallyWantToRequestAllThatData: true,
          labels,
          time: timeQuery,
          timeResolution: timeResolution + "ms",
          groupByLabel: !aggregateLabels,
          groupByCamera: !aggregateCameras,
          cameras: cameraIds,
        }
      })
      if (!dataResponse.ok) return null;
      return dataResponse.data;
    },

    async loadRegionCounts(timeQuery, timeResolution, labels = undefined, aggregateLabels = true, regionType, regionSpecifications) {
      const query = [
        {
          columns: {regions: "sum@inner"},
          time: timeQuery,
          timeResolution: "1m",
          groupByTags: [regionType],
          where: {[regionType]: regionSpecifications}
        },
        {
          columns: {inner: "mean@y", "time@x": true},
          timeResolution: timeResolution + "ms",
          groupByTags: [regionType],
          where: {}
        }
      ]
      if (!aggregateLabels) {
        query[0].groupByTags.push("label")
        query[1].groupByTags.push("label")
      }
      if(labels) {
        query[0].where.label = labels
      }

      return this.loadGenericMeasurement("regions", query)
    }

  }

})
