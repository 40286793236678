<script setup>
import { useTheme } from 'vuetify'
import {ref} from "vue";
import { loadNightVisionMeasurement} from "@/composables/charts";
import i18n from "../../plugins/i18n"
import CameraBaseChart from "@/components/charts/CameraBaseChart.vue";
const {t} = i18n.global
const props = defineProps({
  cameraIds: {type: Array, default: () => []},
  aggregate: {type: Boolean, default: false},
  timeSetup: {type: Object, default: null},
  chainTimeSetup: {type: Boolean, default: false}

})
defineEmits(["updatedTimeSetup"])


const options = ref({
    theme: {
      mode: useTheme().name.value,
    },
    title: {
      text: t("charts.titles.nightvision_radial"),
      margin: 16,
    },
    chart: {
      type: "polarArea",
      animations: {
        enabled: true,
      },
      zoom: {
        enabled: true,
        type: "xy",
        autoScaleYaxis: true,
      },
    },
    yaxis: {
      max: 100,
      labels: {
        formatter: function(val) {
          return Math.round(val) + '%'
        }
      }
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%"
      }
    },
  }
)

async function dataLoader(cameraIds, timeSetup) {
  const data = await loadNightVisionMeasurement({
    cameraIds: cameraIds,
    timeResolution : timeSetup.timeResolution,
    timeQuery: {start: timeSetup.start, end: timeSetup.end},
    aggregateCameras: props.aggregate,
    chartType: "polarArea"
  })
  options.value.labels = data.seriesNames
  return data
}

</script>

<template>
  <CameraBaseChart
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :time-setup="timeSetup"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
