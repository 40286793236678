import {defineStore} from 'pinia'

export const useMiscellaneousStore = defineStore('miscellaneous', {
  state: () => ({
    heatmapOverlayChainOpacity: true,
    heatmapOverlayOpacity: 75,
    expandInstanceDashboardNavigationDrawer: false,
    incidentListDisplayMode: "list",
    artifactListDisplayMode: "list",
    updateAvailable: false,
  }),
  persist: false,
  share: {
    enable: false,
    initialize: false,
  },
  getters: {
    isUpdateAvailable: (state) => state.updateAvailable !== false,
    getUpdateHandler: (state) => state.updateAvailable,
  },
  actions: {
    setUpdateAvailable(updateAvailable) {
      this.updateAvailable = updateAvailable
    },
  }

})
