import {defineStore} from 'pinia'
import {useInstanceStore} from "@/store/backendInstances";
import backendHandler from "@/BackendAPI";

export const useServiceProviderStore = defineStore('serviceProviders', {
  state: () => ({}),
  persist: false,
  share: {
    enable: false,
    initialize: false,
  },
  getters: {},
  actions: {
    async loadServiceProviderForGlobalUniqueIdentifier(globalUniqueIdentifier) {
      useInstanceStore().ensureSenatorExists()
      const serviceProviderResponse = await backendHandler.post("66", {
        path: "/api/serviceProviders/queryOne",
        data: {
          query: [
            {key: "accountId", operator: "==", value: useInstanceStore().getAccountIdForInstance("66")},
            {key: "globalUniqueIdentifier", operator: "==", value: globalUniqueIdentifier}]
        }
      })
      if (!serviceProviderResponse.ok) return null;
      return serviceProviderResponse.data
    },

    async loadServiceProviders() {
      useInstanceStore().ensureSenatorExists()
      const serviceProviderListResponse = await backendHandler.get("66", {path: "/api/serviceProviders"})
      if (serviceProviderListResponse.ok) return serviceProviderListResponse.data
    },

    async addServiceProviderAsInstance(serviceProvider) {
      const globalUniqueIdentifier = serviceProvider.globalUniqueIdentifier;
      const name = serviceProvider.serviceProviderName
      const discoveryObjectResponse = await backendHandler.get("66", {path: "/api/serviceProviders/" + serviceProvider.serviceProviderId + "/discoveryObject"})

      if (!discoveryObjectResponse.ok) return
      const discoveryObject = discoveryObjectResponse.data;

      useInstanceStore().addNewBackendInstance(globalUniqueIdentifier, name, discoveryObject, "barn")
    },

    async removeServiceProviderFromInstances(serviceProvider) {
      const globalUniqueIdentifier = serviceProvider.globalUniqueIdentifier;
      useInstanceStore().removeBackendInstance(globalUniqueIdentifier)
    },

    async addNewServiceProvider(globalUniqueIdentifier, name) {
      const response = await backendHandler.post("66", {
        path: "/api/serviceProviders",
        data: {globalUniqueIdentifier, name}
      })
      if (response.ok) return true
      if (response.status === 404) return "no-instance-found"
      if (response.status === 503) return "cannot-provide"
      return "error"
    },

    async deleteServiceProvider(serviceProviderId) {
      await backendHandler.delete("66", {path: "/api/serviceProviders/" + serviceProviderId})
    },

    async checkIfRemoteAccountExists(globalUniqueIdentifier) {
      const serviceProvider = await this.loadServiceProviderForGlobalUniqueIdentifier(globalUniqueIdentifier)
      if (!serviceProvider) return {accountExists: false, serviceProviderId: null}
      if (!serviceProvider.remoteAccountId) return {
        accountExists: false,
        serviceProviderId: serviceProvider.serviceProviderId
      }
      return {accountExists: true, serviceProviderId: serviceProvider.serviceProviderId}
    },
  }
})
