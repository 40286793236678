import Konva from "konva";
import StillFrameProvider from "@/components/canvas/CanvasElementSourceProviders/StillFrameProvider";
import HeatmapProvider from "@/components/canvas/CanvasElementSourceProviders/HeatmapProvider";
import LongTimeExposureProvider from "@/components/canvas/CanvasElementSourceProviders/LongTimeExposureProvider";
import LongTimeExposureAnnotationProvider
  from "@/components/canvas/CanvasElementSourceProviders/LongTimeExposureAnnotationProvider";
import {reactive} from "vue";
import ArchivedStillFrameProvider from "@/components/canvas/CanvasElementSourceProviders/ArchivedStillFrameProvider.js";
import {useLogsStore} from "@/store/logs.js";
import {useInstanceStore} from "@/store/backendInstances.js";

const backgroundImageUrl = new URL('@/assets/camera-background_grayscale.png', import.meta.url).href
const videoOffImageUrl = new URL('@/assets/video-off-outline.svg', import.meta.url).href

class CanvasElementProvider {


  constructor({
                cameraId, x, y, width, height, rotation
              }, group, scale, providerType, timestamp, enableElementProviderTooltip, disableElementProviderTooltip, router) {
    this.providerType = providerType;
    this.element = null;
    this.cameraId = cameraId;
    this.timestamp = timestamp ? timestamp : Date.now();
    this.x = x;
    this.y = y;
    this.router = router;
    this.width = width;
    this.height = height;
    this.rotation = rotation;
    this.group = group;
    this.scale = scale;
    this.needsToBeReRendered = true;
    this.needsToBeReloaded = true;
    this.loading = false;
    this.loadingPromise = null;
    this.abortController = null;
    this.currentlyLoadingSize = null;
    this.enableElementProviderTooltip = enableElementProviderTooltip;
    this.disableElementProviderTooltip = disableElementProviderTooltip;
    this.hasSourceLoaded = false
    this.reactiveState = reactive({
      loading: false,
      error: false,
    })
    this.buildOverlays();
    this.buildElement();
    this.group.add(this.overlayGroup);


  }

  buildElement() {
    this.sourceHolder = new Image();
    this.element = new Konva.Image({
      image: this.sourceHolder,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rotation: this.rotation
    })
    this.group.add(this.element);
    this.element.setAttrs({cameraId: this.cameraId})
    this.needsToBeReRendered = true;
    this.setSource(backgroundImageUrl, 1080, true)
    this.attachEventHandlers();
  }

  buildOverlays() {
    const transform = new Konva.Transform()
    transform.translate(this.x, this.y)
    transform.rotate(this.rotation * (Math.PI / 180))
    this.overlayGroup = new Konva.Group({});

    const centerPoint = transform.point({x: this.width / 2, y: this.height / 2})
    this.loadingCircle = new Konva.Arc({
      innerRadius: 6,
      outerRadius: 7,
      x: centerPoint.x,
      y: centerPoint.y,
      fill: 'transparent',
      stroke: "blue",
      strokeWidth: 1,
      angle: 60,
      rotation: -45,
      lineCap: 'round',
      listening: false,
    });
    let startPoint = {x: -this.width / 2, y: -this.height / 2}
    let endPoint = {x: this.width / 2, y: this.height / 2}

    this.loadingGradient = new Konva.Rect({
      x: this.x,
      y: this.y,
      rotation: this.rotation,
      width: this.width,
      height: this.height,
      opacity: 1,
      listening: false,
      fillLinearGradientStartPoint: startPoint,
      fillLinearGradientEndPoint: endPoint,
      fillLinearGradientColorStops: [
        0, 'rgba(0,0,0,0.3)',
        0.3, 'rgba(255,255,255,0.5)',
        0.7, 'rgba(255,255,255,0.5)',
        1, 'rgba(0,0,0,0.3)',
      ],
    })
    this.loadingAnimation = new Konva.Animation((frame) => {

      startPoint = {x: startPoint.x + (frame.timeDiff * 0.1), y: startPoint.y + (frame.timeDiff * 0.1)}
      endPoint = {x: endPoint.x + (frame.timeDiff * 0.1), y: endPoint.y + (frame.timeDiff * 0.1)}

      if (startPoint.x > this.width && startPoint.y > this.height && endPoint.x > this.width && endPoint.y > this.height) {
        startPoint.x = -this.width / 2
        startPoint.y = -this.height / 2
        endPoint.x = 0
        endPoint.y = 0
      }
      this.loadingGradient.fillLinearGradientStartPoint(startPoint)
      this.loadingGradient.fillLinearGradientEndPoint(endPoint)
      this.loadingCircle.rotation(this.loadingCircle.rotation() + (frame.timeDiff * 0.2))
      return false
    }, null)
    const errorImageObject = new Image();
    const errorImageCenterPoint = transform.point({x: this.width / 2 - 10, y: this.height / 2 - 10})

    this.errorImage = new Konva.Image({
      rotation: this.rotation,
      x: errorImageCenterPoint.x,
      y: errorImageCenterPoint.y,
      name: "errorImage",
      width: 20,
      height: 20,
      image: errorImageObject,
    });
    errorImageObject.src = videoOffImageUrl;
    this.errorBackground = new Konva.Rect({
      x: this.x,
      y: this.y,
      rotation: this.rotation,
      name: "errorBackground",
      width: this.width,
      height: this.height,
      fill: "black",
      opacity: 0.3,
    })
  }

  enableLoader() {
    if (!this.loadingCircle.getParent()) {
      this.overlayGroup.add(this.loadingCircle);
    }
    if (!this.loadingGradient.getParent()) {
      this.overlayGroup.add(this.loadingGradient);
    }

    this.loadingAnimation.start()
  }

  disableLoader() {
    this.loadingAnimation.stop()
    this.loadingGradient.remove()
    this.loadingCircle.remove()
  }

  enableErrorImage() {
    this.element.remove()

    if (!this.errorBackground.getParent()) {
      this.overlayGroup.add(this.errorBackground);
    }
    if (!this.errorImage.getParent()) {
      this.overlayGroup.add(this.errorImage);
    }
  }

  disableErrorImage() {
    this.errorBackground.remove()
    this.errorImage.remove()
    if (!this.element.getParent()) {
      this.overlayGroup.add(this.element)
    }
  }

  abortLoad() {
    try {
      this.abortController.abort();
    } catch {
    } finally {
      this.loadingPromise = null;
      this.loading = false;
      this.reactiveState.loading = false;
      this.abortController = null;
      this.currentlyLoadingSize = null;
      this.disableLoader();
    }
  }

  render() {
    if (this.reactiveState.error) return;
    if (!this.needsToBeReRendered && !this.needsToBeReloaded) return;
    if (!this.isInView() && this.hasSourceLoaded && !this.needsToBeReloaded) return;
    if (!this.element) this.buildElement();
    if (!this.sourceProvider) this.sourceProvider = this.buildSourceProvider();
    if (this.loading && (this.needsToBeReloaded || this.sourceProvider.getQuantizedHeight() > this.currentlyLoadingSize)) {
      this.abortLoad();
    }
    if (this.loadingPromise) return;
    this.needsToBeReRendered = false;
    this.needsToBeReloaded = false;
    this.loading = true;
    this.reactiveState.loading = true;
    this.enableLoader();
    const renderingResult = this.sourceProvider.fetchSource(this.isInView());
    this.loadingPromise = renderingResult[0];
    this.abortController = renderingResult[1];
    this.currentlyLoadingSize = renderingResult[2];
    this.loadingPromise.then(() => {
      this.hasSourceLoaded = true
      this.loading = false;
      this.reactiveState.loading = false;
      this.loadingPromise = null;
      this.abortController = null;
      this.currentlyLoadingSize = null;
      this.disableLoader();
      this.render();
    }).catch(e => {
      this.reactiveState.error = true;
      this.loadingPromise = null;
      this.loading = false;
      this.reactiveState.loading = false;
      this.enableErrorImage();
      this.disableLoader();
      useLogsStore().addLogEntry({message: "Error loading source for camera " + this.cameraId, error: e, tag: "CanvasElementProvider", level: "ERROR"})
    })
  }

  attachEventHandlers() {
    this.element.on("mouseenter", (ev) => {
      if (this.sourceProvider?.tooltipText) {
        this.enableElementProviderTooltip({
          x: ev.evt.clientX + 2, y: ev.evt.clientY + 2, text: this.sourceProvider.tooltipText(),
        })
      }
    })
    this.element.on("mousemove", (ev) => {
      if (this.sourceProvider?.tooltipText) {
        this.enableElementProviderTooltip({
          x: ev.evt.clientX + 2, y: ev.evt.clientY + 2, text: this.sourceProvider.tooltipText(),
        })
      }
    })
    this.element.on("mouseleave", () => {
      this.disableElementProviderTooltip()
    })
    this.element.on("dbltap", (ev) => {
      this.sourceProvider.handleDoubleClick(ev)
    })
    this.element.on("dblclick", (ev) => {
      this.sourceProvider.handleDoubleClick(ev)
    })
    this.element.on("tap", (ev) => {
      this.sourceProvider.handleClick(ev)
    })
    this.element.on("click", (ev) => {
      this.sourceProvider.handleClick(ev)
    })
    this.element.on("contextmenu", (ev) => {
      this.sourceProvider.handleContextMenu(ev)
    })
  }

  updatePosition() {
    this.render();
  }

  setScale(scale) {
    this.scale = scale;
    if (this.sourceProvider) this.sourceProvider.setScale(scale);
    this.needsToBeReRendered = true;
    this.render()
  }

  setSource(source, height) {
    this.reactiveState.error = false;
    this.disableErrorImage()
    if (this.sourceHolder.src === source) return;
    this.sourceHeight = height
    this.sourceHolder.onload = () => {
      try {
        this.element.draw()
      } catch (e) {
        useLogsStore().addLogEntry({message: "Error drawing element provider", error: e, tag: "CanvasElementProvider", level: "ERROR"})
      }
      const pixelRatio = this.sourceHeight ? this.sourceHeight / (this.height) : 5;
      this.element.cache({pixelRatio})

    }
    if (this.element.isCached()) this.element.clearCache()
    this.sourceHolder.src = source;
  }

  setTimestamp(timestamp) {
    this.reactiveState.error = false;
    this.timestamp = timestamp;
    this.sourceProvider.setTimestamp(timestamp);
    this.needsToBeReloaded = true;
    this.render()
  }

  setProviderType(providerType) {
    this.reactiveState.error = false;
    this.providerType = providerType;
    this.needsToBeReloaded = true;

    if (this.sourceProvider && this.sourceProvider.providerType !== providerType) {
      this.hasSourceLoaded = false
      if (this.abortController) this.abortController.abort();
      this.sourceProvider.destroy();
      this.loadingPromise = null;
      this.loading = false;
      this.reactiveState.loading = false;
      this.currentlyLoadingSize = null;
      this.sourceProvider = null;
    }
    this.setSource(backgroundImageUrl, 1080, true)
    this.sourceProvider = this.buildSourceProvider();
    this.sourceProvider.setScale(this.scale);
    this.sourceProvider.setTimestamp(this.timestamp);
    this.render()
  }

  buildSourceProvider() {
    const providerClass = this.getProviderClass();
    return new providerClass({
      cameraId: this.cameraId,
      scale: this.scale,
      timestamp: this.timestamp,
      height: this.height,
      elementProviderReference: this,
      router: this.router
    })
  }

  getProviderClass() {
    switch (this.providerType) {
      case "stillFrame":
        return StillFrameProvider;
      case "heatmap":
        return HeatmapProvider;
      case "longTimeExposure":
        return LongTimeExposureProvider;
      case "longTimeExposureAnnotation":
        return LongTimeExposureAnnotationProvider;
      case "archivedStillFrame":
        return ArchivedStillFrameProvider;
      //    default: return LogoProvider;
    }
  }

  isInView() {
    if (!this.element) return false
    return this.element.isClientRectOnScreen()
  }

  enableBorders() {
    this.element.strokeEnabled(true)
  }

  disableBorders() {
    this.element.strokeEnabled(false)
  }

  getKonvaElement() {
    return this.element
  }

  destroyElement() {
    this.abortLoad()
    this.element.destroy()
    this.errorImage.destroy()
    this.errorBackground.destroy()
    this.loadingCircle.destroy()
    this.loadingGradient.destroy()
  }
}


export default CanvasElementProvider;
