<script setup>
import {onMounted, ref, watch, computed} from "vue";
import {loadRegionMeasurement} from "@/composables/charts";
import {useMeasurementStore} from "@/store/measurements";
import {useRegionStore} from "@/store/regions";

const regionStore = useRegionStore()
import CameraBaseChart from "@/components/charts/CameraBaseChart.vue";

const props = defineProps(
  {
    cameraIds: {type: Array, default: () => []},
    aggregateLabels: {type: Boolean, default: false},
    title: {type: String, default: ""},
    subtitle: {type: String, default: ""},
    regionType: {type: String, default: ""},
    specifiedLabels: {type: Array, default: null},
    timeSetup: {type: Object, default: null},
    chainTimeSetup: {type: Boolean, default: false},
  }
)
defineEmits(["updatedTimeSetup"])

const options = ref({
    title: {
      text: props.title,
      margin: 16,
    },
    subtitle: {
      text: props.subtitle,
      align: "left",
    },
    chart: {
      type: "line",
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
    },
  }
)

const labels = ref([])

async function dataLoader(cameraIds, timeSetup) {
  const data = await loadRegionMeasurement({
    timeResolution: timeSetup.timeResolution,
    timeQuery: {start: timeSetup.start, end: timeSetup.end},
    aggregateCameras: props.aggregateCameras,
    aggregateLabels: props.aggregateLabels,
    chartType: "line",
    labels: labels.value,
    regionType: props.regionType,
    regionSpecifications: regionSpecifications.value,
    chainTimeSetup: {type: Boolean, default: false}
  })
  options.value.labels = data.seriesNames
  return data
}

const regionTypes = computed(() => regionStore.getRegionTypes())
const regionSpecifications = computed(() => {
  const specifications = regionStore.getRegionSpecifications()
  if (!specifications) return []
  return Object.values(specifications).filter(spec => spec.regionType === props.regionType).map(spec => spec.regionSpecificationId)
})

const cannotRender = computed(() => {

  if (!props.aggregateLabels && (!labels.value || !labels.value.length)) return true
  if (!regionTypes.value) return true
  return !Object.keys(regionTypes.value).includes(props.regionType)
})


onMounted(async () => {
  if (props.specifiedLabels) labels.value = props.specifiedLabels
  else labels.value = await useMeasurementStore().loadUsedLabels();
  regionStore.keepRegionsLoaded()
})


</script>

<template>
  <CameraBaseChart
    v-if="!cannotRender && regionSpecifications && labels"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :time-setup="timeSetup"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
