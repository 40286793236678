<script setup>
import {useTheme} from 'vuetify'
import {ref} from "vue";
import {loadNightVisionMeasurement} from "@/composables/charts";
import i18n from "../../plugins/i18n"

const {t} = i18n.global

import CameraBaseChart from "@/components/charts/CameraBaseChart.vue";

const props = defineProps({
  title: {type: String, default: null},
  subtitle: {type: String, default: ""},
  cameraIds: {type: Array, default: () => []},
  aggregate: {type: Boolean, default: false},
  timeSetup: {type: Object, default: null},
  chainTimeSetup: {type: Boolean, default: false}
})
defineEmits(["updatedTimeSetup"])


const options = ref({
    theme: {
      mode: useTheme().name.value,
    },
    title: {
      text: props.title ? props.title : t("charts.titles.nightvision_bar"),
      margin: 16,
    },
    subtitle: {
      text: props.subtitle,
      align: "left",
    },
    chart: {
      type: "bar",
      animations: {
        enabled: true,
      },
    },
    xaxis: {},
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%"
      }
    },
  }
)

async function dataLoader(cameraIds, timeSetup) {
  return await loadNightVisionMeasurement({
    cameraIds: cameraIds,
    timeResolution: timeSetup.timeResolution,
    timeQuery: {start: timeSetup.start, end: timeSetup.end},
    aggregateCameras: props.aggregate,
    chartType: "percentageBar"
  })
}

</script>

<template>
  <CameraBaseChart
    :height="700"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :time-setup="timeSetup"
    @updated-time-setup="$emit('updatedTimeSetup', $event)"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
