<script setup>

import NightvisionTimelineChart from "@/components/charts/NightvisionTimelineChart.vue";
import NightvisionRadialChart from "@/components/charts/NightvisionRadialChart.vue";
import LabelCountsLineChart from "@/components/charts/LabelCountsLineChart.vue";
import SimpleLineChart from "@/components/charts/SimpleLineChart.vue";
import RegionChart from "@/components/charts/RegionChart.vue";
import {ref} from "vue";
import NightvisionBarChart from "@/components/charts/NightvisionBarChart.vue";
import ChartTimeSetupPicker from "@/components/charts/ChartTimeSetupPicker.vue";

const chainedTimeSetup = ref({});
const chainTimeSetup = ref(true)

function updateChainedTimeSetup(timeSetup) {
  if (chainTimeSetup.value) chainedTimeSetup.value = timeSetup
}

</script>

<template>
  <v-container
    :fluid="true"
    class="ma-0 pa-0"
  >
    <v-layout-card>
      <v-card-title>
        {{ $t("measurements.titles.barn_measurements") }}
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col :cols="12">
            <v-row class="justify-end">
              <v-col cols="auto">
                <v-switch
                  v-model="chainTimeSetup"
                  :label="$t('charts.chain_time_setup')"
                />
              </v-col>
              <v-col cols="auto" v-if="chainTimeSetup">
                <ChartTimeSetupPicker
                  :model-value="chainedTimeSetup"
                  @update:model-value="updateChainedTimeSetup($event)"
                  :hide-time-resolution="true"
                  v-if="chainTimeSetup"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col :cols="12">
            <SimpleLineChart
              :aggregate="true"
              measurement="activity"
              :subtitle="$t('charts.units.activity')"
              field="activity"
              :title="$t('charts.titles.activity')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <SimpleLineChart
              :aggregate="true"
              measurement="speed"
              field="speed"
              :subtitle="$t('charts.units.speed')"
              :title="$t('charts.titles.speed')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <RegionChart
              :aggregate-labels="false"
              :region-type="'stalltechnik'"
              :title="$t('charts.titles.regions')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <NightvisionTimelineChart
              :aggregate="false"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <SimpleLineChart
              :aggregate="false"
              measurement="activity"
              :subtitle="$t('charts.units.activity')"
              field="activity"
              :title="$t('charts.titles.activity')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>


          <v-col :cols="12">
            <SimpleLineChart
              :aggregate="false"
              measurement="speed"
              field="speed"
              :subtitle="$t('charts.units.speed')"
              :title="$t('charts.titles.speed')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>


          <v-col :cols="12">
            <RegionChart
              :aggregate-labels="false"
              :region-type="'stallbereich_vh'"
              :title="$t('charts.titles.regions')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <RegionChart
              :aggregate-labels="false"
              :region-type="'stallbereich_lr'"
              :title="$t('charts.titles.regions')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>


          <v-col :cols="12">
            <LabelCountsLineChart
              :aggregate-cameras="true"
              :aggregate-labels="false"
              :title="$t('charts.titles.labelCounts')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <LabelCountsLineChart
              :aggregate-cameras="false"
              :aggregate-labels="true"
              :title="$t('charts.titles.labelCounts')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>

          <v-col :cols="12">
            <RegionChart
              :aggregate-labels="true"
              :region-type="'pen'"
              :specified-labels="['piglet', 'piglet_lying', 'piglet_standing', 'piglet_sitting']"
              :title="$t('charts.titles.regions')"
              :time-setup="chainedTimeSetup"
              :chain-time-setup="chainTimeSetup"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-layout-card>
  </v-container>
</template>

<style scoped>

</style>
