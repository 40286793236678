<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useCamerasStore} from "@/store/cameras";
import {useMiscellaneousStore} from "@/store/miscellaneous";
const cameraStore = useCamerasStore()
const miscellaneousStore = useMiscellaneousStore()
const props = defineProps({
  artifactId: {type: String, default: ""},
  payload: {type: Object, default: null},
  isDownloaded: {type: Boolean, default: false},
  artifactData: {type: Object, default: () => ({})},
  fullScreen: {type: Boolean, default: false},
  isDownloading: {type: Boolean, default: false},
})

const lastFrameOfHeatmap = ref(null)
const opacity = ref(75)
const chainOpacity = ref(true)
const stillFrame = computed(() => {
  if(!cameraStore.currentStillFrames[props.artifactData.data.cameraId]) return null
  if(!cameraStore.currentStillFrames[props.artifactData.data.cameraId][200]) return null
  return cameraStore.currentStillFrames[props.artifactData.data.cameraId][200].frame
})

const endTimestamp = computed(() => {
  let end = props.artifactData.data.time.end
  if(end === undefined) end = props.artifactData.timestamp
  return end
})

const imagePayload = computed(() => {
  if(!props.payload) return null
  return props.payload[Math.max(...Object.keys(props.payload).map(key => parseInt(key)))]
})
function updateChainOpacity() {
  miscellaneousStore.heatmapOverlayChainOpacity = chainOpacity.value
  updateOpacity()
}
function updateOpacity() {
  if(chainOpacity.value) {
    miscellaneousStore.heatmapOverlayOpacity = opacity.value
  }
}


miscellaneousStore.$subscribe((mutation, state) => {
    chainOpacity.value = state.heatmapOverlayChainOpacity
    opacity.value = state.heatmapOverlayOpacity
})

onMounted(async () => {
  chainOpacity.value = miscellaneousStore.heatmapOverlayChainOpacity;
  opacity.value = miscellaneousStore.heatmapOverlayOpacity;
  await cameraStore.loadCurrentStillFrame(props.artifactData.data.cameraId, {resizeY: 200})
  lastFrameOfHeatmap.value = await cameraStore.loadFrameForTimestamp(props.artifactData.data.cameraId, endTimestamp.value, 200)
})
</script>

<template>
  <v-card variant="flat" elevation="0">

  <v-img
    :src="stillFrame"
    :lazy-src="lastFrameOfHeatmap"
    :max-height="fullScreen ? '80vh' : '40vh'"
    :gradient="isDownloaded ? undefined : 'to top right, rgba(0,0,0,0.6), rgba(0,0,0,.3)'"
  >
    <v-img
      v-if="isDownloaded"
      :src="imagePayload"
      :style="`opacity: ${opacity}%`"
    />
    <v-container
      v-else
      class="justify-center align-center fill-height"
    >
      <v-chip prepend-icon="mdi-alert">
        {{ $t("artifact.not_downloaded_yet") }}
      </v-chip>
    </v-container>
  </v-img>
  </v-card>
  <v-row
    v-if="isDownloaded"
    :no-gutters="true"
    class="align-center"
  >
    <v-col>
      <v-slider
        v-model="opacity"
        :min="0"
        :max="100"
        :label="$t('artifact.heatmap_opacity')"
        class="ma-2"
        @update:model-value="updateOpacity"
      />
    </v-col>
    <v-col cols="auto">
      <v-switch
        v-model="chainOpacity"
        :label="$t('artifact.chain_opacity')"
        class="ma-2"
        @update:model-value="updateChainOpacity"
      />
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
