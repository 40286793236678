<script setup>

import {computed, onMounted, ref, watch} from "vue";
import {splitIncidents, splittingModes} from "@/composables/incidents/incidentPlottingHelpers";
import {DateTime} from "luxon";

const props = defineProps({
  incidents: {type: Array, default: () => []},
  mode: {type: String, default: splittingModes.opened},
  timeResolution: {type: Number, default: 6},
  start: {type: Number, default: 0},
  end: {type: Number, default: 0},
  dimensionKey: {type: String, default: "type"},
  timeShift: {type: Number, default: 0},
})

const series = ref([])
const segments = ref([])
const headers = computed(() =>  {
  const h = []
  const hours = props.timeResolution
  let hour = 0 + props.timeShift
  while (hour < 24 + props.timeShift) {
    h.push({ value: hour.toString(), text: `${hour % 24}:00-${(hour + hours) % 24}:00`, colspan: series.value.length });
    hour += hours;
  }
  return h;
})

const dimensionHeaders = computed(() => {
  if(series.value.length === 1) return null
  const h = [
    {text: "", value: ""}
  ]
  for (const dimension of series.value) {
    h.push({text: dimension.name, value: dimension.name})
  }
  for (const header of headers.value) {
    if(header.value === "date") continue;
    for (const dimension of series.value) {
      h.push({text: dimension.name, value: dimension.name})
    }
  }
  return h

})

const seriesAndHeaderCombinations = computed(() => {
  const combinations = []
  for (const {name} of series.value) {
    for (const header of headers.value) {
      combinations.push({series: name, header: header.text})
    }
  }
  return combinations
})

const days = computed(() => {
  const d = Object.keys(rows.value)
  return d.sort((a, b) => {
    return DateTime.fromFormat(b, "yyyy-MM-dd").toMillis() - DateTime.fromFormat(a, "yyyy-MM-dd").toMillis()
  })
})
const rows = computed(() => {
  const rows = {}

  let i = 0
  for (const segment of segments.value) {
    const day = segment.attributedDay
    const startHour = DateTime.fromMillis(segment.start).hour
    const endHour = DateTime.fromMillis(segment.end).hour
    const headerString = `${startHour}:00-${endHour}:00`
    if(!Object.keys(rows).includes(day)) rows[day] = {segments: {}}
    rows[day].segments[headerString] = {}
    for (const seriesElement of series.value) {
      const seriesName = seriesElement.name
      const seriesData = seriesElement.data
      rows[day].segments[headerString][seriesName] = {
        value: seriesData[i],
        alreadyDone: segment.alreadyDone,
        notYetStarted: segment.notYetStarted,
        includesDSTShift: segment.includesDSTShift,
        actualLength: segment.actualLength
      }
    }
    i = i + 1
  }

  for (const day of Object.keys(rows)) {
    const totals = {}
    for (const {name} of series.value) {
      totals[name] = {
        value: 0,
        alreadyDone: true,
        notYetStarted: true,
        includesDSTShift: false,
        actualLength: 0
      }
      Object.values(rows[day].segments).forEach(segment => {
        if(!segment[name].alreadyDone) totals[name].alreadyDone = false
        if(!segment[name].notYetStarted) totals[name].notYetStarted = false
        if(segment[name].includesDSTShift) totals[name].includesDSTShift = true
        totals[name].actualLength += segment[name].actualLength
        totals[name].value += segment[name].value
      })
    }
    rows[day].totals = totals
  }
  return rows
})

watch(() => props.timeResolution, () => {
  reRender()
})

watch(() => props.timeShift, () => {
  reRender()
})

function reRender() {
  const splitResult = splitIncidents(props.incidents, props.timeResolution, props.start, props.end, props.mode, props.dimensionKey, props.timeShift)
  series.value = splitResult.series.map(({name, data}) => ({name, data: data}))
  segments.value = splitResult.segmentBounds
}

onMounted(() => {
  reRender()
})

</script>

<template>
  <v-table density="compact">
    <thead>
      <tr>
        <th
          key="date"
          class="border text-center"
          :colspan="1"
        >
          {{ $t('charts.date') }}
        </th>
        <th
          key="total"
          class="border text-center"
          :colspan="series.length"
        >
          {{ $t('charts.total') }}
        </th>
        <th
          v-for="header in headers"
          :key="header.value"
          class="border text-center"
          :colspan="header.colspan"
        >
          {{ header.text }}
        </th>
      </tr>
      <tr v-if="dimensionHeaders">
        <th
          v-for="header in dimensionHeaders"
          :key="header.value"
          class="border text-center"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="day in days"
        :key="day"
      >
        <td class="border text-center">
          {{ day }}
        </td>
        <td
          v-for="seriesElement in series"
          :key="seriesElement.name"
          class="border text-center font-weight-bold"
        >
          <!-- Day not yet started-->
          <span v-if="rows[day].totals[seriesElement.name].notYetStarted" />


          <!-- Non DST Shift, Day completely done-->
          <span v-else-if="!rows[day].totals[seriesElement.name].includesDSTShift && rows[day].totals[seriesElement.name].alreadyDone">{{ rows[day].totals[seriesElement.name].value }}</span>

          <!-- Day not yet done, but started-->
          <v-tooltip
            v-else-if="!rows[day].totals[seriesElement.name].alreadyDone"
            :text="$t('charts.day_not_yet_done')"
          >
            <template #activator="{props}">
              <span v-bind="props">{{ rows[day].totals[seriesElement.name].value+"+" }}</span>
            </template>
          </v-tooltip>

          <v-tooltip
            v-else-if="rows[day].totals[seriesElement.name].includesDSTShift"
            :text="$t('charts.dst_shift_day_explanation', {count: rows[day].totals[seriesElement.name].actualLength})"
          >
            <template #activator="{props}">
              <!-- DST Shift, Day completely Done-->
              <span
                v-if="rows[day].totals[seriesElement.name].alreadyDone"
                v-bind="props"
                style="color: dodgerblue"
              >
                {{ rows[day].totals[seriesElement.name].value + " (DST Shift)" }}
              </span>
            </template>
          </v-tooltip>
        </td>

        <td
          v-for="h in seriesAndHeaderCombinations"
          :key="h"
          class="border text-center"
        >
          <!-- Segment does not even exist-->
          <span v-if="!rows[day].segments[h.header]" />
          <!-- Segment Not Yet Started-->

          <span v-else-if="rows[day].segments[h.header][h.series].notYetStarted" />

          <!-- Non DST Shift, Segment completely done-->
          <span
            v-else-if="rows[day].segments[h.header][h.series].alreadyDone
              && !rows[day].segments[h.header][h.series].includesDSTShift"
          >
            {{ rows[day].segments[h.header][h.series].value }}
          </span>

          <!-- Non DST Shift, Segment not yet done, but started-->
          <v-tooltip
            v-else-if="!rows[day].segments[h.header]?.[h.series].notYetStarted
              && !rows[day].segments[h.header]?.[h.series].alreadyDone
              && !rows[day].segments[h.header]?.[h.series].includesDSTShift"
            :text="$t('charts.segment_not_yet_done')"
          >
            <template #activator="{props}">
              <span v-bind="props">{{ rows[day].segments[h.header][h.series].value+"+" }}</span>
            </template>
          </v-tooltip>



          <v-tooltip
            v-else-if="rows[day].segments[h.header][h.series].includesDSTShift"
            :text="$t('charts.dst_shift_explanation', {count: rows[day].segments[h.header][h.series].actualLength})"
          >
            <template #activator="{props}">
              <!-- DST Shift, Segment completely Done-->
              <span
                v-if="rows[day].segments[h.header][h.series].alreadyDone"
                v-bind="props"
                style="color: dodgerblue"
              >
                {{ `${rows[day].segments[h.header][h.series].value} ${$t('charts.dst_shift')}` }}
              </span>

              <!-- DST Shift, Segment not yet done, but started-->
              <span
                v-else-if="!rows[day].segments[h.header][h.series].alreadyDone"
                style="color: dodgerblue"
                v-bind="props"
              >
                {{ `${rows[day].segments[h.header][h.series].value} + ${$t('charts.dst_shift')}` }}
              </span>
            </template>
          </v-tooltip>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<style scoped>

</style>
