<script setup>
import {computed} from "vue";
import TriggerBarnDocumentation from "@/components/incidentTriggers/TriggerBarnDocumentation.vue";
import TriggerBarnIntervention from "@/components/incidentTriggers/TriggerBarnIntervention.vue";
import TriggerBarnState from "@/components/incidentTriggers/TriggerBarnState.vue";
import TriggerDeadAnimal from "@/components/incidentTriggers/TriggerDeadAnimal.vue";

const props = defineProps({
  incident: {type: Object, default: () => ({})},
  showCancelButton: {type: Boolean, default: false}
})
defineEmits(["saved", "cancel"])


const triggerComponent = computed(() => {
  switch (props.incident.type) {
    case "barnDocumentation": return TriggerBarnDocumentation;
    case "barnIntervention": return TriggerBarnIntervention;
    case "deadAnimal": return TriggerDeadAnimal;
    case "barnState": return TriggerBarnState;
  }
  return null
})

</script>

<template>
  <component
    :is="triggerComponent"
    :incident="incident"
    :show-cancel-button="showCancelButton"
    @saved="$emit('saved')"
    @cancel="$emit('cancel')"
  />
</template>

<style scoped>

</style>
