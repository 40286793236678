import {defineStore} from 'pinia'
import backendHandler from "@/BackendAPI";
import {useServiceProviderStore} from "@/store/serviceProviders";
import {useSettingsStore} from "@/store/settings";
import {buildDiscoveryObject} from "@/composables/discovery.js";
import _ from "lodash";
import OwnPermissionsSyncable from "@/syncing/syncables/OwnPermissionsSyncable.js";
import SyncableQueue from "@/syncing/SyncableQueue.js";
import {useLogsStore} from "@/store/logs.js";


export const useInstanceStore = defineStore('backendInstances', {
  state: () => ({
    instances: {},
    availableAuthenticationMethods: {},
    eulas: {},
    eulaRefreshRequired: {},
    alreadyTriedAutoLogin: {},
    attemptingAutoLogin: {},
  }), persist: {
    paths: ["instances"]
  },

  share: {
    enable: true, initialize: true,
  },

  getters: {

    isPerformingRequestOnSelectedInstance: (state) => {
      if (!state.selectedInstanceId) return false;
      return backendHandler.getOrCreateBackendAPIInstance(state.selectedInstanceId).accessibleState.concurrentRequests > 0
    },

    attemptingAutoLoginOnInstance: (state) => (globalUniqueIdentifier) => state.attemptingAutoLogin[globalUniqueIdentifier],

    isOnline: () => (globalUniqueIdentifier) => backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier).accessibleState.isOnline,

    isLoggedIn: () => (globalUniqueIdentifier) => backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier).accessibleState.loggedIn,

    getCurrentAccountId: state => {
      if (!state.selectedInstanceId) return null;
      return backendHandler.getOrCreateBackendAPIInstance(state.selectedInstanceId).accessibleState.accountId
    },

    getSenator: state => state.instances["66"],

    getAdmiral: state => Object.values(state.instances).findLast((instance) => instance.instanceType === "admiral"),

    globalUniqueIdentifiers: state => Object.keys(state.instances),

    globalUniqueIdentifiersForBarns: state => Object.keys(state.instances).filter((globalUniqueIdentifier) => state.instances[globalUniqueIdentifier].instanceType === "barn"),

    getAccountIdForInstance: () => (globalUniqueIdentifier) => backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier).accessibleState.accountId,

    connectionStrategy: () => (globalUniqueIdentifier) => backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier).accessibleState.connectionStrategy,

    selectedInstanceId() {
      return this.router.currentRoute.value.params?.globalUniqueIdentifier
    },

    selectedInstance(state) {
      return state.instances[this.selectedInstanceId]
    },

    getSpecies: (state) => (globalUniqueIdentifier) => state.instances[globalUniqueIdentifier].species,
  },

  actions: {
    async ensureSpeciesIsLoaded(globalUniqueIdentifier) {
      if (this.instances[globalUniqueIdentifier].species) return;
      return this.loadSpecies(globalUniqueIdentifier)
    },

    async loadSpecies(globalUniqueIdentifier) {
      const response = await backendHandler.get(globalUniqueIdentifier, {path: "/api/data/labels"})
      if (!response.ok) return;
      if (response.data.length === 0) return;
      if (response.data.includes("pig_lying")) this.instances[globalUniqueIdentifier].species = "pigs";
      if (response.data.includes("Broiler")) this.instances[globalUniqueIdentifier].species = "chickens";
    },

    addAllInstancesToBackendHandler() {
      for (const globalUniqueIdentifier of Object.keys(this.instances)) {
        backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier)
        useInstanceStore().keepOwnPermissionsLoaded(globalUniqueIdentifier)
      }
    },

    addNewBackendInstance(globalUniqueIdentifier, name, discoveryObject, type) {
      const instance = {
        globalUniqueIdentifier,
        name,
        discoveryObject,
        instanceType: type,
        online: false,
        version: {},
        refreshToken: null,
        autoLoginWithRemoteAccount: true,
      }
      this.instances[globalUniqueIdentifier] = instance
      return instance
    },

    removeBackendInstance(globalUniqueIdentifier) {
      backendHandler.removeInstance(globalUniqueIdentifier)
      delete this.instances[globalUniqueIdentifier]
      delete this.availableAuthenticationMethods[globalUniqueIdentifier]
    },

    updateInstanceName(globalUniqueIdentifier, name) {
      if (!this.instances[globalUniqueIdentifier]) return;
      this.instances[globalUniqueIdentifier].name = name;
    },

    async fetchVersion(globalUniqueIdentifier=useInstanceStore().selectedInstanceId) {
      const response = await backendHandler.get(globalUniqueIdentifier, {path: '/api/v', requiresAuth: false})
      if (!response.ok) return;
      return response.data
    },

    async loadVersion(globalUniqueIdentifier=useInstanceStore().selectedInstanceId) {
     const version = this.fetchVersion(globalUniqueIdentifier)
      if(!version) return
      this.instances[globalUniqueIdentifier].version = version
    },

    async loadAvailableAuthenticationMethods(globalUniqueIdentifier) {
      const usernamePasswordResponse = await backendHandler.get(globalUniqueIdentifier, {
        path: '/api/auth/usernamePassword', requiresAuth: false
      })
      const emailPasswordResponse = await backendHandler.get(globalUniqueIdentifier, {
        path: '/api/auth/emailPassword', requiresAuth: false
      })
      const remoteResponse = await backendHandler.get(globalUniqueIdentifier, {
        path: '/api/auth/remote', requiresAuth: false
      })

      this.availableAuthenticationMethods[globalUniqueIdentifier] = {
        usernamePassword: usernamePasswordResponse.ok,
        emailPassword: emailPasswordResponse.ok,
        remote: remoteResponse.ok,
      }
    },

    async autoLoginIntoInstanceIfPossible(globalUniqueIdentifier) {
      if (useSettingsStore().enableAutoLogin === false) return;
      if (this.instances[globalUniqueIdentifier].autoLoginWithRemoteAccount === false) return;
      if (this.alreadyTriedAutoLogin[globalUniqueIdentifier]) return;
      useLogsStore().addLogEntry({
        message: "Attempting Auto Login",
        tag: "backendInstances",
        globalUniqueIdentifier: globalUniqueIdentifier,
        level: "DEBUG"
      })
      this.alreadyTriedAutoLogin[globalUniqueIdentifier] = true

      this.attemptingAutoLogin[globalUniqueIdentifier] = true
      if (!Object.keys(this.availableAuthenticationMethods).includes(globalUniqueIdentifier)) {
        await this.loadAvailableAuthenticationMethods(globalUniqueIdentifier)
      }
      if (!this.availableAuthenticationMethods[globalUniqueIdentifier].remote) {
        this.attemptingAutoLogin[globalUniqueIdentifier] = false
        useLogsStore().addLogEntry({
          message: "Auto Login not possible, no remote authentication available",
          tag: "backendInstances",
          globalUniqueIdentifier: globalUniqueIdentifier,
          level: "DEBUG"
        })
        return
      }
      const {
        accountExists, serviceProviderId
      } = await useServiceProviderStore().checkIfRemoteAccountExists(globalUniqueIdentifier)
      if (!accountExists) {
        this.attemptingAutoLogin[globalUniqueIdentifier] = false
        useLogsStore().addLogEntry({
          message: "Auto Login not possible, no remote account exists",
          tag: "backendInstances",
          globalUniqueIdentifier: globalUniqueIdentifier,
          level: "DEBUG"
        })
        return
      }
      await this.loginWithRemoteAuthentication(globalUniqueIdentifier, serviceProviderId)

      this.attemptingAutoLogin[globalUniqueIdentifier] = false
    },

    async logOutOfInstance(globalUniqueIdentifier) {
      this.instances[globalUniqueIdentifier].autoLoginWithRemoteAccount = false
      await backendHandler.logOutOfInstance(globalUniqueIdentifier)
    },

    async getRolesOfUser(accountId) {
      const roleResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/authorization/roles/by_user/" + accountId})
      if (!roleResponse.ok) return [];
      return roleResponse.data
    },
    async getPermissionsOfUser(accountId, recursive = false) {
      const permissionResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/authorization/permissions/by_user/" + accountId + "?recursive=" + recursive})
      if (!permissionResponse.ok) return [];
      return permissionResponse.data
    },

    async getAllPermissions() {
      const permissionResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/authorization/permissions/all"})
      if (!permissionResponse.ok) return [];
      return permissionResponse.data
    },

    async addPermissionToAccount(accountId, permissionId) {
      const permissionResponse = await backendHandler.put(this.selectedInstanceId, {
        path: "/api/authorization/add_permission/" + permissionId + "/to_user/" + accountId
      })
      return permissionResponse.ok
    },

    async getUptime(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      const response = await backendHandler.get(globalUniqueIdentifier, {
        path: "/api/uptime"
      })
      if(!response.ok) return null
      return response.data
    },

    async removePermissionFromAccount(accountId, permissionId) {
      const permissionResponse = await backendHandler.delete(this.selectedInstanceId, {
        path: "/api/authorization/delete_permission/" + permissionId + "/from_user/" + accountId
      })
      return permissionResponse.ok
    },

    async addRoleToAccount(accountId, roleId) {
      const roleResponse = await backendHandler.put(this.selectedInstanceId, {
        path: "/api/authorization/add_role/" + roleId + "/to_user/" + accountId
      })
      return roleResponse.ok
    },

    async removeRoleFromAccount(accountId, roleId) {
      const roleResponse = await backendHandler.delete(this.selectedInstanceId, {
        path: "/api/authorization/delete_role/" + roleId + "/from_user/" + accountId
      })
      return roleResponse.ok
    },

    async getAllRoles() {
      const roleResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/authorization/roles/all"})
      if (!roleResponse.ok) return [];
      return roleResponse.data
    },

    async deleteAccount(accountId) {
      const accountResponse = await backendHandler.delete(this.selectedInstanceId, {path: "/api/accounts/" + accountId})
      return accountResponse.ok
    },

    async getOwnPermissions(recursive = false) {
      const permissionResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/authorization/permissions/?recursive=" + recursive})
      if (!permissionResponse.ok) return [];
      return permissionResponse.data
    },

    async fetchOwnPermissions(globalUniqueIdentifier) {
      try {
        const permissionResponse = await backendHandler.get(globalUniqueIdentifier, {path: "/api/permissions"})
        if (permissionResponse.ok) {
          const currentAvailablePermissions = this.instances[globalUniqueIdentifier].availablePermissions
          const newPermissions = permissionResponse.data
          if (currentAvailablePermissions && _.isEqual(currentAvailablePermissions, newPermissions)) return
          this.instances[globalUniqueIdentifier].availablePermissions = newPermissions
        }
      } catch (e) {
        useLogsStore().addLogEntry({
          message: "Error while fetching own permissions",
          error: e,
          tag: "backendInstances",
          level: "ERROR",
          globalUniqueIdentifier: globalUniqueIdentifier
        })
      }
    },

    async loginWithRemoteAuthentication(globalUniqueIdentifier, serviceProviderId) {
      try {
        const signInResponse = await backendHandler.get("66", {path: "/api/idp/" + serviceProviderId + "/login"})
        if (!signInResponse.ok) return false;
        const {refreshToken} = signInResponse.data;
        this.instances[globalUniqueIdentifier].refreshToken = refreshToken;
        backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier).validateRefreshToken()
        await this.ensureOwnPermissionsLoaded(globalUniqueIdentifier);
        //        await this.fetchOwnPermissions(globalUniqueIdentifier);
        useLogsStore().addLogEntry({
          message: "Auto Login Successful! (Service Provider) " + serviceProviderId,
          tag: "backendInstances",
          globalUniqueIdentifier: globalUniqueIdentifier,
          level: "INFO"
        })
        return true;
      } catch (e) {
        useLogsStore().addLogEntry({
          message: "Auto Login failed! (Service Provider) " + serviceProviderId,
          tag: "backendInstances",
          error: e,
          globalUniqueIdentifier: globalUniqueIdentifier,
          level: "ERROR"
        })
        return false
      }
    },

    async registerViaServiceProvider(globalUniqueIdentifier, serviceProviderId, displayName, locale, timezone, acceptEula) {
      try {
        const registerResponse = await backendHandler.post("66", {
          path: "/api/idp/" + serviceProviderId + "/account", data: {displayName, acceptEula, locale, timezone}
        })
        return registerResponse.ok;
      } catch {
        return false
      }
    },

    async registerWithEmailAndPassword(globalUniqueIdentifier, email, password, passwordRepetition, displayName, locale, acceptEula) {
      const registerResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/auth/emailPassword",
        data: {
          email,
          password,
          passwordRepetition,
          displayName,
          locale,
          acceptEula,
          frontendUrl: window.location.origin + "/ql/email-verification"
        },
        requiresAuth: false
      })
      return registerResponse.ok
    },

    async requestNewConfirmationEmail(globalUniqueIdentifier, email) {
      const confirmationResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/auth/emailPassword/verification/new", data: {email}, requiresAuth: false
      })
      return confirmationResponse.ok
    },

    async requestNewPasswordLink({email, discoveryString, globalUniqueIdentifier}) {
      await this.addInstanceFromDiscoveryString(discoveryString, globalUniqueIdentifier)
      if (globalUniqueIdentifier === false) return false
      const response = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/auth/emailPassword/verification/new", data: {
          email: email,
          frontendUrl: window.location.origin + "/ql/account-provisioning"
        }, requiresAuth: false
      })
      return response.ok

    },

    async loginWithEmailAndPassword(globalUniqueIdentifier, email, password) {
      try {
        const signInResponse = await backendHandler.post(globalUniqueIdentifier, {
          path: "/api/auth/emailPassword/login", data: {email, password}, requiresAuth: false
        })
        if (!signInResponse.ok) return {successful: false, emailConfirmationNeeded: signInResponse.status === 412};

        const {refreshToken} = signInResponse.data;
        this.instances[globalUniqueIdentifier].refreshToken = refreshToken;
        await this.ensureOwnPermissionsLoaded(globalUniqueIdentifier);
        this.instances[globalUniqueIdentifier].email = email
        return {successful: true};
      } catch {
        return {successful: false, emailConfirmationNeeded: false}
      }
    },

    async registerWithUsernameAndPassword(globalUniqueIdentifier, username, password, passwordRepetition, displayName, locale, acceptEula) {
      const registerResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/auth/usernamePassword",
        data: {username, password, passwordRepetition, displayName, locale, acceptEula},
        requiresAuth: false
      })
      return registerResponse.ok
    },

    async loginWithUsernameAndPassword(globalUniqueIdentifier, username, password) {
      try {
        const signInResponse = await backendHandler.post(globalUniqueIdentifier, {
          path: "/api/auth/usernamePassword/login", data: {username, password}, requiresAuth: false
        })
        if (!signInResponse.ok) return false;

        const {refreshToken} = signInResponse.data;
        this.instances[globalUniqueIdentifier].refreshToken = refreshToken;
        await this.ensureOwnPermissionsLoaded(globalUniqueIdentifier);
        return true;
      } catch {
        return false
      }
    },

    hasAllPermissions(permissions, overrideGlobalUniqueIdentifier = undefined) {
      for (const permission of permissions) {
        if (!this.hasPermission(permission, overrideGlobalUniqueIdentifier)) return false;
      }
      return true;
    },

    hasPermission(permission, overrideGlobalUniqueIdentifier = undefined) {
      const globalUniqueIdentifier = overrideGlobalUniqueIdentifier !== undefined ? overrideGlobalUniqueIdentifier : this.selectedInstanceId;
      if (!globalUniqueIdentifier || !Object.keys(this.instances).includes(globalUniqueIdentifier)) return false
      if (!this.instances[globalUniqueIdentifier].availablePermissions) return false
      return this.instances[globalUniqueIdentifier].availablePermissions.includes(permission)
    },

    hasAnyPermission(permissions, overrideGlobalUniqueIdentifier = undefined) {
      for (const permission of permissions) {
        if (this.hasPermission(permission, overrideGlobalUniqueIdentifier)) return true;
      }
      return false
    },

    ensureSenatorExists() {
      if (this.instances["66"]) return;
      this.addNewBackendInstance("66", "VetVise SSO", {
        remote: {
          hostname: "senator.vms.dev.vetvise.com",
          port: 443,
          protocol: "https"
        }
      }, "senator")
    },

    async getAccounts() {
      const accountResponse = await backendHandler.post(this.selectedInstanceId, {path: "/api/accounts/query"})
      if (accountResponse.ok) return accountResponse.data
      return []
    },

    async getCurrentEula(globalUniqueIdentifier) {
      return this.getEulaFile(globalUniqueIdentifier, 'current')
    },

    async getEulaFile(globalUniqueIdentifier, version = 'current') {
      const eulaResponse = await backendHandler.get(globalUniqueIdentifier, {
        path: "/api/eulas/" + version, requiresAuth: false, responseType: "blob"
      })
      if (!this.eulas[globalUniqueIdentifier]) this.eulas[globalUniqueIdentifier] = {}

      if (!eulaResponse.ok) {
        return false
      }
      const eulaURL = URL.createObjectURL(eulaResponse.data)
      const loadedVersion = eulaResponse.response.headers['content-disposition'].match(/filename="([^"]+)"/)[1];

      this.eulas[globalUniqueIdentifier][loadedVersion] = {url: eulaURL, version: loadedVersion}
      if (version === 'current') this.eulas[globalUniqueIdentifier]["current"] = {url: eulaURL, version: loadedVersion}
      return eulaURL
    },

    async loadOwnEulaHistory(globalUniqueIdentifier = undefined) {
      if (globalUniqueIdentifier === undefined) globalUniqueIdentifier = this.selectedInstanceId
      const historyResponse = await backendHandler.get(globalUniqueIdentifier, {path: "/api/eulaAcceptance"})
      if (!historyResponse.ok) return []
      return historyResponse.response.data
    },

    async loadEulaHistoryForAccount(accountId) {
      const historyResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/eulaAcceptance/" + accountId})
      if (!historyResponse.ok) return []
      return historyResponse.data
    },

    async revokeEula(globalUniqueIdentifier) {
      const revokeResponse = await backendHandler.delete(globalUniqueIdentifier, {path: "/api/eulaAcceptance"})
      if (!revokeResponse.ok) return false;
    },

    async loadBackendLicenses() {
      const licenseResponse = await backendHandler.get(this.selectedInstanceId, {path: "/api/licenses"})
      if (!licenseResponse.ok) return []
      return licenseResponse.data
    },

    async loadImage(globalUniqueIdentifier) {
      const urlResponse = await backendHandler.get(globalUniqueIdentifier, {path: "/api/identity/imageUrl"})
      if (!urlResponse.ok) return false;
      this.instances[globalUniqueIdentifier].imageUrl = urlResponse.data
    },

    async getDisplayName(globalUniqueIdentifier) {
      if (globalUniqueIdentifier === undefined) globalUniqueIdentifier = this.selectedInstanceId
      const accountResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/accounts/queryOne", data: {query: this.getAccountIdForInstance(globalUniqueIdentifier)}
      })
      return accountResponse.data.displayName
    },

    async loadDiscoveredNetworkDevices(globalUniqueIdentifier = undefined, signal) {
      const response = await backendHandler.get(globalUniqueIdentifier, {path: "/api/network/discover", signal})
      if (!response.ok) return []
      return response.data
    },

    async updateOUI(globalUniqueIdentifier = undefined, signal) {
      const response = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/network/discover/update-oui", signal
      })
      if (!response.ok) return false
      return true
    },

    async addServiceProvidersToInstanceList() {
      const settingsStore = useSettingsStore()
      const serviceProviderStore = useServiceProviderStore()
      if (!settingsStore.autoAddServiceProviders) return
      const serviceProviders = await serviceProviderStore.loadServiceProviders();
      if (!serviceProviders) return
      const promises = []

      for (const serviceProvider of serviceProviders) {
        if (settingsStore.serviceProvidersExcludedFromAutoAdd.includes(serviceProvider.globalUniqueIdentifier)) continue
        if (this.instances[serviceProvider.globalUniqueIdentifier]) continue
        const promise = backendHandler.get("66", {path: "api/serviceProviders/" + serviceProvider.serviceProviderId + "/discoveryObject"})
          .then((discoveryObjectResponse) => {
            if (!discoveryObjectResponse.ok) return
            const discoveryObject = discoveryObjectResponse.data;
            this.addNewBackendInstance(serviceProvider.globalUniqueIdentifier, serviceProvider.serviceProviderName, discoveryObject, "barn")
          }).catch((e) => {
            useLogsStore().addLogEntry({
              message: "Error while adding service provider " + serviceProvider.serviceProviderName + " to instance list",
              error: e,
              tag: "backendInstances",
              globalUniqueIdentifier: serviceProvider.globalUniqueIdentifier,
              level: "ERROR"
            })
          })
        promises.push(promise)
      }
      await Promise.all(promises)
    },

    async addInstanceFromDiscoveryString(discoveryString, guidOverride = undefined) {
      const discoveryObject = buildDiscoveryObject(discoveryString)
      if (!discoveryObject) return false
      if (!guidOverride && !discoveryObject.gateway?.id) return false
      const globalUniqueIdentifier = guidOverride ? guidOverride : discoveryObject.gateway.id
      if (!this.instances[globalUniqueIdentifier]) {
        this.addNewBackendInstance(globalUniqueIdentifier, "VetVise SSO", discoveryObject, "senator")
      }
      await backendHandler.getOrCreateBackendAPIInstance(globalUniqueIdentifier).triggerScheduledQuickConnectionCheck()
      return globalUniqueIdentifier
    },

    async setPasswordForProvisionedAccount({
                                             id,
                                             token,
                                             password,
                                             passwordRepetition,
                                             globalUniqueIdentifier,
                                             discoveryString,
                                             acceptEula
                                           }) {
      await this.addInstanceFromDiscoveryString(discoveryString, globalUniqueIdentifier)
      const response = await backendHandler.post(globalUniqueIdentifier, {
        requiresAuth: false,
        path: "/api/auth/emailPassword/setPassword",
        data: {
          authenticationId: id,
          password: password,
          passwordRepetition: passwordRepetition,
          verificationToken: token,
          acceptEula: acceptEula,
        }
      })
      return response.ok;
    },

    async ensureOwnPermissionsLoaded(globalUniqueIdentifier) {
      const syncable = this.keepOwnPermissionsLoaded(globalUniqueIdentifier)
      await SyncableQueue.ensureSyncable(globalUniqueIdentifier, syncable.handle)
    },

    keepOwnPermissionsLoaded(globalUniqueIdentifier) {
      if (SyncableQueue.doesSyncableExist(globalUniqueIdentifier, OwnPermissionsSyncable.getSyncableHandle())) {
        return SyncableQueue.jobs[globalUniqueIdentifier][OwnPermissionsSyncable.getSyncableHandle()]
      }
      const syncable = new OwnPermissionsSyncable(globalUniqueIdentifier)
      SyncableQueue.addSyncable(syncable)
      return syncable
    }
  }
})
