<script setup>
import i18n from "../../plugins/i18n"
const {t} = i18n.global
import {computed} from "vue";
import {humanReadableTimestamp} from "@/composables/datetime";
import WYSIWYG from "@/components/WYSIWYG.vue";

const props = defineProps({incident: {type: Object, default: () => ({})}})

const wasCreatedAutomatically = computed(() => {
  return (props.incident.triggeredBy.entityType !== "account")
})

const hasDescription = computed(() => {
  return Object.keys(props.incident.messagePayload).includes("description")
})

const showIssue = computed(() => {
  return Object.keys(props.incident.messagePayload).includes("issue")
})

const humanReadableStatus = computed(() => {
  return t(`incidents.barnIntervention.status.${props.incident.state}`)
})

const humanReadableSeverity = computed(() => {
  return t(`incidents.barnIntervention.severities.${props.incident.messagePayload.severity}`)
})

const humanReadableType = computed(() => {
  return t(`incidents.barnIntervention.types.${props.incident.messagePayload.type}`)
})

const humanReadableIssue = computed(() => {
  return t(`incidents.barnIntervention.issues.${props.incident.messagePayload.issue}`)
})
</script>

<template>
  <v-layout-card>
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-row
      class="ma-0 pa-0 align-center"
      :no-gutters="true"
    >
      <v-col>
        <v-card-title>
          {{ incident.messagePayload.title }}
        </v-card-title>
      </v-col>
      <v-col>
        <v-card-subtitle>{{ humanReadableTimestamp(incident.firstEventTimestamp) }}</v-card-subtitle>
      </v-col>
    </v-row>
    <v-card-text>
      <v-list>
        <v-list-item
          :title="$t('incidents.barnIntervention.titles.status')"
          :subtitle="humanReadableStatus"
          prepend-icon="mdi-label"
        />
        <v-list-item
          :title="$t('incidents.barnIntervention.titles.timestamp')"
          :subtitle="humanReadableTimestamp(incident.firstEventTimestamp)"
          prepend-icon="mdi-clock"
        />
        <v-list-item
          :title="$t('incidents.barnIntervention.titles.lastUpdatedTimestamp')"
          :subtitle="humanReadableTimestamp(incident.lastEventTimestamp)"
          prepend-icon="mdi-clock-end"
        />
        <v-list-item
          :title="$t('incidents.barnIntervention.titles.severity')"
          :subtitle="humanReadableSeverity"
          prepend-icon="mdi-alert-circle"
        />
        <v-list-item
          :title="$t('incidents.barnIntervention.titles.type')"
          :subtitle="humanReadableType"
          prepend-icon="mdi-shape"
        />
        <v-list-item
          v-if="showIssue"
          :title="$t('incidents.barnIntervention.titles.issue')"
          :subtitle="humanReadableIssue"
          prepend-icon="mdi-shape"
        />
        <v-list-item
          v-if="wasCreatedAutomatically"
          :title="$t('incidents.barnIntervention.titles.created_automatically')"
          :subtitle="$t('incidents.barnIntervention.properties.created_automatically')"
          prepend-icon="mdi-robot"
        />
        <v-list-item
          v-if="!wasCreatedAutomatically"
          :title="$t('incidents.barnIntervention.titles.creator')"
          :subtitle="incident.triggeredBy.displayName"
          prepend-icon="mdi-account"
        />
        <v-list-item
          v-if="hasDescription"
          :title="$t('incidents.barnIntervention.titles.description')"
          prepend-icon="mdi-text-box"
        >
          <WYSIWYG
            :model-value="incident.messagePayload.description"
            :read-only="true"
          />
        </v-list-item>
        <v-list-item
          v-if="incident.state !== 'closed' && incident.isPublicOpen === false"
          prepend-icon="mdi-eye-off"
          :title="$t('incidents.titles.public_closed')"
          :subtitle="$t('incidents.properties.public_closed')"
        />
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{enableNewEventButton: true, newEventButtonText: $t('incidents.barnIntervention.actions.new_event')}"
      />
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
