import CanvasPluginProvider from "@/components/canvas/CanvasPluginProvider";
import {useIncidentStore} from "@/store/incident";
import Konva from "konva";
import IncidentDisplay from "@/components/incidents/IncidentDisplay.vue";
import {getDeadAnimalIncidentColor} from "@/composables/incidents/incidentTimeHelper";
import {reactive, ref} from "vue";
import {useInstanceStore} from "@/store/backendInstances.js";

class DeadAnimalIncidentPlugin extends CanvasPluginProvider {

  constructor(settings) {
    super(settings, "deadAnimals", true, true)
    this.reactiveProperties.incidents = []
  }

  fetchInformation() {
    this.reactiveProperties.incidents = []
    this.draw()
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      useIncidentStore().loadIncidentsOpenAtSpecificTimestamp("deadAnimal", this.timestamp.toMillis(), [{
        key: 'messagePayload.falsePositive', operator: 'exists', value: false
      }], undefined, controller.signal).then((incidents) => {
        this.reactiveProperties.incidents = incidents
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
    return [promise, controller]
  }

  draw() {
    this.group.destroyChildren()
    for (let incident of this.reactiveProperties.incidents) {
      const cameraId = incident.identifier.cameraId
      const elementPosition = this.elementPositions[cameraId]
      const x = incident.messagePayload.lastDetection.x * elementPosition.width
      const y = incident.messagePayload.lastDetection.y * elementPosition.height
      const w = incident.messagePayload.lastDetection.w * elementPosition.width
      const h = incident.messagePayload.lastDetection.h * elementPosition.height
      const transform = new Konva.Transform()
      transform.translate(elementPosition.x, this.elementPositions[cameraId].y)
      transform.rotate(elementPosition.rotation * (Math.PI / 180))

      const marker = DeadAnimalIncidentPlugin.buildMarker({
        elementTransform: transform,
        x: x,
        y: y,
        rotation: elementPosition.rotation,
        width: w,
        height: h,
        strokeWidth: .5,
        stroke: getDeadAnimalIncidentColor(incident),
        attrs: {cameraId: cameraId},
      }, this.config)
      incident.position = {
        transform, x, y, w, h, rotation: elementPosition.rotation, marker,
      }
      marker.on("click", ev => {
        ev.evt.preventDefault();
        this.contextMenuHandler({
          x: ev.evt.clientX,
          y: ev.evt.clientY,
          component: IncidentDisplay,
          props: {
            incident: incident,
            canTriggerNewEventOverride: (props) => {
              if (!useInstanceStore().hasAnyPermission([
                "deadAnimals.manage",
                "incidents.manage",
                "incidents.manage.advanced",
                `incidents.${props.incident.type}.manage`])) return false
              return props.incident.state !== "closed"
            }
          },
          on: {reload: this.forceUpdate.bind(this)}
        })
      })
      marker.on("contextmenu", ev => {
        ev.evt.preventDefault();
        this.contextMenuHandler({
          x: ev.evt.clientX, y: ev.evt.clientY, component: IncidentDisplay, props: {incident: incident}
        })
      })
      marker.on("dblclick", ev => {
        ev.evt.preventDefault();
      })

      this.group.add(marker)
    }
  }

  doesConfigChangeNeedRerender(config) {
    if (config.markerType !== this.config.markerType) return true;
    return false;
  }

  doesConfigChangeNeedReload() {
    return false
  }

  static buildMarker(setup, config) {
    if (config.markerType === "circle") {
      return new Konva.Circle({
        x: setup.elementTransform.point({x: setup.x, y: setup.y}).x,
        y: setup.elementTransform.point({x: setup.x, y: setup.y}).y,
        width: setup.width,
        height: setup.height,
        strokeWidth: setup.strokeWidth,
        stroke: setup.stroke,
        rotation: setup.rotation,
      })
    }
    if (config.markerType === "rectangle") {
      return new Konva.Rect({
        x: setup.elementTransform.point({x: setup.x, y: setup.y}).x,
        y: setup.elementTransform.point({x: setup.x, y: setup.y}).y,
        width: setup.width,
        height: setup.height,
        strokeWidth: setup.strokeWidth,
        stroke: setup.stroke,
        rotation: setup.rotation,
        offsetX: setup.width / 2,
        offsetY: setup.height / 2
      })
    }
    if (config.markerType === "dot") {
      return new Konva.Circle({
        x: setup.elementTransform.point({x: setup.x, y: setup.y}).x,
        y: setup.elementTransform.point({x: setup.x, y: setup.y}).y,
        radius: 3,
        fillEnabled: true,
        fill: setup.stroke
      })
    }

    if (config.markerType === "cross") {
      return new Konva.Line({
        position: setup.elementTransform.getTranslation(),
        points: [setup.x, setup.y, setup.x + setup.width, setup.y + setup.height, setup.x + setup.width / 2, setup.y + setup.height / 2, setup.x + setup.width, setup.y, setup.x, setup.y + setup.height],
        strokeWidth: setup.strokeWidth,
        stroke: setup.stroke,
        rotation: setup.rotation,
        offsetX: setup.width / 2,
        offsetY: setup.height / 2,
      })
    }
  }
}

export default DeadAnimalIncidentPlugin
