import {createI18n} from 'vue-i18n'
import de from "../lang/de.json"
import en from "../lang/en.json"
import debug from "../lang/debug.json"

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: en,
    de: de,
    debug: debug
  },
})
export default i18n
