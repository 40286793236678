<script setup>
import {computed, markRaw, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useCamerasStore} from "@/store/cameras";
import {useCanvasStore} from "@/store/canvas";
import Konva from "konva";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import DeadAnimalIncidentPlugin from "@/components/canvas/CanvasPluginProviders/DeadAnimalIncidentPlugin";
import RegionPlugin from "@/components/canvas/CanvasPluginProviders/RegionPlugin";
import TriggerDeadAnimal from "@/components/incidentTriggers/TriggerDeadAnimal.vue";
import {useRegionStore} from "@/store/regions";
import {useDownloadFile} from "@/composables/downloadFile";
import {useRouter} from "vue-router";
import {
  deadAnimalIncidentTriggerPayload,
  setupLabelingHelper,
  showDeadAnimalIncidentTrigger,
  tearDownLabelingHelper
} from "@/composables/canvasVisualLabelingHelper";

import {DateTime} from "luxon";

import ElementProviderPicker from "@/components/canvas/Overlays/ElementProviderPicker.vue";
import BasicNavigation from "@/components/canvas/Overlays/BasicNavigation.vue";
import CanvasTimeJumper from "@/components/canvas/Overlays/CanvasTimeJumper.vue";
import CanvasTimePicker from "@/components/canvas/Overlays/CanvasTimePicker.vue";
import CanvasElementProvider from "@/components/canvas/CanvasElementProvider";
import DeadAnimalOverlay from "@/components/canvas/PluginOverlays/DeadAnimalOverlay.vue";
import {useDisplay} from "vuetify";
import {writeToClipboard} from "@/composables/clipboard";
import {useInstanceStore} from "@/store/backendInstances";
import _ from "lodash";
import {useLogsStore} from "@/store/logs.js";

const {mobile} = useDisplay()

const i18n = useI18n();
const {t} = i18n

const toast = useToast();

const canvasStore = useCanvasStore()
const scaleBy = 1.05;
const providers = ref({})
const plugins = ref({})
const showShareDialog = ref(false)
const showContextMenu = ref(false)
const enableTimeJumper = ref(false)
const contextMenu = markRaw({})

const emit = defineEmits(["disableDeadAnimalLabeling"])
const props = defineProps({
  disableZoomButtons: {type: Boolean, default: false},
  canvasId: {type: String, default: null},
  overrideCanvas: {type: Object, default: null},
  disableScrolling: {type: Boolean, default: false},
  disableElementPicker: {type: Boolean, default: false},
  disableTimeJumper: {type: Boolean, default: false},
  disableNavigation: {type: Boolean, default: false},
  shrinkAllOverlaysByDefault: {type: Boolean, default: false},
  height: {type: String, default: "100%"},
})

const timestamp = defineModel("timestamp", {type: DateTime, default: DateTime.now().startOf("second")})
const elementProvider = defineModel("elementProvider", {type: String, default: "stillFrame"})
const pluginConfig = defineModel("pluginConfig", {type: Object, default: {}})
const baseRotation = defineModel("baseRotation", {type: Number, default: null})

const router = useRouter()
const stage = ref(null)
const initialSetup = ref(false)
const canvas = ref(null)
const elementProviderTooltipEnabled = ref(false)
const visualLabelingMode = ref(false)
const elementProviderTooltipConfig = ref({})
const pluginTooltipEnabled = ref(false)
const pluginTooltipConfig = ref({})
const elementPositions = ref({})
let basePosition = {x: 0, y: 0}
const deadAnimalOverlayExpanded = ref(false)
const stayAtCurrentPositionForImageDownload = ref(true)
const imageDownloading = ref(false)
const urlSharing = ref({
  includeTimestamp: true,
  includeImageSource: true,
  includeRegions: true,
  includeDeadAnimalIncidents: true,
})
let group
const layers = {
  imageLayer: null,
  pluginLayers: null,
  visualLabelingLayer: null,
}
const stageConfig = ref({
  height: 100,
  width: 100,
  scale: {
    x: 1,
    y: 1,
  },
  position: {
    x: 0,
    y: 0,
  },
  draggable: true
})


const expandTimePicker = ref(true)

const stageHeight = computed(() => stageConfig.value?.height || 0)
const stageWidth = computed(() => stageConfig.value?.width || 0)

const canvasHeight = computed(() => {
  if (!stage.value || !canvas.value) return 0
  return stageHeight.value
})

const canvasWidth = computed(() => {
  if (!stage.value || !canvas.value) return 0
  return canvasHeight.value / canvas.value.heightRatio
})

const sharingUrl = computed(() => {

  const params = {cameraCanvasId: props.canvasId}
  const query = {}
  if (urlSharing.value.includeTimestamp) query.timestamp = timestamp.value.toMillis()
  if (urlSharing.value.includeImageSource) query.elementProvider = elementProvider.value
  if (pluginConfig.value?.regions?.enabled && urlSharing.value.includeRegions) {
    query.showRegions = true
    query.regionType = pluginConfig.value.regions.regionType
    query.regionOpacity = parseInt(pluginConfig.value.regions.regionOpacity ?? 40)
  }
  if (pluginConfig.value?.deadAnimals?.enabled && urlSharing.value.includeDeadAnimalIncidents) {
    query.showDeadAnimals = true
    query.deadAnimalMarkerType = pluginConfig.value.deadAnimals.markerType
  }
  const route = router.resolve({name: "CameraCanvas", params, query})
  const url = new URL(route.href, window.location.origin)
  return url.href
})


async function copySharingUrl() {
  const result = await writeToClipboard(sharingUrl.value)
  if (result) {
    toast.success(t("toast.copied_to_clipboard"))
  } else {
    toast.error(t("toast.copy_failed"))
  }
}

async function downloadImage() {
  imageDownloading.value = true
  await downloadAsImage(!stayAtCurrentPositionForImageDownload.value)
  imageDownloading.value = false
}

let reloadTimeout = null

function setupKonva() {
  if (layers.imageLayer) layers.imageLayer.destroy()
  layers.imageLayer = new Konva.Layer()
  stage.value.getStage().add(layers.imageLayer)

  const scaleX = stageConfig.value.scale.x
  const scaleY = stageConfig.value.scale.y
  basePosition = {
    x: ((stageWidth.value / scaleX) / 2),
    y: ((stageHeight.value / scaleY) / 2)
  }
  stage.value.getStage().off("xChange")
  stage.value.getStage().off("yChange")
  stage.value.getStage().off("scaleChange")
  stage.value.getStage().off("wheel")

  stage.value.getStage().on("xChange", (evt) => {
    updatePositions()
  })
  stage.value.getStage().on("yChange", (evt) => {
    updatePositions()
  })
  stage.value.getStage().on("scaleChange", (evt) => {
    if (reloadTimeout !== null) clearTimeout(reloadTimeout)
    reloadTimeout = setTimeout(() => {
      reloadSources()
    }, 500)
  })
  stage.value.getStage().on("wheel", e => {
    if (props.disableScrolling) return
    e.evt.preventDefault();

    const oldScale = stageConfig.value.scale.x;
    var pointer = stage.value.getStage().getPointerPosition();

    var mousePointTo = {
      x: (pointer.x - stage.value?.getStage()?.x()) / oldScale,
      y: (pointer.y - stage.value?.getStage()?.y()) / oldScale,
    };

    // how to scale? Zoom in? Or zoom out?
    let direction = e.evt.deltaY > 0 ? -1 : 1;
    if (e.evt.deltaX !== 0) {
      direction = e.evt.deltaX > 0 ? 1 : -1;
    }
    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    stageConfig.value.scale = {x: newScale, y: newScale};

    var newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    try {
      stageConfig.value.position = newPos
    } catch (e) {
      useLogsStore().addLogEntry({message: "Error while zooming canvas", error: e, tag: "canvas", level: "ERROR"})
    }
  })

  //MOBILE ZOOM
  function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  var lastCenter = null;
  var lastDist = 0;
  var dragStopped = false;

  stage.value.getStage().on('touchmove', function (e) {
    e.evt.preventDefault();
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];

    // we need to restore dragging, if it was cancelled by multi-touch
    if (touch1 && !touch2 && !stage.value.getStage().isDragging() && dragStopped) {
      stage.value.getStage().startDrag();
      dragStopped = false;
    }

    if (touch1 && touch2) {
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (stage.value.getStage().isDragging()) {
        dragStopped = true;
        stage.value.getStage().stopDrag();
      }

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      var newCenter = getCenter(p1, p2);

      var dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stage.value?.getStage()?.x()) / stageConfig.value.scale.x,
        y: (newCenter.y - stage.value?.getStage()?.y()) / stageConfig.value.scale.x,
      };

      var scale = stageConfig.value.scale.x * (dist / lastDist);

      stageConfig.value.scale = {x: scale, y: scale}

      // calculate new position of the stage
      var dx = newCenter.x - lastCenter.x;
      var dy = newCenter.y - lastCenter.y;

      stageConfig.value.position = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      }

      lastDist = dist;
      lastCenter = newCenter;
    }
  });

  stage.value.getStage().on('touchend', function (e) {
    lastDist = 0;
    lastCenter = null;
  });
//MOBILE ZOOM END

  window.addEventListener('resize', () => {
    resize()
  });
  group = new Konva.Group()
}

function getTimestamp() {
  return timestamp.value
}

function resize() {
  const container = document.querySelector('#stage-parent');
  const containerWidth = container.offsetWidth;
  const containerHeight = container.offsetHeight;
  stageConfig.value.width = containerWidth
  stageConfig.value.height = containerHeight
  automaticResize()
}

function buildElementPositions() {
  for (const camera of canvas.value.cameras) {
    elementPositions.value[camera.cameraId] = {
      cameraId: camera.cameraId,
      x: camera.xPosition * canvasWidth.value,
      y: camera.yPosition * canvasHeight.value,
      width: camera.relativeWidth * canvasWidth.value,
      height: Math.round((camera.relativeWidth * canvasWidth.value) / 1.78),
      rotation: camera.rotation,
    }
  }
}

function automaticResize() {
  const r = baseRotation.value
  let canvasBounds = getCanvasBounds()
  let effectiveCanvasWidth
  let effectiveCanvasHeight
  if (r === 0 || r === 180 || r === 360) {
    effectiveCanvasWidth = canvasBounds.maxX - canvasBounds.minX
    effectiveCanvasHeight = canvasBounds.maxY - canvasBounds.minY
  }
  if (r === 90 || r === 270) {
    effectiveCanvasWidth = canvasBounds.maxY - canvasBounds.minY
    effectiveCanvasHeight = canvasBounds.maxX - canvasBounds.minX
  }
  let possibleScaleX = stageWidth.value / effectiveCanvasWidth
  let possibleScaleY = stageHeight.value / effectiveCanvasHeight

  const newScale = Math.min(possibleScaleX, possibleScaleY)

  stageConfig.value.scale = {x: newScale, y: newScale}
  reloadSources()
}

function rotateAndResize() {
  const r = baseRotation.value
  const canvasBounds = getCanvasBounds()

  group.offset({x: canvasBounds.xCenter, y: canvasBounds.yCenter})
  group.rotation(r)

  if (plugins.value) Object.values(plugins.value).forEach(plugin => {
    plugin.group.offset({x: canvasBounds.xCenter, y: canvasBounds.yCenter})
    plugin.group.rotation(r)
  })

  automaticResize()
  goToCenter()
  return reloadSources()
}

function updatePositions() {
  Object.values(providers.value).forEach(provider => {
    provider.updatePosition()
  })
}

function updateProviderTypes() {
  Object.values(providers.value).forEach(provider => {
    provider.setProviderType(elementProvider.value)
  })
}

function updateProviderTimestamps() {
  Object.values(providers.value).forEach(provider => {
    provider.setTimestamp(timestamp.value)
  })

}

function updatePluginConfig(pluginType) {
  if (!plugins.value[pluginType]) return addPlugin(pluginType)
  plugins.value[pluginType].updateConfig(pluginConfig.value[pluginType])
}

function disablePlugin(pluginType) {
  if (!plugins.value[pluginType]) return
  plugins.value[pluginType].disable()
}

function enablePlugin(pluginType) {
  if (!plugins.value[pluginType]) return addPlugin(pluginType)
  plugins.value[pluginType].enable()
}

function destroyPlugin(pluginType) {
  if (!plugins.value[pluginType]) return
  plugins.value[pluginType].destroy()
  delete plugins.value[pluginType]
}

function addPlugin(pluginType) {
  const config = pluginConfig.value[pluginType]
  let pluginClass = null
  switch (pluginType) {
    case "deadAnimals":
      pluginClass = DeadAnimalIncidentPlugin
      break
    case "regions":
      pluginClass = RegionPlugin
      break
  }

  if (!pluginClass) return
  const plugin = new pluginClass({
    config: config,
    enabled: config.enabled,
    canvasId: props.canvasId,
    canvas: canvas.value,
    stage: stage.value,
    group: group,
    router: router,
    elementPositions: elementPositions.value,
    timestamp: timestamp.value,
    emit: emit,
    contextMenuHandler: (c) => {
      contextMenu.value = c;
      showContextMenu.value = true
    },
    tooltipEnable: enablePluginProviderTooltip,
    tooltipDisable: disablePluginProviderTooltip,
    stageWidth: stageWidth.value,
    stageHeight: stageHeight.value,
    pluginTooltipEnabled: pluginTooltipEnabled,
    pluginTooltipConfig: pluginTooltipConfig,
  })

  plugins.value[pluginType] = plugin
  if (!layers.pluginLayers) layers.pluginLayers = {}
  layers.pluginLayers[pluginType] = plugin.layer
  stage.value.getStage().add(plugin.layer)

  const canvasBounds = getCanvasBounds()
  plugins.value[pluginType].group.offset({x: canvasBounds.xCenter, y: canvasBounds.yCenter})
  plugins.value[pluginType].group.rotation(baseRotation.value)

  plugins.value[pluginType].group.position(basePosition)
}

function updatePluginTimestamps() {
  Object.values(plugins.value).forEach(plugin => {
    plugin.updateTimestamp(timestamp.value)
  })
}

function enableElementProviderTooltip({x, y, text}) {
  pluginTooltipEnabled.value = false
  elementProviderTooltipConfig.value = {x, y, text}
  elementProviderTooltipEnabled.value = true
}

function enablePluginProviderTooltip({x, y, text}) {
  elementProviderTooltipEnabled.value = false
  pluginTooltipConfig.value = {x, y, text}
  pluginTooltipEnabled.value = true
}

function disableElementProviderTooltip() {
  elementProviderTooltipEnabled.value = false
}

function disablePluginProviderTooltip() {
  pluginTooltipEnabled.value = false
}

async function loadProvider(cameraId) {
  providers.value[cameraId] = new CanvasElementProvider(elementPositions.value[cameraId], group, stageConfig.value.scale.y, elementProvider.value, timestamp.value, enableElementProviderTooltip, disableElementProviderTooltip, router)
}

async function loadProviders() {
  if (layers.imageLayer) layers.imageLayer.removeChildren()
  if (providers.value) Object.values(providers.value).forEach(provider => provider.destroyElement())
  providers.value = {}
  layers.imageLayer.add(group)
  const promises = Object.values(elementPositions.value).map(elementPosition => {
    return loadProvider(elementPosition.cameraId)
  })
  await Promise.all(promises)
  await reloadSources()
}

async function loadCameras() {
  return loadProviders()
}

async function zoomIn() {

  const p = stageConfig.value.position
  p.x = p.x / stageConfig.value.scale.x
  p.y = p.y / stageConfig.value.scale.y
  stageConfig.value.scale = {x: stageConfig.value.scale.x += .25, y: stageConfig.value.scale.y += .25}

  const scaleX = stageConfig.value.scale.x
  const scaleY = stageConfig.value.scale.y

  p.x = p.x * scaleX
  p.y = p.y * scaleY
  stageConfig.value.position = p

}

async function zoomOut() {
  const p = stageConfig.value.position
  p.x = p.x / stageConfig.value.scale.x
  p.y = p.y / stageConfig.value.scale.y
  stageConfig.value.scale = {x: stageConfig.value.scale.x -= .25, y: stageConfig.value.scale.y -= .25}

  const scaleX = stageConfig.value.scale.x
  const scaleY = stageConfig.value.scale.y

  p.x = p.x * scaleX
  p.y = p.y * scaleY
  stageConfig.value.position = p
}

async function zoomToSpecificPosition({marker}) {
  const position = marker.getAbsolutePosition(stage.value.getStage())

  const elementSize = {width: marker.width(), height: marker.height()};

  const maxScaleX = stageWidth.value / (elementSize.width * 8)
  const maxScaleY = stageHeight.value / (elementSize.height * 8)
  const scaling = Math.min(maxScaleX, maxScaleY)


  const newX = -position.x * scaling + (stageWidth.value / 2) - (elementSize.width * scaling / 2);
  const newY = -position.y * scaling + (stageHeight.value / 2) - (elementSize.height * scaling / 2);

  stageConfig.value.scale = {x: scaling, y: scaling}
  stageConfig.value.position = {x: newX, y: newY}
}


async function reloadSources() {
  for (const provider of Object.values(providers.value)) {
    provider.setScale(stageConfig.value.scale.y)
  }
}

function goToCenter() {
  resize()
  stageConfig.value.position = {x: 0, y: 0}

  const scaleX = stageConfig.value.scale.x
  const scaleY = stageConfig.value.scale.y

  basePosition = {
    x: ((stageWidth.value / scaleX) / 2),
    y: ((stageHeight.value / scaleY) / 2)
  }
  group.position(basePosition)

  if (plugins.value) Object.values(plugins.value).forEach(plugin => {
    plugin.group.position(basePosition)
  });
}

function getCanvasBounds() {
  const rect = group.getClientRect({skipTransform: true})
  const minX = rect.x
  const minY = rect.y
  const maxX = (rect.x + rect.width)
  const maxY = (rect.y + rect.height)
  return {xCenter: (maxX - minX) / 2, yCenter: (maxY - minY) / 2, minX: minX, minY: minY, maxX: maxX, maxY: maxY}
}

async function downloadAsImage(recenter) {
  let boundingBox = null
  const clientRect = layers.imageLayer.getClientRect()
  if (recenter) {
    boundingBox = layers.imageLayer.getClientRect();
  } else {
    boundingBox = {
      x: Math.max(0, clientRect.x),
      y: Math.max(0, clientRect.y),

    }
    boundingBox.width = Math.min(clientRect.width, stageWidth.value - boundingBox.x)
    boundingBox.height = Math.min(clientRect.height, stageHeight.value - boundingBox.y)
  }
  const img = await stage.value.getStage().toBlob({
    x: boundingBox.x,
    y: boundingBox.y,
    width: boundingBox.width,
    height: boundingBox.height,
    mimeType: "image/png",
  })
  const filename = `${useInstanceStore().selectedInstance.name}_${timestamp.value.toFormat("yyyy-MM-dd_HH-mm-ss")}.png`
  useDownloadFile(img, filename)
}

function enableVisualLabelingMode(type) {
  visualLabelingMode.value = true

  Object.values(providers.value).forEach(provider => provider.enableBorders())
  for (const layer of Object.values(layers.pluginLayers)) {
    layer.listening(false)
  }

  if (layers.visualLabelingLayer) layers.visualLabelingLayer.destroy()

  layers.visualLabelingLayer = new Konva.Layer()

  setupLabelingHelper({
    getTimestamp: getTimestamp,
    labelingType: type,
    providers: providers.value,
    imageLayer: layers.imageLayer,
    stage: stage.value,
    labelingLayer: layers.visualLabelingLayer,
  })
  stage.value.getStage().add(layers.visualLabelingLayer)
}

function disableVisualLabelingMode() {
  visualLabelingMode.value = false
  Object.values(providers.value).forEach(provider => provider.disableBorders())
  tearDownLabelingHelper()

  for (const layer of Object.values(layers.pluginLayers)) {
    layer.listening(true)
  }

  layers.visualLabelingLayer.destroy()
  delete layers.visualLabelingLayer
  emit("disableDeadAnimalLabeling")
}

function rotateLeft() {
  let r = baseRotation.value
  r = r - 90
  if (r < 0) r += 360
  baseRotation.value = r
}

function rotateRight() {
  let r = baseRotation.value
  r = r + 90
  if (r >= 360) r -= 360
  baseRotation.value = r
}

watch(() => baseRotation.value, () => {
  if (!initialSetup.value) return
  rotateAndResize()
})

watch(timestamp, async (value, oldValue) => {
  if (!initialSetup.value) return
  if (value.startOf("second").toMillis() === oldValue.startOf("second").toMillis()) return
  updateProviderTimestamps()
  updatePluginTimestamps()
})

const loading = computed(() => {
  return imageProvidersLoading.value || !initialSetup.value || plugins.value?.deadAnimals?.reactiveProperties.loading || plugins.value?.regions?.reactiveProperties.loading
})

const imageProvidersLoading = computed(() => {
  return Object.values(providers.value).filter(provider => provider.reactiveState.loading).length > 0
})

const loadingText = computed(() => {
  if (!loading.value) return ""
  if (!initialSetup.value) return t("canvas.loading_initial_setup")
  if (imageProvidersLoading.value) return t("canvas.loading_image_providers")
  if (plugins.value?.deadAnimals?.reactiveProperties.loading) return t("canvas.loading_dead_animals")
  if (plugins.value?.regions?.reactiveProperties.loading) return t("canvas.loading_regions")

  return t("canvas.loading")
})

function updatePlugins(newConfig = {}, oldConfig = {}) {
  try {
    if (!initialSetup.value) return

    const removedPlugins = Object.keys(oldConfig || {}).filter(key => !Object.keys(newConfig || {}).includes(key))
    const addedPlugins = Object.keys((newConfig || {})).filter(key => !Object.keys(oldConfig || {}).includes(key))
    const updatedPlugins = Object.keys((newConfig || {})).filter(key => Object.keys(oldConfig || {}).includes(key))

    for (const pluginType of removedPlugins) {
      disablePlugin(pluginType)
    }
    for (const pluginType of addedPlugins) {
      if (newConfig[pluginType].enabled) enablePlugin(pluginType)
    }
    for (const pluginType of updatedPlugins) {
      if (!newConfig[pluginType].enabled) {
        disablePlugin(pluginType)
      } else {
        updatePluginConfig(pluginType)
        enablePlugin(pluginType)
      }
    }
  } catch (e) {
    useLogsStore().addLogEntry({message: "Error while updating plugins", error: e, tag: "canvas", level: "ERROR"})
  }
}

function forceUpdateDeadAnimalPlugin() {
  if (!plugins.value["deadAnimals"]) return
  plugins.value["deadAnimals"].forceUpdate()
}

function updateDeadAnimalOverlayExpanded(value) {
  deadAnimalOverlayExpanded.value = value
  if (value && mobile.value) {
    enableTimeJumper.value = false;
    expandTimePicker.value = false;
  }
}

function updateTimePickerExpanded(value) {
  expandTimePicker.value = value
  if (value && mobile.value) {
    deadAnimalOverlayExpanded.value = false;
  }
}

function showTimeJumper() {
  enableTimeJumper.value = true
  if (mobile.value) {
    deadAnimalOverlayExpanded.value = false;
  }
}

watch(() => pluginConfig.value, (value, oldValue) => {
  updatePlugins(value, oldValue)
}, {deep: true, immediate: true})

watch(() => elementProvider.value, async () => {
  if (!initialSetup.value) return
  updateProviderTypes()
})

const baseCanvas = computed(() => {
  return useCanvasStore().getBaseCanvas()
})

const canvasId = computed(() => {
  if (props.canvasId) return props.canvasId
  if (props.overrideCanvas) return props.overrideCanvas.cameraCanvasId
  if (baseCanvas.value) return baseCanvas.value.cameraCanvasId
  return null
})


watch([canvasId, baseCanvas, () => !!stage.value], async (value) => {
  if (!value[2]) return
  if (!value[0]) return
  if (value[1] && value[1].cameraCanvasId === value[0]) {
    canvas.value = value[1]
  } else {
    canvas.value = await canvasStore.loadSpecificCanvas(value[0])
  }

}, {immediate: true, deep: true})

const stageParent = ref(null)
watch(canvas, async (value, oldValue) => {
  if (!canvas.value) return
  if (_.isEqual(oldValue, value)) return
  initialSetup.value = false
  setupKonva()
  stageConfig.value.width = document.querySelector("#stage-parent").offsetWidth
  stageConfig.value.height = document.querySelector("#stage-parent").offsetHeight
  buildElementPositions()
  initialSetup.value = true
  await loadCameras()
  updatePlugins(pluginConfig.value)
  await rotateAndResize()
  automaticResize()
  goToCenter()
}, {immediate: true, deep: true})

onMounted(async () => {
  const el = document.querySelector("#stage-parent")
  const resizeObserver = new ResizeObserver(() => {
    automaticResize()
    goToCenter()
  })
  resizeObserver.observe(el)
  if (props.shrinkAllOverlaysByDefault) {
    updateDeadAnimalOverlayExpanded(false)
    updateTimePickerExpanded(false)
    enableTimeJumper.value = false
  }
  if (baseRotation.value === null) {
    const display = useDisplay()
    const width = display.width.value
    const height = display.height.value
    if (width > height) baseRotation.value = 270;
    else baseRotation.value = 0;
  }
  if (props.disableScrolling === true) {
    stageConfig.value.draggable = false
  }
  useCanvasStore().keepBaseCanvasLoaded()
  useRegionStore().keepRegionsLoaded()
  await useCamerasStore().keepCamerasLoaded()
})


onBeforeUnmount(() => {
  if (providers.value) Object.values(providers.value).forEach(provider => provider.destroyElement())
  if (plugins.value) Object.values(plugins.value).forEach(plugin => plugin.destroyPlugin())
  if (layers.visualLabelingLayer) layers.visualLabelingLayer.destroy()
  if (layers.pluginLayers) Object.values(layers.pluginLayers).forEach(layer => layer.destroy())
  if (layers.imageLayer) layers.imageLayer.destroy()
  if (stage.value) stage.value.getStage().destroy()
})

defineExpose({
  goToCenter,
  automaticResize,
  downloadAsImage,
})

</script>

<template>
  <div ref="stageParent" id="stage-parent" style="position: absolute; width: 100%; height: 100%">
    <v-stage
      id="stage"
      ref="stage"
      :config="stageConfig"
    />

    <v-dialog
      v-model="showShareDialog"
      max-width="600px"
    >
      <v-card class="rounded">
        <v-card-text>
          <v-card-title>{{ $t("canvas.sharing.url_title") }}</v-card-title>
          <v-text-field
            :model-value="sharingUrl"
            class="ma-2"
            :readonly="true"
            variant="outlined"
          >
            <template #append-inner>
              <v-btn
                variant="flat"
                class="rounded-pill"
                color="primary"
                @click="copySharingUrl"
              >
                {{ $t("general_interface.buttons.copy") }}
              </v-btn>
            </template>
          </v-text-field>
          <v-row
            justify="center"
            :dense="true"
            :no-gutters="true"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="urlSharing.includeTimestamp"
                class="ma-2"
                :hide-details="true"
                :label="$t('canvas.sharing.include_timestamp')"
              />
            </v-col>

            <v-col cols="auto">
              <v-checkbox
                v-model="urlSharing.includeImageSource"
                class="ma-2"
                :hide-details="true"
                :label="$t('canvas.sharing.include_image_source')"
              />
            </v-col>

            <v-col cols="auto">
              <v-checkbox
                v-if="pluginConfig.value?.regions?.enabled"
                v-model="urlSharing.includeRegions"
                class="ma-2"
                :hide-details="true"
                :label="$t('canvas.sharing.include_regions')"
              />
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-if="pluginConfig.value?.deadAnimals?.enabled"
                v-model="urlSharing.includeDeadAnimalIncidents"
                class="ma-2"
                :hide-details="true"
                :label="$t('canvas.sharing.include_dead_animal_incidents')"
              />
            </v-col>
          </v-row>
          <v-divider
            class="mb-2"
            thickness="2"
          />

          <v-card-title>{{ $t("canvas.sharing.image_title") }}</v-card-title>
          <v-row class="justify-center">
            <v-col cols="auto">
              <v-checkbox
                v-model="stayAtCurrentPositionForImageDownload"
                :label="$t('canvas.sharing.use_current_image_section')"
              />
            </v-col>
          </v-row>

          <v-btn
            :block="true"
            variant="flat"
            color="primary"
            class="rounded-pill"
            :loading="imageDownloading"
            @click="downloadImage"
          >
            {{ $t("canvas.sharing.download_image") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <BasicNavigation
      id="basic_nav"
      style="position: absolute"
      :include-zoom="!disableZoomButtons"
      v-if="!disableNavigation"
      @click:recenter="automaticResize(); goToCenter()"
      @click:rotate-left="rotateLeft()"
      @click:rotate-right="rotateRight()"
      @click:zoom-in="zoomIn"
      @click:zoom-out="zoomOut"
    />

    <v-sheet
      v-if="loading"
      variant="elevated"
      class="position-absolute pt-1 rounded justify-center"
      style="top: 8px; right: 50%;
              transform: translate(+50%); z-index: 10"
    >
      <v-label class="mx-2">
        {{ loadingText }}
      </v-label>
      <v-progress-linear :indeterminate="true"/>
    </v-sheet>
    <v-slide-x-transition>
      <CanvasTimePicker
        v-if="!disableTimeJumper"
        v-model="timestamp"
        :enable-time-jumper="enableTimeJumper"
        :expanded="expandTimePicker"
        @update:enable-time-jumper="showTimeJumper"
        @update:expanded="updateTimePickerExpanded"
      />
    </v-slide-x-transition>

    <CanvasTimeJumper
      v-if="enableTimeJumper && !disableTimeJumper"
      v-model="timestamp"
      @close="enableTimeJumper = false"
    />
    <ElementProviderPicker
      v-if="!disableElementPicker"
      :element-provider="elementProvider"
      :plugin-config="pluginConfig"
      @update:element-provider="elementProvider = $event"
      @update:plugin-config="pluginConfig = $event"
      @open-share-dialog="showShareDialog = true"
    />

    <DeadAnimalOverlay
      :plugin-config="pluginConfig['deadAnimals']"
      :plugin="plugins['deadAnimals']"
      :expanded="deadAnimalOverlayExpanded"
      :visual-labeling-mode-enabled="visualLabelingMode"
      @update:expanded="updateDeadAnimalOverlayExpanded"
      @zoom-to-specific-position="zoomToSpecificPosition"
      @enable-visual-labeling-mode="enableVisualLabelingMode"
      @disable-visual-labeling-mode="disableVisualLabelingMode"
    />

    <v-menu
      v-if="showContextMenu"
      v-model="showContextMenu"
      close-delay="0"
      :close-on-content-click="false"
      :close-on-click="true"
      :style="showContextMenu ? ` top: ${contextMenu.value.y}px; left: ${contextMenu.value.x}px;` : undefined"
      z-index="100"
      offset-y
    >
      <component
        :is="contextMenu.value.component"
        v-bind="contextMenu.value.props"
        v-on="contextMenu.value.on"
      />
    </v-menu>

    <v-tooltip
      v-if="elementProviderTooltipEnabled"
      v-model="elementProviderTooltipEnabled"
      :style="elementProviderTooltipEnabled ? ` top: ${elementProviderTooltipConfig.y}px; left: ${elementProviderTooltipConfig.x}px;` : undefined"
      :text="elementProviderTooltipConfig.text"
    />

    <v-tooltip
      v-if="pluginTooltipEnabled"
      v-model="pluginTooltipEnabled"
      :style="pluginTooltipEnabled ? ` top: ${pluginTooltipConfig.y}px; left: ${pluginTooltipConfig.x}px;` : undefined"
      :text="pluginTooltipConfig.text"
    />


    <v-dialog
      v-if="showDeadAnimalIncidentTrigger"
      v-model="showDeadAnimalIncidentTrigger"
      width="auto"
    >
      <TriggerDeadAnimal
        :new-incident-payload="deadAnimalIncidentTriggerPayload"
        @saved="showDeadAnimalIncidentTrigger = false; disableVisualLabelingMode(); forceUpdateDeadAnimalPlugin() "
        @cancel="showDeadAnimalIncidentTrigger = false; disableVisualLabelingMode()"
      />
    </v-dialog>
  </div>

</template>
