<script setup>

import CanvasRenderer from "@/components/CanvasRenderer.vue";
import {onMounted, ref, computed, reactive, onBeforeMount} from "vue";
import {useCanvasStore} from "@/store/canvas";
import SimpleLineChart from "@/components/charts/SimpleLineChart.vue";
import {useIncidentStore} from "@/store/incident";
import IncidentListRenderer from "@/components/IncidentListRenderer.vue";
import DocumentQueryList from "@/views/DocumentQueryList.vue";
import DeadAnimalStatistics from "@/views/DeadAnimalStatistics.vue";
import {DateTime} from "luxon";
import TriggerBarnIntervention from "@/components/incidentTriggers/TriggerBarnIntervention.vue";
import RegionChart from "@/components/charts/RegionChart.vue";

const canvasStore = useCanvasStore()

const timestamp = ref(DateTime.now().startOf("second"))
const baseCanvas = computed(() => {
  return canvasStore.getBaseCanvas()
})
const newBarnInterventionDialog = ref(false)
const barnInterventionList = ref(null)

onMounted(() => {
  timestamp.value = DateTime.now().startOf("second")
  canvasStore.keepBaseCanvasLoaded()
})
</script>

<template>
  <v-row no-gutters>
    <v-col :cols="12">
      <v-layout-card class="px-0" style="height: 60vh">
        <CanvasRenderer
          :disable-zoom-buttons="true"
          v-if="baseCanvas"
          :override-canvas="baseCanvas"
          :model-value:base-rotation="270"
          :disable-element-picker="false"
          :disable-time-jumper="false"
          :disable-navigation="false"
          :disable-scrolling="true"
          :shrink-all-overlays-by-default="true"
          :timestamp="timestamp"
        />
      </v-layout-card>
    </v-col>
    <v-col :cols="12">
      <SimpleLineChart
        :time-setup="{start: timestamp.toMillis() - 1000 * 60 * 60 * 24 * 4, end: timestamp.toMillis(), timeResolution: 1000 * 60 * 10}"
        :disable-control-row="false"
        :aggregate="true"
        measurement="activity"
        field="activity"
        :title="$t('charts.titles.activity')"
        :subtitle="$t('charts.units.activity')"
      />
    </v-col>
    <v-col :cols="12">
      <RegionChart
        :time-setup="{start: timestamp.toMillis() - 1000 * 60 * 60 * 24 * 4, end: timestamp.toMillis(), timeResolution: 1000 * 60 * 10}"
        :aggregate-labels="false"
        :disable-control-row="false"
        :region-type="'stalltechnik'"
        :title="$t('charts.titles.regions')"
      />
    </v-col>
    <v-col :cols="12">
      <SimpleLineChart
        :time-setup="{start: timestamp.toMillis() - 1000 * 60 * 60 * 24 * 4, end: timestamp.toMillis(), timeResolution: 1000 * 60 * 10}"
        :disable-control-row="false"
        :aggregate="true"
        measurement="speed"
        field="speed"
        :title="$t('charts.titles.activity')"
        :subtitle="$t('charts.units.activity')"
      />
    </v-col>
    <v-col :cols="12">
      <DeadAnimalStatistics :show-settings="false" :pre-defined-days="10"/>
    </v-col>
    <v-col :cols="12">
      <v-layout-card>
        <v-row justify="space-between" align="center">
          <v-col cols="auto">
            <v-card-title>{{ $t("barn_intervention_list.title") }}</v-card-title>
          </v-col>
          <v-col cols="auto">
            <v-btn class="ma-2 rounded-pill" variant="flat" color="primary">
              {{ $t("barn_intervention_list.add") }}
              <v-dialog activator="parent" v-model="newBarnInterventionDialog">
                <TriggerBarnIntervention
                  :shrinkable="false"
                  :show-cancel-button="true"
                  @cancel="newBarnInterventionDialog = false"
                  @saved="newBarnInterventionDialog = false; barnInterventionList?.reload()"
                ></TriggerBarnIntervention>

              </v-dialog>
            </v-btn>
          </v-col>
        </v-row>

        <v-card-text>
          <DocumentQueryList
            ref="barnInterventionList"
            hide-sortings
            hide-filters
            hide-items-per-page
            :auto-load="true"
            :preset-items-per-page="5"
            query-type="incident"
            :query-function="useIncidentStore().queryIncidents"
            :enable-query-params="false"
            :preset-filters="[
              {key: 'type', operator: '==', value: 'barnIntervention', forced: true},
              {key: 'firstEventTimestamp', operator: '<=', value: timestamp.toMillis(), forced: true},
            ]"
            :preset-order="{sortBy: 'firstEventTimestamp', direction: 'desc'}"
          >
            <template #list="listProps">
              <IncidentListRenderer
                :incidents="listProps.documents"
                predefined-view-mode="table"
              />
            </template>
          </DocumentQueryList>
        </v-card-text>
      </v-layout-card>
    </v-col>

  </v-row>
</template>
<style>

</style>
