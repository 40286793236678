<script setup>
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import AddFilterForm from "@/components/AddFilterForm.vue";
import {useRoute, useRouter} from "vue-router";
import {useFilter} from "@/composables/filters/filters";
import {ALL, ANY, CONTAINS, IN, NIN} from "@/composables/filters/operators";
import {useCamerasStore} from "@/store/cameras";
import {useCameraName} from "@/composables/cameraHelpers";
import i18n from "../plugins/i18n"
import {humanReadableTimestamp} from "@/composables/datetime";
import JsonEditorVue from 'json-editor-vue'
import 'vanilla-jsoneditor/themes/jse-theme-dark.css'
import {useSettingsStore} from "@/store/settings";
import {useDisplay} from "vuetify";
import OutdatedAlert from "@/components/OutdatedAlert.vue";

const isOutdated = ref(false)
const settingsStore = useSettingsStore()
const {t} = i18n.global
const router = useRouter()
const route = useRoute()
const {mobile} = useDisplay()
const props = defineProps({
  queryType: {type: String, default: ""},
  queryFunction: {
    type: Function, default: () => async () => {
    }
  },
  presetFilters: {type: Array, default: () => [], required: false,},
  presetItemsPerPage: {type: Number, default: 0},
  presetOrder: {type: Object, default: null},
  readOnly: {type: Boolean, default: false},
  hideFilters: {type: Boolean, default: false},
  hideSortings: {type: Boolean, default: false},
  hideItemsPerPage: {type: Boolean, default: false},
  autoLoad: {type: Boolean, default: false},
  enableQueryParams: {type: Boolean, default: true},
  showDisplaySettings: {type: Boolean, default: false},
  uniqueDocumentIdentifier: {type: String, required: false, default: ""},
  reloadSpecificDocumentFunction: {
    type: Function, required: false, default: () => async () => {
    }
  },
})
const appliedFilters = ref([])
const newFilterDialog = ref(false)
const itemsPerPage = ref(5)
const documents = ref(null)
const resetPagination = ref(false)
const sortBy = ref(null)
const orderDirection = ref("asc")
const currentPage = ref(1)
const moreDocumentsAvailable = ref(false)
const loading = ref(false)
const initialLoaded = ref(false)
const showGoToTopButton = ref(false)
const showCustomQueryDialog = ref(false)
const customQuery = ref("")
const orders = [
  {value: "asc", title: t("general_interface.ordering.ascending")},
  {value: "desc", title: t("general_interface.ordering.descending")}
]

defineExpose({reloadSpecificDocument, reload, parseQueryParams})

const filter = computed(() => {
  return useFilter(props.queryType)
})
const sortableProperties = computed(() => {
  return filter.value.getSortableProperties()
})
const noDocumentsFound = computed(() => {
  return (!loading.value && (!documents.value || !documents.value.length) && initialLoaded.value)
})

function removeFilter(index) {
  appliedFilters.value.splice(index, 1)
  updateQueryParams()
  updateCustomQuery()
}

function setOutdated() {
  if (!initialLoaded.value) return
  resetPagination.value = true;
  initialLoaded.value = false;
  isOutdated.value = true;
}

async function addFilter(filter) {
  appliedFilters.value.push(hydrateFilter(filter))
  newFilterDialog.value = false
  updateQueryParams()
  updateCustomQuery()
}

function updateQueryParams() {
  if (!props.enableQueryParams) return
  const query = {}
  query.filters = encodeURIComponent(JSON.stringify(appliedFilters.value.map(filter => ({
    key: filter.key,
    operator: filter.operator,
    value: filter.value
  }))))
  if (sortBy.value) query.order = encodeURIComponent(JSON.stringify({[sortBy.value]: orderDirection.value}))
  router.replace({query})
}

function updateCustomQuery() {
  customQuery.value = {
    query: appliedFilters.value.map(serializeFilter),
    limit: itemsPerPage.value,
    order: {[sortBy.value]: orderDirection.value},
  }
}

function saveCustomQuery() {

  if (!customQuery.value) {
    showCustomQueryDialog.value = false;
    return
  }
  const queryObject = JSON.parse(customQuery.value)
  appliedFilters.value = []
  queryObject?.query?.forEach(filter => {
    addFilter(filter)
  })

  itemsPerPage.value = queryObject?.limit
  sortBy.value = Object.entries(queryObject?.order)?.[0]?.[0]
  orderDirection.value = Object.entries(queryObject?.order)?.[0]?.[1]

  showCustomQueryDialog.value = false
}

function hydrateFilter(filter) {
  const filterConfig = useFilter(props.queryType)
  const propertyTitle = filterConfig.getPropertyTitle(filter.key)
  const dataType = filterConfig.getDataType(filter.key)
  const isArrayOperator = [NIN, IN, CONTAINS, ALL, ANY].includes(filter.operator)
  let valueText = ""
  if (dataType === "camera") {
    if (!isArrayOperator) {
      valueText = useCameraName(filter.value)
    } else {
      const cameraNames = []
      for (const cameraId of filter.value) {
        cameraNames.push(useCameraName(cameraId))
      }
      valueText = cameraNames.join(", ")
    }
  }
  if (dataType === "String") valueText = filter.value
  if (dataType === "Number") valueText = filter.value
  if (dataType === "generic") valueText = filter.value
  if (dataType === "timestamp") valueText = humanReadableTimestamp(filter.value)
  if (dataType === "autocomplete") {
    if (!isArrayOperator) {
      valueText = filterConfig.getOptionTitle(filter.key, filter.value)
    } else {
      const optionTitles = []
      for (const optionId of filter.value) {
        optionTitles.push(filterConfig.getOptionTitle(filter.key, optionId))
      }
      valueText = optionTitles.join(", ")
    }
  }
  const text = `${propertyTitle} ${filter.operator} ${valueText}`

  return Object.assign({text: text}, filter)
}

function serializeFilter(filter) {
  return {key: filter.key, operator: filter.operator, value: filter.value}
}

async function rehydrateFilters(dehydratedFilters) {
  const rehydratedFilters = []
  for (let dehydratedFilter of dehydratedFilters) {
    rehydratedFilters.push(hydrateFilter(dehydratedFilter))
  }
  return rehydratedFilters
}

async function parseQueryParams() {
  let filters = []
  props.presetFilters.forEach(presetFilter => {
    filters.push(presetFilter)
  })

  if (props.presetOrder) {
    sortBy.value = props.presetOrder.sortBy
    orderDirection.value = props.presetOrder.direction
  }

  if (props.enableQueryParams) {
    const queryParams = route.query

    if (queryParams.filters) {
      const decodedString = decodeURIComponent(queryParams.filters)
      JSON.parse(decodedString).forEach(filter => {
        filters.push(filter)
      })
    }

    if (queryParams.order) {
      const decodedString = decodeURIComponent(queryParams.order)
      const parsed = JSON.parse(decodedString)
      if (Object.entries(parsed).length >= 1) {
        sortBy.value = Object.entries(parsed)[0][0]
        orderDirection.value = Object.entries(parsed)[0][1]
      }
    }
  }

  appliedFilters.value = await rehydrateFilters(filters)
}

async function load({startAfter = undefined, endBefore = undefined} = {}) {
  isOutdated.value = false;
  loading.value = true;
  if (resetPagination.value) {
    currentPage.value = 1
  }
  const payload = {
    query: appliedFilters.value.map(serializeFilter),
    limit: itemsPerPage.value + 1,
  }
  if (sortBy.value) payload.order = {[sortBy.value]: orderDirection.value}
  if (startAfter) payload.startAfter = startAfter
  if (endBefore) payload.endBefore = endBefore
  resetPagination.value = false;
  const rawDocuments = await props.queryFunction(payload)
  moreDocumentsAvailable.value = (rawDocuments && rawDocuments.length === itemsPerPage.value + 1)
  loading.value = false;

  if (!moreDocumentsAvailable.value) {
    documents.value = rawDocuments
  } else {
    documents.value = rawDocuments.slice(0, -1)
  }
  initialLoaded.value = true;
  isOutdated.value = false;
}

async function reload() {
  await load()
}

async function reloadSpecificDocument(documentId) {
  if (!props.reloadSpecificDocumentFunction) return;
  if (!props.uniqueDocumentIdentifier) return;
  const document = await props.reloadSpecificDocumentFunction(documentId)
  if (document) {
    const index = documents.value.findIndex(document => document[props.uniqueDocumentIdentifier] === documentId)
    documents.value.splice(index, 1, document)
  } else {
    documents.value = documents.value.filter(document => document[props.uniqueDocumentIdentifier] !== documentId)
  }

}

async function nextPage() {
  currentPage.value = currentPage.value + 1
  await load({startAfter: documents.value[documents.value.length - 1]})
  goToTop()
}

async function previousPage() {
  currentPage.value = currentPage.value - 1
  await load({endBefore: documents.value[0]})
  goToTop()
}

function handleScroll() {
  showGoToTopButton.value = (window.scrollY > 100)
}

function goToTop() {
  window.scrollTo(0, 0)
}


const canGoToPreviousPage = computed(() => {
  if (currentPage.value === 1) return false;
  if (!documents.value || documents.value.length === 0) return false;
  return true;
})

const canGoToNextPage = computed(() => {
  if (!documents.value || documents.value.length === 0) return false;
  return moreDocumentsAvailable.value;
})


watch(orderDirection, () => {
  setOutdated()
  updateQueryParams()
  updateCustomQuery()
})

watch(sortBy, () => {
  setOutdated()
  updateQueryParams()
  updateCustomQuery()
})

watch(appliedFilters, () => {
  setOutdated()
}, {deep: true})

watch(itemsPerPage, () => {
  setOutdated()
  updateCustomQuery()
})

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded()
  if (props.presetItemsPerPage) itemsPerPage.value = props.presetItemsPerPage
  await parseQueryParams()
  window.addEventListener("scroll", handleScroll);

  if (props.autoLoad) {
    resetPagination.value = true
    return load();
  }
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})
</script>

<template>
  <v-container
    :fluid="true"
    class="mx-0 ma-0 pt-0 px-0 pb-16"
  >
    <v-layout-card
      v-if="!hideItemsPerPage || !hideFilters || !hideSortings"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-text>
        <v-row>
          <v-col
            v-if="!hideFilters"
            :cols="12"
          >
            <v-input hide-details>
              <template #append>
                <v-btn
                  color="success"
                  size="small"
                  icon
                >
                  <v-icon icon="mdi-plus"/>
                  <v-dialog
                    v-model="newFilterDialog"
                    :fullscreen="mobile"
                    activator="parent"
                    max-width="1000px"
                  >
                    <AddFilterForm
                      :filter-type="queryType"
                      @confirm="addFilter"
                      @cancel="newFilterDialog = false"
                    />
                  </v-dialog>
                </v-btn>
              </template>
              <v-sheet
                class="w-100 pa-4"
                elevation="10"
              >
                <v-chip
                  v-for="(appliedFilter, index) in appliedFilters"
                  :key="index"
                  class="mx-2 my-1"
                  close-icon="mdi-close"
                  :append-icon="!appliedFilter.forced ? 'mdi-close' : undefined"
                  @click="!appliedFilter.forced ? removeFilter(index) : undefined"
                >
                  {{ appliedFilter.text }}
                </v-chip>

                <v-row
                  v-if="appliedFilters.length === 0"
                  justify="center"
                  class="ma-1"
                >
                  <v-alert
                    type="info"
                  >
                    {{ $t('general_interface.filter.no_filters') }}
                  </v-alert>
                </v-row>
                <v-btn
                  class="float-end"
                  variant="flat"
                  size="small"
                  icon
                >
                  <v-icon icon="mdi-code-json"/>
                  <v-dialog
                    v-model="showCustomQueryDialog"
                    :fullscreen="mobile"
                    activator="parent"
                    max-width="1000px"
                  >
                    <v-layout-card>
                      <v-card-title>{{ $t("general_interface.filter.custom_query") }}</v-card-title>
                      <v-card-text>
                        <JsonEditorVue
                          v-model="customQuery"
                          mode="text"
                          :class="settingsStore.theme === 'dark' ? 'jse-theme-dark' : ''"
                        />
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          variant="outlined"
                          color="error"
                          @click="showCustomQueryDialog = false"
                          class="rounded-pill"
                        >
                          {{ $t("general_interface.buttons.cancel") }}
                        </v-btn>

                        <v-btn
                          variant="flat"
                          color="success"
                          @click="saveCustomQuery"
                          class="rounded-pill"
                        >
                          {{ $t("general_interface.buttons.confirm") }}
                        </v-btn>
                      </v-card-actions>
                    </v-layout-card>
                  </v-dialog>
                </v-btn>
              </v-sheet>
            </v-input>
          </v-col>
          <v-col>
            <v-row
              :no-gutters="true"
              justify="end"
            >
              <v-col
                v-if="!hideItemsPerPage"
                :cols="12"
                md="auto"
              >
                <v-select
                  v-model="itemsPerPage"
                  :label="$t('general_interface.filter.items_per_page')"
                  class="ma-2"
                  variant="outlined"
                  style="min-width: 200px"
                  :items="[5, 10, 20, 50]"
                />
              </v-col>
              <v-col/>
              <v-col
                v-if="!hideSortings"
                :cols="12"
                md="auto"
              >
                <v-layout class="justify-end">
                  <v-select
                    v-model="sortBy"
                    class="ma-2"
                    :label="$t('general_interface.ordering.sort_by')"
                    :items="sortableProperties"
                    item-value="id"
                    item-title="title"
                    :clearable="false"
                    variant="outlined"
                  />
                </v-layout>
              </v-col>
              <v-col
                v-if="!hideSortings"
                :cols="12"
                md="auto"
              >
                <v-select
                  v-model="orderDirection"
                  class="ma-2"
                  :label="$t('general_interface.filter.order')"
                  :items="orders"
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          variant="elevated"
          @click="() => {resetPagination = true; return load(); }"
          class="rounded-pill"
        >
          {{ $t("general_interface.buttons.load") }}
        </v-btn>
      </v-card-actions>
    </v-layout-card>
    <v-layout-card
      v-if="showDisplaySettings && !loading && documents"
    >
      <v-card-title>{{ $t("general_interface.display_settings") }}</v-card-title>
      <v-card-text>
        <slot name="displaySettings"/>
      </v-card-text>
    </v-layout-card>

    <slot
      v-if="!loading && documents && documents.length > 0"
      name="list"
      :documents="documents"
      :reload="load"
    />
    <OutdatedAlert
      v-if="isOutdated"
      @click="goToTop(); resetPagination = true; load();"
    />

    <v-fade-transition>
      <v-btn
        v-if="showGoToTopButton"
        elevation="5"
        color="primary"
        icon="mdi-arrow-up"
        style="position: fixed; bottom: 24px; right: 24px;"
        @click="goToTop"
      />
    </v-fade-transition>

    <v-container
      v-if="loading"
      class="ma-0 pa-0 w-100"
      :fluid="true"
    >
      <v-skeleton-loader
        v-for="index in itemsPerPage"
        :key="index"
        type="article, actions"
        class="ma-2 w-100"
      />
    </v-container>

    <v-row
      v-if="noDocumentsFound"
      class="justify-center my-4 mx-1"
    >
      <v-alert
        type="error"
        max-width="500px"
      >
        {{ $t("general_interface.filter.nothing_found_text") }}
      </v-alert>
    </v-row>

    <v-row
      v-if="!isOutdated && !resetPagination && !noDocumentsFound && (canGoToNextPage || canGoToPreviousPage)"
      class="justify-center align-center my-4"
    >
      <v-btn
        prepend-icon="mdi-arrow-left"
        class="ma-2 rounded-pill"
        :disabled="!canGoToPreviousPage"
        @click="previousPage"
      >
        {{ $t("general_interface.pagination.previous") }}
      </v-btn>
      <v-chip
        class="ma-2"
        variant="flat"
        size="large"
      >
        {{ $t("general_interface.pagination.page", currentPage) }}
      </v-chip>
      <v-btn
        append-icon="mdi-arrow-right"
        class="ma-2 rounded-pill"
        :disabled="!canGoToNextPage"
        @click="nextPage"
      >
        {{ $t("general_interface.pagination.next") }}
      </v-btn>
    </v-row>
  </v-container>
</template>
