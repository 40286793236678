<script setup>

import DateTimePicker from "@/components/DateTimePicker.vue";
import {DateTime} from "luxon";
import {computed, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {humanReadableDuration, humanReadableTimestamp} from "@/composables/datetime.js";

const t = useI18n().t

const props = defineProps({
  hideTimeResolution: {type: Boolean, default: false},
})

const modelValue = defineModel()

const availableTimeRanges = [
  {text: t("charts.relative_durations.minutes", 10, {count: 10}), value: 1000 * 60 * 10},
  {text: t("charts.relative_durations.minutes", 30, {count: 30}), value: 1000 * 60 * 30},
  {text: t("charts.relative_durations.hours", 1), value: 1000 * 60 * 60},
  {text: t("charts.relative_durations.hours", 2, {count: 2}), value: 1000 * 60 * 60 * 2},
  {text: t("charts.relative_durations.hours", 6, {count: 6}), value: 1000 * 60 * 60 * 6},
  {text: t("charts.relative_durations.hours", 12, {count: 12}), value: 1000 * 60 * 60 * 12},
  {text: t("charts.relative_durations.days", 1), value: 1000 * 60 * 60 * 24},
  {text: t("charts.relative_durations.days", 2, {count: 2}), value: 1000 * 60 * 60 * 24 * 2},
  {text: t("charts.relative_durations.days", 3, {count: 3}), value: 1000 * 60 * 60 * 24 * 3},
  {text: t("charts.relative_durations.days", 4, {count: 4}), value: 1000 * 60 * 60 * 24 * 4},
  {text: t("charts.relative_durations.weeks", 1, {count: 1}), value: 1000 * 60 * 60 * 24 * 7},
  {text: t("charts.relative_durations.weeks", 4, {count: 4}), value: 1000 * 60 * 60 * 24 * 7 * 4},
  {text: t("charts.relative_durations.weeks", 12, {count: 12}), value: 1000 * 60 * 60 * 24 * 7 * 12},
]

const timeResolutionUnits = [
  {text: t("charts.units.seconds"), value: 1000},
  {text: t("charts.units.minutes"), value: 1000 * 60},
  {text: t("charts.units.hours"), value: 1000 * 60 * 60},
  {text: t("charts.units.days"), value: 1000 * 60 * 60 * 24},
  {text: t("charts.units.weeks"), value: 1000 * 60 * 60 * 24 * 7},
]

const absoluteStartTime = ref(null)
const absoluteEndTime = ref(null)
const pickedRelativeTimeRange = ref(0)
const manuallyPickedTimeResolutionValue = ref(10)
const manuallyPickedTimeResolutionUnit = ref(1000 * 60)
const autoTimeResolution = ref(true)


const showResetButton = computed(() => {
  if (!absoluteStartTime.value || absoluteStartTime.value.toMillis() !== modelValue.value?.start) {
    return true
  }
  if (!absoluteEndTime.value || absoluteEndTime.value.toMillis() !== modelValue.value?.end) {
    return true
  }
  if (timeResolution.value !== modelValue.value?.timeResolution) {
    return true
  }
  return false
})

const humanReadableTimeQuery = computed(() => {
  if (relativeTimeRangeFittingToAbsoluteTimeRange.value) {
    const timeRange = availableTimeRanges.findLast(range => range.value === relativeTimeRangeFittingToAbsoluteTimeRange.value)
    return timeRange.text
  }
  return `${humanReadableTimestamp(absoluteStartTime.value.toMillis())} - ${humanReadableTimestamp(absoluteEndTime.value.toMillis())}`
})

const timeResolution = computed(() => {
  if (!autoTimeResolution.value) return manuallyPickedTimeResolutionValue.value * manuallyPickedTimeResolutionUnit.value
  return optimalTimeResolution.value
})

const optimalTimeResolution = computed(() => {
  if (absoluteStartTime.value === null || absoluteEndTime.value === null) return 1000 * 60
  const duration = absoluteEndTime.value.diff(absoluteStartTime.value)
  const targetDataPoints = 100
  return Math.round(Math.ceil(duration / targetDataPoints) / 1000) * 1000
})

function estimateBestTimeResolutionUnit(resolution) {
  for (const unit of timeResolutionUnits.toSorted((a, b) => a.value - b.value)) {
    if (resolution % unit.value === 0) return unit.value
  }
  return 1
}

function resetToDefault() {
  applyModelValue()
}

function setDefaultValue() {
  absoluteEndTime.value = DateTime.now().startOf("second")
  absoluteStartTime.value = DateTime.now().minus({days: 4}).startOf("second")
  autoTimeResolution.value = true
  confirm()
}

function applyModelValue() {
  const value = modelValue.value
  if (!value || value === {}) return setDefaultValue()
  if (value.start === undefined || value.end === undefined) return setDefaultValue()
  absoluteStartTime.value = DateTime.fromMillis(value.start)
  absoluteEndTime.value = DateTime.fromMillis(value.end)
  if (value.timeResolution) {
    if (optimalTimeResolution.value === value.timeResolution) {
      autoTimeResolution.value = true
      return
    }
    autoTimeResolution.value = false
    const unit = estimateBestTimeResolutionUnit(value.timeResolution)
    manuallyPickedTimeResolutionValue.value = value.timeResolution / unit
    manuallyPickedTimeResolutionUnit.value = unit
  } else {
    autoTimeResolution.value = true
  }
}

function confirm() {
  triggeredInitialLoad.value = true
  const timeResolution = autoTimeResolution.value ? optimalTimeResolution.value : manuallyPickedTimeResolutionValue.value * manuallyPickedTimeResolutionUnit.value
  modelValue.value = {
    start: absoluteStartTime.value.toMillis(),
    end: absoluteEndTime.value.toMillis(),
    timeResolution: timeResolution,
  }
  menu.value = false
}

function updateStartTimestamp(newValue) {
  const oldValue = absoluteStartTime.value
  absoluteStartTime.value = newValue
  if (newValue.startOf("second").toMillis() === oldValue.startOf("second").toMillis()) return
}

function updateEndTimestamp(newValue) {
  const oldValue = absoluteEndTime.value
  absoluteEndTime.value = newValue
  if (newValue.startOf("second").toMillis() === oldValue.startOf("second").toMillis()) return
}

function calculateAbsoluteTimeRangeToMatchRelative() {
  const now = DateTime.now().startOf("second").toMillis()
  absoluteStartTime.value = DateTime.fromMillis(now - pickedRelativeTimeRange.value)
  absoluteEndTime.value = DateTime.fromMillis(now)
}

const relativeTimeRangeFittingToAbsoluteTimeRange = computed(() => {
  const timeRange = absoluteEndTime.value.diff(absoluteStartTime.value).toMillis()
  if (DateTime.now().diff(absoluteEndTime.value).toMillis() <= 1000 * 60)
    if (availableTimeRanges.map(range => range.value).includes(timeRange))
      return timeRange
  return null
})

function updateRelativeTimeRange(timeRange) {
  pickedRelativeTimeRange.value = timeRange
  calculateAbsoluteTimeRangeToMatchRelative()
}
const triggeredInitialLoad = ref(false)
const menu = ref(false)
const quickTimeRangeMenu = ref(false)

watch(modelValue, () => {
  applyModelValue()
  if(!triggeredInitialLoad.value) {
    confirm()
  }
}, {deep: true, immediate: true, })

</script>

<template>
  <v-btn
    class="ma-2 rounded-pill"
    prepend-icon="mdi-clock-outline"
    append-icon="mdi-menu"
  >
    {{ humanReadableTimeQuery }}
    <v-overlay
      location-strategy="connected"
      scroll-strategy="close"
      location="bottom end"
      v-model="menu"
      id="timeQueryMenu"
      activator="parent"
      :close-on-content-click="false"
    >
      <v-card style="border-radius: 16px">

        <v-card-title>
          Time Range
        </v-card-title>
        <v-card-text>
          <v-row :dense="true" class="justify-space-around">
            <v-col cols="auto">
              <DateTimePicker
                :model-value="absoluteStartTime"
                label="Start"
                @update:model-value="updateStartTimestamp">
              </DateTimePicker>
            </v-col>
            <v-col cols="auto">
              <DateTimePicker
                label="End" :model-value="absoluteEndTime"
                @update:model-value="updateEndTimestamp"></DateTimePicker>
            </v-col>
            <v-col :cols="12">
              <v-btn
                variant="outlined"
                class="rounded-pill"
                :block="true"
                size="small"
              >
                {{$t("charts.quick_time_ranges")}}
                <v-menu activator="parent">
                  <v-list>
                    <v-list-item v-for="availableTimeRange in availableTimeRanges"
                                 :title="availableTimeRange.text"
                                 :key="availableTimeRange.value"
                                 @click="updateRelativeTimeRange(availableTimeRange.value);"
                    >
                    </v-list-item>
                  </v-list>

                </v-menu>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-title v-if="!hideTimeResolution">
          {{ t("charts.time_resolution") }}
        </v-card-title>
        <v-card-text v-if="!hideTimeResolution">
          <v-switch v-model="autoTimeResolution"
                    :label="t('charts.choose_time_resolution_automatically')"
          ></v-switch>
          <v-row justify="center" align="center" :dense="true">

            <v-col cols="6" v-if="!autoTimeResolution">
              <v-text-field v-model="manuallyPickedTimeResolutionValue" type="Number"
                            variant="outlined"
                            rounded
                            :label="t('charts.time_resolution_value')"
              ></v-text-field>
            </v-col>
            <v-col cols="3" v-if="!autoTimeResolution">
              <v-select v-model="manuallyPickedTimeResolutionUnit"
                        :items="timeResolutionUnits"
                        item-title="text"
                        item-value="value"
                        variant="outlined"
                        :label="t('charts.time_resolution_unit')"
              ></v-select>
            </v-col>

            <v-col cols="auto" v-if="autoTimeResolution">
              <v-text-field :readonly="true"
                            style=" min-width: 250px; max-width: 400px"
                            variant="outlined"
                            label="Time Resolution"
                            rounded
                            :model-value="humanReadableDuration(optimalTimeResolution, undefined, undefined, true)"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="error"
            variant="text"
            class="rounded-pill"
            @click="resetToDefault"
            v-if="showResetButton"
          >
            Reset to Default
          </v-btn>
          <v-btn
            color="primary"
            variant="flat"
            class="rounded-pill"
            @click="confirm">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-btn>
</template>

<style scoped>

</style>
